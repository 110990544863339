import 'bootstrap/dist/css/bootstrap.css';
import '../../css/index.css';
import '../../css/google.css';
import React, { Component } from 'react';
import TextService from '../../services/TextService';

export class WebsocketTester extends Component {
  componentDidMount() {
    var connectionUrl = document.getElementById("connectionUrl");
    var connectButton = document.getElementById("connectButton");
    var stateLabel = document.getElementById("stateLabel");
    var sendMessage = document.getElementById("sendMessage");
    var sendButton = document.getElementById("sendButton");
    var commsLog = document.getElementById("commsLog");
    var closeButton = document.getElementById("closeButton");

    var scheme = document.location.protocol === "https:" ? "wss" : "ws";
    var port = document.location.port ? (":" + document.location.port) : "";
    var socket;
    connectionUrl.value = scheme + "://" + document.location.hostname + port + "/websocket";

    closeButton.onclick = () => {
      if (!socket || socket.readyState !== WebSocket.OPEN) {
        alert("socket not connected");
      }
      socket.close(1000, "Closing from client");
    };

    sendButton.onclick = () => {
      if (!socket || socket.readyState !== WebSocket.OPEN) {
        alert("socket not connected");
      }
      var data = sendMessage.value;
      socket.send(data);
      commsLog.innerHTML += '<tr>' +
        '<td class="commslog-client">Client</td>' +
        '<td class="commslog-server">Server</td>' +
        '<td class="commslog-data">' + TextService.htmlEscape(data) + '</td></tr>';
    };

    connectButton.onclick = () => {
      stateLabel.innerHTML = "Connecting";
      socket = new WebSocket(connectionUrl.value);
      socket.onopen = (event) => {
        updateState();
        commsLog.innerHTML += '<tr>' +
          '<td colspan="3" class="commslog-data">Connection opened</td>' +
          '</tr>';
      };

      socket.onclose = (event) => {
        updateState();
        commsLog.innerHTML += '<tr>' +
          '<td colspan="3" class="commslog-data">Connection closed. Code: ' + TextService.htmlEscape(event.code) + '. Reason: ' + TextService.htmlEscape(event.reason) + '</td>' +
          '</tr>';
      };
      socket.onerror = updateState;
      socket.onmessage = (event) => {
        commsLog.innerHTML += '<tr>' +
          '<td class="commslog-server">Server</td>' +
          '<td class="commslog-client">Client</td>' +
          '<td class="commslog-data">' + TextService.htmlEscape(event) + '</td></tr>';
      };
    };

    function updateState() {
      function disable() {
        sendMessage.disabled = true;
        sendButton.disabled = true;
        closeButton.disabled = true;
      }
      function enable() {
        sendMessage.disabled = false;
        sendButton.disabled = false;
        closeButton.disabled = false;
      }

      connectionUrl.disabled = true;
      connectButton.disabled = true;

      if (!socket) {
        disable();
      } else {
        switch (socket.readyState) {
          case WebSocket.CLOSED:
            stateLabel.innerHTML = "Closed";
            disable();
            connectionUrl.disabled = false;
            connectButton.disabled = false;
            break;
          case WebSocket.CLOSING:
            stateLabel.innerHTML = "Closing...";
            disable();
            break;
          case WebSocket.CONNECTING:
            stateLabel.innerHTML = "Connecting...";
            disable();
            break;
          case WebSocket.OPEN:
            stateLabel.innerHTML = "Open";
            enable();
            break;
          default:
            stateLabel.innerHTML = "Unknown WebSocket State: " + TextService.htmlEscape(socket.readyState);
            disable();
            break;
        }
      }
    }


  }

  render() {
    return (
      <html>
        <head>
          <meta charSet="utf-8" />
          <style dangerouslySetInnerHTML={{
            __html: `
          table {border: 0 }
        .commslog-data {font - family: Consolas, Courier New, Courier, monospace; }
        .commslog-server {background - color: red; color: white }
        .commslog-client {background - color: green; color: white }
        
        `}} />
        </head>
        <body>
          <h1>WebSocket Sample Application</h1>
          <p id="stateLabel">Ready to connect...</p>
          <div>
            <label htmlFor="connectionUrl">WebSocket Server URL:</label>
            <input id="connectionUrl" />
            <button id="connectButton" type="submit">Connect</button>
          </div>
          <p></p>
          <div>
            <label htmlFor="sendMessage">Message to send:</label>
            <input id="sendMessage" disabled />
            <button id="sendButton" type="submit" disabled>Send</button>
            <button id="closeButton" disabled>Close Socket</button>
          </div>

          <h2>Communication Log</h2>
          <table style={{ width: 800 + 'px' }}>
            <thead>
              <tr>
                <td style={{ width: 100 + 'px' }}>From</td>
                <td style={{ width: 100 + 'px' }}>To</td>
                <td>Data</td>
              </tr>
            </thead>
            <tbody id="commsLog">
            </tbody>
          </table>
        </body>
      </html >
    );
  }
}
import React, { useState, useEffect, useRef } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { TopMenu } from './ui/shared/TopMenu';
import { Home } from './ui/home/Home';
import { About } from './ui/home/About';
import { ReleaseNotes } from './ui/home/ReleaseNotes';
import { Contact } from './ui/home/Contact';
import { CreateStir } from './ui/create/CreateStir';
import { UserDetails } from './ui/details/UserDetails';
import { ThemeDetails } from './ui/details/ThemeDetails';
import { AdministratorDetails } from './ui/details/AdministratorDetails';
import { StirDetails } from './ui/details/StirDetails';
import { PreferenceDetails } from './ui/details/PreferenceDetails';
import { AssignDetails } from './ui/details/AssignDetails';
import { EventDetails } from './ui/details/EventDetails';
import { NotFound } from './ui/shared/NotFound';
import { WebsocketTester } from './ui/test/websocketTester';
import Constants from './services/Constants';
import User from './entities/User';
import Validator from './services/ValidationService';
import { UrlService } from './services/UrlService';
import EntityService from './services/EntityService';

export function App() {
  const [userHash, setUserHash] = useState("");
  const [user, setUser] = useState(new User());
  const [colorThemeCss, setColorThemeCss] = useState(user.getColorTheme().css);
  const isLoadingUser = useRef();

  useEffect(() => {
    EntityService.getEntityAsync((data, status) => {
      if (status === Constants.httpStatus.Ok) {
        data.publicHash = data.hash;
        data.hash = userHash;
        data.status = Constants.httpStatus.Ok;
        const newUser = new User(data);
        setUser(newUser);
        setColorThemeCss(newUser.getColorTheme().css);
      } else {
        setUser(new User());
      }
    }, userHash, isLoadingUser);
  }, [userHash]);

  const urlUserHash = UrlService.getUserHashFromUrl() || UrlService.getPathFromUrl();
  if (userHash !== urlUserHash && Validator.isHashValid(urlUserHash, Constants.hash.user)) {
    setUserHash(urlUserHash);
  }

  const updateUser = (newUser) => {
    setUser(new User(newUser));
    setColorThemeCss(newUser.getColorTheme().css);
  }

  const ContentLoader = (props) => {
    const path = UrlService.getPathFromUrl();
    var hash = /[^/]*$/.exec(path)[0]; // text after last slash
    var route = path.split('/')[0]; // text before first slash
    const hasValidUserHash = Validator.isHashValid(UrlService.getUserHashFromUrl(), Constants.hash.user);
    const isValidAdmin = (route === "administer") && hasValidUserHash && Validator.isHashValid(hash, Constants.hash.stir);
    const isValidPick = (route === "pick") && hasValidUserHash && Validator.isHashValid(hash, Constants.hash.stir);
    const isValidAssign = (route === "assign") && hasValidUserHash && Validator.isHashValid(hash, Constants.hash.stir);

    if (isValidAdmin) return <AdministratorDetails hash={hash} {...props} />;
    if (isValidPick) return <PreferenceDetails hash={hash} {...props} />;
    if (isValidAssign) return <AssignDetails hash={hash} {...props} />;
    if (Validator.isHashValid(hash, Constants.hash.stir)) return <StirDetails hash={hash} {...props} />;
    if (Validator.isHashValid(hash, Constants.hash.user)) return <UserDetails hash={hash} updateUser={updateUser} {...props} />;
    if (Validator.isHashValid(hash, Constants.hash.theme)) return <ThemeDetails hash={hash} {...props} />;
    if (Validator.isHashValid(hash, Constants.hash.event)) return <EventDetails hash={hash} {...props} />;

    return <NotFound />;
  }

  return (<>
    <link rel="stylesheet" type="text/css" href={colorThemeCss} />
    <Container>
      <Row>
        <Col sm={12} className="center">
          <TopMenu />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Routes>
            <Route exact path='/' element={<Home user={user} />} />
            <Route path='/about' element={<About />} />
            <Route path='/releaseNotes' element={<ReleaseNotes />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/websocketTester' element={<WebsocketTester />} />
            <Route path='/stir/*' element={<CreateStir user={user} navigate={useNavigate()} />} />
            <Route path="*" element={<ContentLoader isLoadingUser={isLoadingUser} user={user} navigate={useNavigate()} />} />
          </Routes>
        </Col>
      </Row>
    </Container>
  </>);
}
import React, { Component } from 'react';
import { Icon } from '../shared/Icon';

export class PredictionDisplay extends Component {
  render() {
    const i = this.props.i;
    const o = this.props.o;

    const outputMatchControls = (o.matchCount > 0) &&
      <>
        <span className="number">{o.matchCount}</span>
        <Icon icon={this.props.isMirror ? "match-participant" : "match"} />
      </>;
    const outputTeamControls = (o.teamCount > 0) &&
      <>
        {o.matchCount ? <Icon icon="small-plus" /> : null}
        <span className="number">{o.teamCount}</span>
        <Icon icon="team" />
      </>;
    const inputMatchControls = (i.matchCount > 0) &&
      <>
        <span className="number">{i.matchCount}</span>
        <Icon icon="match" />
      </>;
    const inputTeamControls = (i.teamCount > 0) &&
      <>
        {i.matchCount ? <Icon icon="small-plus" /> : null}
        <span className="number">{i.teamCount}</span>
        <Icon icon="team" />
      </>;

    const inputControls = i.isAmbiguousCount()
      ? (
        <>
          <Icon icon={"team-secret"} />
        </>
      ) : (
        <>
          {inputMatchControls}
          {inputTeamControls}
        </>
      );

    const outputControls = o.isAmbiguousCount()
      ? (
        <>
          <Icon icon={"team-secret"} />
        </>
      ) : (
        <>
          {outputMatchControls}
          {outputTeamControls}
        </>
      );

    return (
      <div className="method-step-matches-outer">
        <div className="method-step-matches">
          {inputControls}
          <Icon icon="long-arrow-alt-right" />
          {outputControls}
        </div>
      </div>);
  }
}

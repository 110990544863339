import React from 'react';
import { HelpOverlay } from './HelpOverlay';

export function Icon(props) {
  const getIcon = (() => {
    const css = props.css ? (" " + props.css) : "";
    switch (props.icon) {
      case "expand":
        return <i className={"fa fa-fw fa-expand-alt" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "shrink":
        return <i className={"fa fa-fw fa-compress-alt" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "event":
        return <i className={"fa fa-fw fa-calendar" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "email":
        return <i className={"fa fa-fw fa-envelope" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "email-success":
        return (
          <div className={"fa fa-fw fa-stack" + css} aria-hidden="true" onClick={props.onClick} {...props}>
            <i className="fa fa-fw fa-envelope fa-stack-1x" aria-hidden="true"></i>
            <i className="fa fa-fw fa-check success fa-xs fa-stack-1x fa-inverse" aria-hidden="true"></i>
          </div>);
      case "loading":
        return <i className={"fa fa-fw fa-spinner fa-pulse" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "question":
        return <i className={"fa fa-fw fa-question-circle" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "caution":
        return <i className={"fa fa-fw fa-exclamation red" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "check":
        return <i className={"fa fa-fw fa-check success" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "observer":
        return <i className={"fa fa-fw fa-glasses" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "participant":
        return <i className={"fa fa-fw fa-user" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "participants":
        return <i className={"fa fa-fw fa-users" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "theme":
        return <i className={"fa fa-fw fa-th-large" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "team":
        return <i className={"fa fa-fw fa-square" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "team-secret":
        return (
          <div className={"fa fa-fw fa-stack" + css} aria-hidden="true" onClick={props.onClick} {...props}>
            <i className="fa fa-fw fa-square fa-lg fa-stack-1x" aria-hidden="true"></i>
            <i className="fa fa-fw fa-question fa-xs fa-stack-1x fa-inverse" aria-hidden="true"></i>
          </div>);
      case "elect-team-1x":
        return (
          <div className={"fa fa-fw fa-stack" + css} aria-hidden="true" onClick={props.onClick} {...props}>
            <i className="fa fa-fw fa-square fa-lg fa-stack-1x" aria-hidden="true"></i>
            <i className="fa fa-fw fa-star elect-star-1of1 fa-xs fa-stack-1x fa-inverse gold" aria-hidden="true"></i>
          </div>);
      case "elect-team-2x":
        return (
          <div className={"fa fa-fw fa-stack" + css} aria-hidden="true" onClick={props.onClick} {...props}>
            <i className="fa fa-fw fa-square fa-lg fa-stack-1x" aria-hidden="true"></i>
            <i className="fa fa-fw fa-star elect-star-1of2 fa-xs fa-stack-1x fa-inverse gold" aria-hidden="true"></i>
            <i className="fa fa-fw fa-star elect-star-2of2 fa-xs fa-stack-1x fa-inverse gold" aria-hidden="true"></i>
          </div>);
      case "elect-team-3x":
        return (
          <div className={"fa fa-fw fa-stack" + css} aria-hidden="true" onClick={props.onClick} {...props}>
            <i className="fa fa-fw fa-square fa-lg fa-stack-1x" aria-hidden="true"></i>
            <i className="fa fa-fw fa-star elect-star-1of3 fa-xs fa-stack-1x fa-inverse gold" aria-hidden="true"></i>
            <i className="fa fa-fw fa-star elect-star-2of3 fa-xs fa-stack-1x fa-inverse gold" aria-hidden="true"></i>
            <i className="fa fa-fw fa-star elect-star-3of3 fa-xs fa-stack-1x fa-inverse gold" aria-hidden="true"></i>
          </div>);
      case "elect-team-1x-match":
        return (
          <div className={"fa fa-fw fa-stack" + css} aria-hidden="true" onClick={props.onClick} {...props}>
            <i className="fa fa-fw fa-square fa-lg fa-stack-1x" aria-hidden="true"></i>
            <i className="fa fa-fw fa-user fa-xs fa-stack-1x fa-inverse" aria-hidden="true"></i>
            <i className="fa fa-fw fa-star elect-star-1of1 fa-xs fa-stack-1x fa-inverse gold" aria-hidden="true"></i>
          </div>);
      case "elect-team-2x-match":
        return (
          <div className={"fa fa-fw fa-stack" + css} aria-hidden="true" onClick={props.onClick} {...props}>
            <i className="fa fa-fw fa-square fa-lg fa-stack-1x" aria-hidden="true"></i>
            <i className="fa fa-fw fa-user fa-xs fa-stack-1x fa-inverse" aria-hidden="true"></i>
            <i className="fa fa-fw fa-star elect-star-1of2 fa-xs fa-stack-1x fa-inverse gold" aria-hidden="true"></i>
            <i className="fa fa-fw fa-star elect-star-2of2 fa-xs fa-stack-1x fa-inverse gold" aria-hidden="true"></i>
          </div>);
      case "elect-team-1x-exclude-team":
        return (
          <div className={"fa fa-fw fa-stack" + css} aria-hidden="true" onClick={props.onClick} {...props}>
            <i className="fa fa-fw fa-square fa-lg fa-stack-1x" aria-hidden="true"></i>
            <i className="fa fa-fw fa-star elect-star-1of1 fa-xs fa-stack-1x fa-inverse gold" aria-hidden="true"></i>
            <i className="fa fa-fw fa-slash fa-stack-1x fa-inverse red" aria-hidden="true"></i>
          </div>);
      case "elect-team-2x-exclude-team":
        return (
          <div className={"fa fa-fw fa-stack" + css} aria-hidden="true" onClick={props.onClick} {...props}>
            <i className="fa fa-fw fa-square fa-lg fa-stack-1x" aria-hidden="true"></i>
            <i className="fa fa-fw fa-star elect-star-1of2 fa-xs fa-stack-1x fa-inverse gold" aria-hidden="true"></i>
            <i className="fa fa-fw fa-star elect-star-2of2 fa-xs fa-stack-1x fa-inverse gold" aria-hidden="true"></i>
            <i className="fa fa-fw fa-slash fa-stack-1x fa-inverse red" aria-hidden="true"></i>
          </div>);
      case "exclude-team":
        return (
          <div className={"fa fa-fw fa-stack" + css} aria-hidden="true" onClick={props.onClick} {...props}>
            <i className="fa fa-fw fa-square fa-lg fa-stack-1x" aria-hidden="true"></i>
            <i className="fa fa-fw fa-slash fa-stack-1x fa-inverse red" aria-hidden="true"></i>
          </div>);
      case "drag":
        return <i className={"fa fa-fw fa-bars" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "stir":
        return <i className={"fa fa-fw fa-utensil-spoon" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "draft":
      case "rank":
      case "rank vote":
        return <i className={"fa fa-fw fa-sort-numeric-down" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "clone":
        return <i className={"fa fa-fw fa-solid fa-clone" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "group":
        return <i className={"fa fa-fw fa-exchange-alt" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "score":
        return <i className={"fa fa-fw fa-thumbs-up" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "match":
        return (
          <div className={"fa fa-fw fa-stack" + css} aria-hidden="true" onClick={props.onClick} {...props}>
            <i className="fa fa-fw fa-square fa-lg fa-stack-1x" aria-hidden="true"></i>
            <i className="fa fa-fw fa-user fa-xs fa-stack-1x fa-inverse" aria-hidden="true"></i>
          </div>);
      case "match-secret":
        return (
          <div className={"fa fa-fw fa-stack" + css} aria-hidden="true" onClick={props.onClick} {...props}>
            <i className="fa fa-fw fa-square fa-lg fa-stack-1x" aria-hidden="true"></i>
            <i className="fa fa-fw fa-user-secret fa-xs fa-stack-1x fa-inverse" aria-hidden="true"></i>
          </div>);
      case "match-participant":
        return <i className={"fa fa-fw fa-user-friends" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "balance":
        return <i className={"fa fa-fw fa-balance-scale" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "no-balance":
        return (
          <div className={"fa-stack small-stack" + css}>
            <i className="fa fa-balance-scale fa-stack-1x"></i>
            <i className="fa fa-slash fa-stack-1x red"></i>
          </div>);
      case "unselected":
        return <i className={"fa fa-fw fa-minus-circle" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "in-progress":
        return <i className={"fa fa-fw fa-hourglass-half" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "admin":
        return <i className={"fa fa-fw fa-user-cog" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "admin-observer":
        return (
          <div className={"fa fa-fw fa-stack" + css} aria-hidden="true" onClick={props.onClick} {...props}>
            <i className="fa fa-fw fa-glasses fa-stack-1x" aria-hidden="true"></i>
            <i className="fa fa-fw fa-cog fa-xs fa-stack-1x" aria-hidden="true"></i>
          </div>);
      case "dropdown":
        return <i className={"fa fa-fw fa-ellipsis-v" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "breadcrumb-separator":
        return <i className={"fa fa-fw fa-caret-right" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "medal":
        if (props.rank === 1) {
          return <i className={"fa fa-fw fa-trophy gold" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
        } else if (props.rank === 2) {
          return <i className={"fa fa-fw fa-trophy silver" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
        } else if (props.rank === 3) {
          return <i className={"fa fa-fw fa-trophy bronze" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
        }
        return null;
      case "small-plus":
        return (
          <div className={"fa fa-fw fa-stack" + css} aria-hidden="true" onClick={props.onClick} {...props}>
            <i className="fa fa-fw fa-plus fa-xs fa-stack-1x" aria-hidden="true"></i>
          </div>);
      case "star":
        return <i className={"fa fa-fw fa-star" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "hand":
        return <i className={"fa fa-fw fa-hand-paper" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      case "assign":
        return <i className={"fa fa-fw fa-solid fa-recycle" + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
      default:
        return <i className={"fa fa-fw fa-" + props.icon + css} aria-hidden="true" onClick={props.onClick} {...props}></i>;
    }
  });

  return <HelpOverlay content={getIcon()} text={props.helptext} />;
}
import React, { Component } from 'react';
import { Tile } from './Tile';
import { Icon } from '../shared/Icon';
import Constants from '../../services/Constants';
import TextService from '../../services/TextService';

export class MatchTile extends Component {

  constructor(props) {
    super(props);
    if (!props.match.isInitialized) throw new Error("match not initialized");
  }

  getIcon() {
    if (this.props.match.electedCount && this.props.match.assignedCount) {
      let icon = "elect-team-1x-match";
      let electedCountText = "";
      if (this.props.match.electedCount === 2) {
        icon = "elect-team-2x-match";
        electedCountText = " twice ";
      }
      return <Icon icon={icon} helptext={this.props.match.team.name + " was elected in this stir" + electedCountText + " then assigned to " + this.props.match.participant.name} />;
    }
    if (this.props.match.electedCount && this.props.match.excludedCount) {
      let icon = "elect-team-1x-exclude-team";
      let electedCountText = "";
      if (this.props.match.electedCount === 2) {
        icon = "elect-team-2x-exclude-team";
        electedCountText = " twice ";
      }
      return <Icon icon={icon} helptext={this.props.match.team.name + " was elected in this stir" + electedCountText + " before being excluded"} />;
    }
    if (this.props.isSecretReveal && this.props.match.assignedCount && this.props.match.team) {
      return <Icon icon="match-secret" helptext={this.props.match.participant.name + " was secretly assigned " + this.props.match.team.name} />;
    }
    if (this.props.match.assignedCount && this.props.match.team) {
      const verbed = this.props.isDraft ? " drafted " : " was assigned ";
      return <Icon icon="match" helptext={this.props.match.participant.name + verbed + this.props.match.team.name} />;
    }
    if (this.props.match.electedCount) {
      let icon = "elect-team-1x";
      let electedCountText = "";
      if (this.props.match.electedCount === 2) {
        icon = "elect-team-2x";
        electedCountText = " twice";
      } else if (this.props.match.electedCount === 3) {
        icon = "elect-team-3x";
        electedCountText = " three times";
      }
      return <Icon icon={icon} helptext={this.props.match.team.name + " was elected" + electedCountText + " in this stir"} />;
    }
    if (this.props.match.excludedCount) {
      return <Icon icon="exclude-team" helptext={this.props.match.team.name + " was excluded from this stir"} />;
    }
    if (this.props.match.participantHash) {
      return <Icon icon="participant" helptext={this.props.match.participant.name} />;
    }
  }

  getFooter() {
    if (this.props.match.team && this.props.match.participant) {
      return <>{this.props.match.participant.name}</>;
    }
    return null;
  }

  getHeaderElements() {
    return null;
  }

  getFooterElements() {
    let place;
    if (this.props.match.winnerRank === 1) {
      place = '1st';
    } else if (this.props.match.winnerRank === 2) {
      place = '2nd';
    } else if (this.props.match.winnerRank === 3) {
      place = '3rd';
    }

    let medal;
    if (place && this.props.match.participant) {
      medal = (
        <div>
          <Icon icon="medal" rank={this.props.match.winnerRank} helptext={`${this.props.match.participant.name} won ${place} place`} />
        </div>
      );
    }

    let preference = null;
    let teamscore = null;
    if (this.props.match.step) {
      const hasStepPreferences = this.props.match.step.algorithmId !== Constants.algorithmType.Random;
      if (this.props.match.teamScore !== null && this.props.match.team && this.props.showStirPoints) {
        teamscore = (
          <div>
            {this.props.match.teamScore} <Icon icon="star" helptext={this.props.match.team.name + " received " + TextService.pluralize(this.props.match.teamScore, "stir point") + " across all participants."} />
          </div>);
      }
      if (this.props.match.team && this.props.match.rank && this.props.match.step.isElect()) {
        preference = (
          <div>
            {this.props.match.rank} <Icon icon="balance" helptext={this.props.match.team.name + " was elected as team #" + this.props.match.rank + "."} />
          </div>);
      } else if (this.props.match.participant && hasStepPreferences && this.props.match.team && !this.props.match.step.isAdminAssign()) {
        const preferenceRank = this.props.match.getOutcome().rank;
        preference = (preferenceRank)
          ? (
            <div>
              {preferenceRank} <Icon icon="balance" helptext={this.props.match.participant.name + " ranked " + this.props.match.team.name + " as preference #" + preferenceRank + "."} />
            </div>)
          : <Icon icon="no-balance" helptext={this.props.match.participant.name + " did not set any rank preferences."} />;
      }
    }

    return (
      <>
        {teamscore}
        {medal}
        {preference}
      </>
    );
  }

  getDropdownActions() {
    return [];
  }

  render() {
    return <Tile
      isHeader={this.props.isHeader}
      icon={this.getIcon()}
      header={this.props.match.team?.name ?? this.props.match.participant?.name ?? "none"}
      footer={this.getFooter()}
      headerElements={this.getHeaderElements()}
      footerElements={this.getFooterElements()}
      dropdownActions={this.getDropdownActions()}
      hash={this.props.match.hash}
      {...this.props}
    />;
  }
}

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Alert, FormCheck } from 'react-bootstrap';
import { StirTile } from '../tiles/StirTile';
import { Spinner } from '../shared/Spinner';
import { HelpOverlay } from '../shared/HelpOverlay';
import { NoContent } from '../shared/NoContent';
import { RankDetails } from './RankDetails';
import { GroupDetails } from './GroupDetails';
import { DraftDetails } from './DraftDetails';
import { ConfirmationModal } from '../shared/ConfirmationModal';
import { Icon } from '../shared/Icon';
import { Breadcrumbs } from '../shared/Breadcrumbs';
import TextService from '../../services/TextService';
import Constants from '../../services/Constants';
import { UrlService } from '../../services/UrlService';
import Validator from '../../services/ValidationService';
import EntityService from '../../services/EntityService';
import Stir from '../../entities/Stir';
import WebsocketService from '../../services/WebsocketService';

export function PreferenceDetails(props) {
  const { user, isLoadingUser } = props;

  const [event, setEvent] = useState(undefined);
  const isLoadingEvent = useRef();
  const [stir, setStir] = useState(undefined);
  const isLoadingStir = useRef();
  const teamPicks = useRef();
  const [savedTeamPicks, setSavedTeamPicks] = useState(undefined);
  const [draftTeamPicks, setDraftTeamPicks] = useState(undefined);
  const isLoadingTeamPicks = useRef();
  const isLoadingDraftPicks = useRef();
  const isOverridingTeamPicks = useRef(false);
  const [isLeaveStirModalOpen, setIsLeavingStirModalOpen] = useState(false);
  const [isSchedulingSave, setIsSchedulingSave] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [concedeTies, setConcedeTies] = useState(false);
  const participant = useRef();
  const [isEmailActionProcessed, setIsEmailActionProcessed] = useState(false);
  const isDirty = useRef(false);
  const isSavingTeamPicks = useRef(false);
  const isCommitted = useRef();
  const [isError, setIsError] = useState(false);
  const isWebsocketConnected = useRef(false);
  const isDraft = (stir?.currentStep.methodId == Constants.methodType.Draft) ?? false;
  const isSelfAssign = (stir?.currentStep.methodId === Constants.methodType.SelfAssign) ?? false;

  UrlService.useTitle(isDraft ? 'Draft' : 'Set Preferences');

  const currentStep = stir?.currentStep;

  const entityText = isDraft ? 'draft picks' : 'preferences';

  const loadEvent = useCallback((eventHash) => {
    if (Validator.isHashValid(eventHash)) {
      EntityService.getEntityAsync((data) => {
        if (data && Validator.isHashValid(data.hash, Constants.hash.event)) {
          setEvent(data);
        }

      }, eventHash, isLoadingEvent);
    }
  }, []);

  const loadDraftPicks = useCallback((stir) => {
    if (Validator.isHashValid(stir.hash) && stir.currentStep.isDraft() && draftTeamPicks === undefined) {
      EntityService.getAsyncData((data) => {
        if (data) {
          setDraftTeamPicks(data);
        }
      }, `api/pickLists/draft/${props.hash}?user=${user.hash}`, isLoadingDraftPicks);
    }
  }, []);

  const loadStir = (stirHash) => {
    if (Validator.isHashValid(stirHash)) {
      EntityService.getAsyncData((data) => {
        if (!data) {
          setNotFound(true);
        } else {
          const stir = new Stir(data);

          if (stir.dateResolved) {
            props.navigate(UrlService.getUrl("/" + stir.hash));
          }

          participant.current = stir.getParticipant(user.publicHash);
          if (!participant.current || participant.current.isObserver) {
            setNotFound(true);
            return;
          }

          isCommitted.current = participant.current.isCommitted;

          loadEvent(stir.eventHash);
          loadDraftPicks(stir);
          setStir({ ...stir });
          setConcedeTies(participant.current.concedeTies);
        }
      }, `api/${stirHash}?user=${user.publicHash}`, isLoadingStir, false);
    }
  };

  if (Validator.isHashValid(props.hash) && Validator.isHashValid(user?.publicHash) && props.hash === UrlService.getHashFromUrl()) {
    if (!stir) {
      loadStir(props.hash);
    }

    if (!teamPicks.current) {
      EntityService.getAsyncData((data) => {
        if (data?.picks?.length) {
          teamPicks.current = data.picks.sort((a, b) => a.pickRank - b.pickRank);
          setSavedTeamPicks(teamPicks.current);
        } else {
          teamPicks.current = [];
          setSavedTeamPicks([]);
        }
      }, `api/pickLists/${props.hash}?user=${user.hash}`, isLoadingTeamPicks);
    }
  }

  useEffect(() => {
    if (isLoadingUser.current === false && !Validator.isHashValid(user?.publicHash)) {
      setNotFound(true);
    }
  }, [isLoadingUser.current])

  useEffect(() => {
    if (isSchedulingSave) {
      scheduleSave();
    }
  }, [isSchedulingSave])

  const scheduleSave = () => {
    setTimeout(() => {
      if (!stir || !teamPicks.current || !participant.current) return;

      let hasChanges = teamPicks.current.length !== savedTeamPicks?.length || isCommitted.current !== participant.current.isCommitted

      if (!hasChanges) {
        for (let i = 0; i < teamPicks.current?.length; i++) {
          if (teamPicks.current[i] !== savedTeamPicks[i]) {
            hasChanges = true;
            break;
          }
        }
      }

      if (!hasChanges) {
        isDirty.current = false;
        setIsSchedulingSave(false);
        return;
      }

      EntityService.setAsyncData((_, status) => {
        if (status === Constants.httpStatus.Redirect) {
          props.navigate(UrlService.getUrl("/" + stir.hash, "status=resolved"));
        } else if (status !== Constants.httpStatus.Ok) {
          setIsError(true);
        }

        isDirty.current = false;
        stir.stepParticipants.find(sp => sp.participantHash === user.publicHash && sp.stepHash === stir.currentStep.hash).isCommitted = isCommitted.current;

        isCommitted.current = participant.current.isCommitted;
        setStir({ ...stir });
      },
        `/api/pickLists?user=${user.hash}`,
        {
          stirHash: props.hash,
          picks: teamPicks.current,
          stepParticipant: {
            participantHash: user.publicHash,
            stepHash: stir.currentStepHash,
            isCommitted: isCommitted.current,
            concedeTies: concedeTies
          }
        }, 'PUT', isSavingTeamPicks);
      setIsSchedulingSave(false);
    }, Constants.pageDelays.AfterActionSeconds * 500);
  };

  const handleDropdownActions = useCallback((actionId) => {
    const action = parseInt(actionId);
    if (action === Constants.actionType.clearPreferences || action === Constants.actionType.clearPreferencesAndCommit) {
      isOverridingTeamPicks.current = true;
      teamPicks.current = [];
      isCommitted.current = isCommitted.current || action === Constants.actionType.clearPreferencesAndCommit;
      isDirty.current = true;
      setIsSchedulingSave(true);
      setStir({ ...stir });
    } else if (action === Constants.actionType.leaveThisStir) {
      setIsLeavingStirModalOpen(true);
    }

    const dropdown = document.getElementsByClassName("dropdown");
    if (dropdown.length) {
      dropdown[0].click();
    }
  }, [stir, isCommitted]);

  const handlePreferencesChange = (newTeamPicks) => {
    if (teamPicks?.current?.length === 0 && newTeamPicks?.length === 0) return;
    if (teamPicks?.current?.map(x => x.teamHash + x.pickRank).join('|') === newTeamPicks?.map(x => x.teamHash + x.pickRank).join('|')) return;

    isDirty.current = true;
    teamPicks.current = newTeamPicks;
    isOverridingTeamPicks.current = false;

    setIsSchedulingSave(true);

    setStir({ ...stir });
  }

  useEffect(() => {
    if (!stir || isWebsocketConnected.current) return;

    isWebsocketConnected.current = true;

    var socket = WebsocketService.openWebsocket((message) => {
      console.debug('socket message received:');
      console.debug(message);

      if (message.data.includes(stir.hash)) {
        isLoadingStir.current = undefined;

        loadStir(stir.hash);

        if (message.data.includes('Resolved')) {
          socket.close(1000, 'stir resolved');
          return;
        }

        if (message.data.includes('Committed')) {
          setDraftTeamPicks(undefined);
        }
      }
    });
  }, [stir, loadStir]);

  useEffect(() => {
    const action = UrlService.getUrlParam("action");
    if (action && stir && !isLoadingTeamPicks.current && !isEmailActionProcessed) {
      handleDropdownActions(action);
      setIsEmailActionProcessed(true);
    }
  }, [handleDropdownActions, isEmailActionProcessed, isLoadingTeamPicks.current, stir]);

  const getResolutionText = () => { //TODO: copy this text from MethodStep?
    const adjective = currentStep.methodId === Constants.methodType.Group
      ? "approved"
      : currentStep.methodId === Constants.methodType.Rank
        ? "preferred"
        : "drafted";

    const nounPhrase = (currentStep.methodId === Constants.methodType.Group) ? "highest approval" : "strongest preference";
    if (currentStep.isAssign() && currentStep.resolutionCount === 1) {
      return `This method will assign each participant with one of their ${adjective} teams if possible.`;
    } else if (currentStep.isAssign()) {
      const matchCount = Math.max(stir.participantCount, stir.teamCount);
      const matchesPerParticipantFloor = Math.floor(matchCount / stir.participantCount);
      const matchesPerParticipantCeiling = Math.ceil(matchCount / stir.participantCount);
      const matchesPerParticipantText = (matchesPerParticipantFloor === matchesPerParticipantCeiling)
        ? TextService.pluralize(matchesPerParticipantFloor, "team")
        : `${matchesPerParticipantFloor} or ${TextService.pluralize(matchesPerParticipantCeiling, "team")}`;
      return `This method will assign all matches, which means each participant will be matched with ${matchesPerParticipantText} so that all teams are assigned.`;
    } else if (currentStep.isElect()) {
      return `This method will elect the ${TextService.pluralize(currentStep.resolutionCount, "team")} with the ${nounPhrase} among participants.`;
    } else if (currentStep.isExclude()) {
      return `This method will exclude the ${TextService.pluralize(currentStep.resolutionCount, "team")} with the ${nounPhrase} among participants.`;
    }
    return "";
  }

  const getDraftedTeamHashes = () => Object.values(draftTeamPicks ?? [])
    .filter(picks => picks.stepParticipant.isCommitted)
    .map(picks => picks.picks.map(p => p.teamHash))
    .flat();

  let maxPickCount = undefined;
  let availableTeamHashes = undefined;

  if (currentStep) {
    if (isSelfAssign) {
      availableTeamHashes = stir.assignedTeamsPerParticipant[user.publicHash] ?? [];
    } else {
      const unavailableTeamHashes = currentStep.restrictedOutcomes.filter(x => x.participantHash === user.publicHash).map(x => x.teamHash);
      availableTeamHashes = stir.availableTeamHashes.filter(x => !unavailableTeamHashes.includes(x));
    }

    if (isDraft) {
      maxPickCount = Math.floor(getDraftedTeamHashes().length / stir.participantCount) + 1;
    }
    else if (isSelfAssign) {
      if (currentStep.resolutionCount !== 0) {
        maxPickCount = currentStep.resolutionCount;
      }
      else {
        maxPickCount = availableTeamHashes?.length ?? 0;
      }
    }
    else if (currentStep.isAssign() && currentStep.resolutionCount !=0) {
      maxPickCount = Math.min(stir.participantCount * currentStep.resolutionCount, stir.teamCount) //TODO: Test negative resolutionCount
    }
    else {
      maxPickCount = stir.teamCount;
    }
  }

  const getCommitButton = () => {
    const isWaiting = isSchedulingSave || isDirty.current || isCommitted.current !== participant.current.isCommitted;
    const isDraftDisabled = isDraft && (isCommitted.current == true || teamPicks.current.length < maxPickCount);
    const isDisabled = isWaiting || isDraftDisabled;
    let overlayText = "";

    if (isWaiting) {
      overlayText = `saving ${isDraft ? 'draft picks' : 'preferences'}...`;
    } else if (isDraft) {
      overlayText = isCommitted.current
        ? "Your draft picks are committed and will be assigned to you. Once other participants have made their draft picks in the current round, you will be able to draft additional teams."
        : "Your draft picks are saved but not committed. Modify your picks or click commit for the draft to proceed."
    } else {
      overlayText = isCommitted.current
        ? "Your preferences are committed. To make changes, first uncommit your preferences. Once all participants have committed their preferences (or time runs out), teams will be assigned. You may uncommit your preferences any time before then."
        : "Your preferences are saved but not committed. The stir will not end until all participants have clicked the 'commit' button or time runs out. You may uncommit your preferences any time before then.";
    }

    return <HelpOverlay text={overlayText} content={
      <div className={`status-alert-button ${(isDisabled ? 'disabled-button' : '')} ${isWaiting ? 'waiting-button' : ''}`}
        onClick={() => {
          if (!isDisabled) {
            isCommitted.current = !isCommitted.current;
            setIsSchedulingSave(true);
          }
        }}>
        {isWaiting ? <Icon icon="loading" /> : isCommitted.current ? "uncommit" : "commit"}
      </div>
    } />;
  }

  const getLeaveStirModal = () => {
    return <ConfirmationModal
      title="leave stir"
      isOpen={isLeaveStirModalOpen}
      content={
        <div className="modal-element">
          {"Are you sure you want to leave this stir? This action cannot be undone."}
        </div>
      }
      secondaryText="no"
      yesText="yes"
      onSecondary={() => handleModalChange(false)}
      onNo={() => handleModalChange(false)}
      onYes={() => handleModalChange(true)}
    />;
  }

  const getStatusAlert = () => {
    let icon = "unselected";
    let style = "info";
    let displayText = `${entityText} not yet committed`;
    let button = getCommitButton();
    let helptext = `Your ${entityText} are saved, but you have not committed them yet.`;

    if (isError) {
      icon = "caution";
      style = "danger";
      displayText = TextService.genericErrorText;
      helptext = TextService.genericErrorText;
      button = null;
    } else if (currentStep.isDraft() && (user.publicHash !== currentStep.activeUserHash)) {
      displayText = "it is currently " + stir.getParticipant(currentStep.activeUserHash)?.name + "'s turn";
      helptext = "After " + stir.getParticipant(currentStep.activeUserHash)?.name + " and any other participants ahead of you have committed their draft preferences, and if teams are still available, you may commit your next draft preferences.";
      button = null;
    } else if (isCommitted.current && !participant.current.isCommitted) {
      icon = "in-progress";
      displayText = `committing ${entityText}...`;
      helptext = `Saving ${entityText}...`;
    } else if (!isCommitted.current && participant.current.isCommitted) {
      icon = "in-progress";
      displayText = `uncommitting ${entityText}...`;
      helptext = `Saving ${entityText}...`;
    } else if (!isDirty.current && participant.current.isCommitted) {
      icon = "check";
      displayText = `${entityText} committed`;
      helptext = `Your ${entityText} are saved and committed.`;
    } else if (isDirty.current) {
      icon = "in-progress";
      helptext = `Saving ${entityText}...`;
    }

    return <Alert transition={null} className="left" variant={style}>
      <HelpOverlay text={helptext} content={
        <span><Icon icon={icon} css="status-alert-icon" />{displayText}</span>
      } />
      {button}
    </Alert>;
  }

  const getSettings = () => {
    return (currentStep?.isAssign() && (currentStep?.isDraft() !== true) && (currentStep?.algorithmId !== Constants.algorithmType.Predetermined) &&
      <div className="stir-checkbox left">
        <FormCheck
          id="concede-ties-checkbox"
          value={concedeTies}
          checked={concedeTies}
          disabled={isCommitted.current}
          onChange={(e) => { setConcedeTies(e.target.value === 'false'); isDirty.current = true;  setIsSchedulingSave(true); }} />
        <span>
          <label htmlFor="concede-ties-checkbox">assign me last</label>
          <Icon icon="question" css="silver" helptext="By default, teamstir resolves ties (when two participants set the same preference for the same team) randomly. However, with this option, you are voluntarily letting other players' preferences take priority." />
        </span>
      </div>);
  }

  const getContent = () => {
    if (!currentStep) {
      return null;
    }

    if (currentStep.methodId === Constants.methodType.Rank) {
      return <RankDetails
        stir={stir}
        teamPicks={teamPicks.current || []}
        availableTeamHashes={availableTeamHashes}
        maxPickCount={maxPickCount}
        isCommitted={isCommitted.current}
        resolutionText={getResolutionText()}
        getNoContentControl={getNoContentControl}
        handlePreferencesChange={handlePreferencesChange}
        isOverridingTeamPicks={isOverridingTeamPicks.current}
      />;
    } else if (currentStep.methodId === Constants.methodType.Group || currentStep.methodId === Constants.methodType.SelfAssign) {
      return <GroupDetails
        stir={stir}
        teamPicks={teamPicks.current || []}
        availableTeamHashes={availableTeamHashes}
        maxPickCount={maxPickCount}
        isCommitted={isCommitted.current}
        resolutionText={getResolutionText()}
        getNoContentControl={getNoContentControl}
        handlePreferencesChange={handlePreferencesChange}
        isOverridingTeamPicks={isOverridingTeamPicks.current}
      />;
    } else if (isDraft) {
      return <DraftDetails
        stir={stir}
        teamPicks={teamPicks.current || []}
        draftTeamPicks={draftTeamPicks}
        draftedTeamHashes={getDraftedTeamHashes()}
        availableTeamHashes={availableTeamHashes}
        maxPickCount={maxPickCount}
        isCommitted={isCommitted.current}
        resolutionText={getResolutionText()}
        getNoContentControl={getNoContentControl}
        handlePreferencesChange={handlePreferencesChange}
        isOverridingTeamPicks={isOverridingTeamPicks.current}
        user={props.user}
      />;
      //TODO: error page for default case
    }
  }

  const getNoContentControl = (adjective, helptext) => {
    return (
      <div className="no-content-outer half">
        <div className="no-content-inner">
          <p>{`no ${adjective} teams`}</p>
          <p className="no-content-description">{helptext}</p>
        </div>
      </div>
    );
  }

  const handleModalChange = (isConfirmed) => {
    setIsLeavingStirModalOpen(false);

    if (!isConfirmed) return;

    EntityService.setAsyncData((_, status) => {
      if (status === Constants.httpStatus.Ok) {
        props.navigate(UrlService.getUrl("/" + stir.hash));
      } else {
        setIsError(true);
      }
    }, '/api/stir/administer/' + stir.hash + '?user=' + user.hash, { removeHashes: [props.user.hash] }, "POST");
  }

  if (stir?.dateResolved) {
    props.navigate(UrlService.getUrl("/" + stir.hash));
  }

  if (notFound) return <NoContent noun="stir or user" />;

  if (isLoadingStir.current || isLoadingTeamPicks.current || !stir || !teamPicks.current || !participant.current || isLoadingTeamPicks.current || (isDraft && isLoadingDraftPicks.current)) return <Spinner />;

  return (
    <div className="center">
      {getLeaveStirModal()}
      <div className="tile-container">
        <Breadcrumbs user={user} stir={stir} theme={stir.theme} event={event} />
        <StirTile
          key={stir.hash}
          stir={stir}
          isCommitted={isCommitted.current}
          isHeader={true}
          handleStirSelect={handleDropdownActions}
          page={Constants.page.Preference}
          user={user}
        />
        {getStatusAlert()}
        {getSettings()}
        {getContent()}
      </div>
    </div>
  );
}
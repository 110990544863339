import React, { Component } from 'react';
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { Dropdown } from 'react-bootstrap';
import { HelpOverlay } from '../shared/HelpOverlay';
import { Icon } from '../shared/Icon';
import Validator from '../../services/ValidationService';
import Constants from '../../services/Constants';
import { UrlService } from '../../services/UrlService';
import EntityService from '../../services/EntityService';
import '../../css/StirParticipant.css';

export class StirParticipant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      name: props.name,
      email: props.email,
      isNameLocked: props.isNameLocked,
      stirParticipantTypeId: props.stirParticipantTypeId
    };
    this.handleNameOrEmailChange = this.handleNameOrEmailChange.bind(this);
    this.handleEmailBlur = this.handleEmailBlur.bind(this);
    this.handleStirParticipantTypeChange = this.handleStirParticipantTypeChange.bind(this);
    this.renderNameMenu = this.renderNameMenu.bind(this);
    this.renderEmailMenu = this.renderEmailMenu.bind(this);
  }

  update() {
    this.props.handleParticipantChange({ id: this.state.id, name: this.state.name, email: this.state.email, stirParticipantTypeId: this.state.stirParticipantTypeId, isNameLocked: this.state.isNameLocked });
  }

  handleNameOrEmailChange(e, isEmail) {
    let contact = { name: "", email: "", hash: null, isNameLocked: false };
    if (typeof e === "object") {
      if (!e.length) return;
      contact = e[0];
    } else if (typeof e === "string") {
      contact.email = isEmail ? e : this.state.email;
      contact.name = isEmail ? e.split("@")[0] : e;
    }
    contact.isNameLocked = Validator.isHashValid(contact.hash);
    this.setState({ email: contact.email, name: contact.name, isNameLocked: contact.isNameLocked }, () => { this.update(); });
  }

  handleEmailBlur(e) {
    const email = e.target.value;

    if (email === this.state.email) return;

    if (Validator.isEmailValid(email)) {
      EntityService.getAsyncData((data) => {
        if (data?.name) {
          this.setState({ name: data.name, isNameLocked: data.isNameLocked }, () => { this.update() });
        } else {
          this.setState({ isNameLocked: false });
        }
      }, 'api/participants/email/' + email);
    } else {
      this.setState({ isNameLocked: false });
    }
  }

  handleStirParticipantTypeChange(stirParticipantTypeId) {
    this.setState({ stirParticipantTypeId: stirParticipantTypeId }, () => { this.update() });
  }

  getIconName(stirParticipantTypeId) {
    switch (stirParticipantTypeId) {
      case Constants.stirParticipantType.Participant:
        return "participant";
      case Constants.stirParticipantType.AdminParticipant:
        return "admin";
      case Constants.stirParticipantType.Observer:
        return "observer";
      case Constants.stirParticipantType.AdminObserver:
        return "admin-observer";
      default:
        return "trash";
    }
  }

  getIconControl() {
    if (!this.props.showEmail && !this.props.showTrash) {
      return <div className="stir-participant-type-blank">
        <Icon css="stir-icon-toggle" icon="participant" />
      </div>;
    }

    const customToggle = React.forwardRef(({ children, onClick }, ref) => (
      <div ref={ref} className={""} onClick={(e) => { e.preventDefault(); if (onClick) { onClick(e); } }} key={this.state.id} data-id={this.state.id}>
        {children}
      </div>
    ));

    let dropdownActions = !this.props.showEmail ? [] :
      [
        { label: "participant (admin)", stirParticipantTypeId: Constants.stirParticipantType.AdminParticipant, onClick: this.handleStirParticipantTypeChange },
        { label: "participant", stirParticipantTypeId: Constants.stirParticipantType.Participant, onClick: this.handleStirParticipantTypeChange },
        { label: "observer (admin)", stirParticipantTypeId: Constants.stirParticipantType.AdminObserver, onClick: this.handleStirParticipantTypeChange },
        { label: "observer", stirParticipantTypeId: Constants.stirParticipantType.Observer, onClick: this.handleStirParticipantTypeChange },
      ];

    if (this.props.showTrash) {
      dropdownActions.push(
        { label: "remove this participant", onClick: () => this.props.handleParticipantTrash(this.state.id) }
      );
    }

    let index = 0;
    const controlId = "stirParticipantTypeToggle_" + this.state.id;
    return (
      <Dropdown className="stir-participant-type-dropdown">
        <Dropdown.Toggle as={customToggle} id={controlId}>
          <Icon css="stir-participant-type-toggle" icon={this.getIconName(this.props.stirParticipantTypeId)} />
          <Icon css="" icon={"caret-down"} />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-right">
          {dropdownActions.map(x => (
            <Dropdown.Item onClick={() => x.onClick(x.stirParticipantTypeId)} key={controlId + "_option_" + index++}>
              <Icon css="stir-icon-toggle" icon={this.props.showEmail ? this.getIconName(x.stirParticipantTypeId) : "participant"} />
              <span className="stir-icon-toggle-label">{x.label}</span>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  renderNameMenu(results, menuProps) {
    return (!results.length) ? null : (
      <Menu {...menuProps}>
        {results.filter(result => !this.props.contactsInStir.map(contact => contact.email).includes(result.email)).map((result, index) => (
          <MenuItem key={this.props.id + "_" + index} option={result} position={index}>
            {result.name + (result.email.includes('@') ? ` (${result.email})` : '')}
          </MenuItem>
        ))}
      </Menu>);
  }

  renderEmailMenu(results, menuProps) {
    return (!results.length) ? null : (
      <Menu {...menuProps}>
        {results.filter(result => !this.props.contactsInStir.map(contact => contact.email).includes(result.email)).map((result, index) => (
          <MenuItem key={this.props.id + "_" + index} option={result} position={index}>
            {result.email + (this.state.isNameLocked || (result.name?.length && !result.email.includes(result.name + '@')) ? ` (${result.name})` : '')}
          </MenuItem>
        ))}
      </Menu>);
  }

  getEmailControl() {
    if (!this.props.showEmail) {
      return null;
    }

    const id = `stirparticipant_participant_email_${this.props.id}`;
    const autocomplete = UrlService.getUserHashFromUrl()?.length > 0 ? "off" : "on";
    const isContact = this.props.contacts.map(x => x.email).includes(this.state.email);
    const options = isContact ? this.props.contacts : this.props.contacts.concat(this.state);
    return <Typeahead
      inputProps={{
        autoComplete: autocomplete,
        id: `${id}_input`
      }}
      autoComplete={autocomplete}
      key={id}
      id={id}
      selected={options.filter(o => o.email === this.state.email)}
      onBlur={this.handleEmailBlur}
      onChange={(e) => this.handleNameOrEmailChange(e, true)}
      onInputChange={(e) => this.handleNameOrEmailChange(e, true)}
      className="half-stir-participant"
      options={options}
      renderMenu={this.renderEmailMenu}
      labelKey="email"
      placeholder=""
    />;
  }

  getNameControl() {
    const isDisabled = this.state.isNameLocked;
    const id = `stirparticipant_participant_name_${this.props.id}`;
    const autocomplete = UrlService.getUserHashFromUrl()?.length > 0 ? "new-password" : "on";

    const control = <Typeahead
      inputProps={{
        autoComplete: autocomplete,
        id: `${id}_input`
      }}
      autoComplete={autocomplete}
      key={id}
      id={id}
      disabled={isDisabled}
      defaultInputValue={this.state.name}
      onChange={(e) => this.handleNameOrEmailChange(e, false)}
      onInputChange={(e) => this.handleNameOrEmailChange(e, false)}
      className={this.props.showEmail ? "half-stir-participant" : "full-stir-participant"}
      options={this.props.contacts || []}
      renderMenu={this.renderNameMenu}
      labelKey="name"
      placeholder=""
    />;

    return isDisabled ? <HelpOverlay text="You cannot edit this participant's name because they have already set it themselves." content={control} /> : control;
  }

  render() {
    return (
      <div className="stir-participant">
        {this.getEmailControl()}
        {this.getNameControl()}
        {this.getIconControl()}
      </div>
    );
  }
}

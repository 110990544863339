import React, { Component } from 'react';
import '../../css/Spinner.css';

export class Spinner extends Component {
  render() {
    return (
      <div className="loading-container">
        <i className="fa fa-spinner fa-pulse fa-6x fa-fw"></i>
      </div>);
  }
}

import React, { Component } from 'react';
import { Typeahead, Token } from 'react-bootstrap-typeahead';
import { Icon } from '../shared/Icon';
import { Tile } from './Tile';
import _uniqueId from 'lodash/uniqueId';
import '../../css/TileMini.css';

export class ParticipantAssignTile extends Component {

  constructor(props) {
    super(props);
    this.state = { selectedTeams: props.selectedTeams };
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
  }

  handleSelectionChange(selectedTeams) {
    this.setState({ selectedTeams: selectedTeams }, () => {
      this.props.handleSelectionChange({ participantHash: this.props.participant.hash, selectedTeamHashes: this.state.selectedTeams.map(x => x.hash) });
    });
  }

  getFooter() {
    if (this.props.isObserver) return null;

    const id = this.props.participant.hash;
    const options = this.props.availableTeams;
    return <Typeahead
      inputProps={{
        autoComplete: "on",
        id: `${id}_input`
      }}
      autoComplete={"on"}
      key={id}
      id={id}
      selected={options.filter(o => (this.state.selectedTeams || []).map(x => x.hash).includes(o.hash))}
      onChange={this.handleSelectionChange}
      options={options}
      labelKey="name"
      placeholder="click to select teams"
      className="tile-mini-container"
      renderToken={(option, props, index) => {
        return (
          <Token className="tile-mini team not-grabbable" key={index} onRemove={props.onRemove} option={option}>
            {option.name}
          </Token>
        );
      }}
      multiple
    />;
  }
  
  render() {
    return <Tile
      isHeader={this.props.isHeader}
      icon={<Icon icon={this.props.isObserver ? "observer" : "participant"} />}
      header={this.props.participant.name}
      footer={this.getFooter()}
      headerElements={undefined}
      footerElements={undefined}
      dropdownActions={[]}
      hash={this.props.participant.hash}
      css={"participant-assign"}
      {...this.props}
    />;
  }
}

import React, { Component } from 'react';
import { Icon } from '../shared/Icon';
import { Tile } from './Tile';
import TextService from '../../services/TextService';

export class ParticipantTile extends Component {

  constructor(props) {
    super(props);
    if (!props.participant.isInitialized) throw new Error("participant not initialized");
  }

  getIcon() {
    const icon = this.props.participant.isObserver ? "observer" : this.props.isSecretReveal ? "user-secret" : "participant";
    return <Icon icon={icon} helptext={this.props.participant.name} />;
  }

  getFooter() {
    return <>{this.props.participant.email}</>;
  }

  getHeaderElements() {
    return null;
  }

  getFooterElements() {
    if (this.props.participant.isObserver) {
      return null;
    } else if (this.props.participant.totalStirCount) {
      return <>{this.props.participant.totalStirCount} <Icon icon="stir" helptext={"you have participated in " + TextService.pluralize(this.props.participant.totalStirCount, "stir")} /></>;
    } else if (this.props.activeUserOrder >= 0) {
      return (this.props.activeUserOrder === 0)
        ? <Icon icon="hand" helptext={"it is currently " + this.props.participant.name + "'s turn"} />
        : <div className="bold">{this.props.activeUserOrder} <Icon icon="in-progress" helptext={this.props.participant.name + " drafts in " + TextService.pluralize(this.props.activeUserOrder, "turn")} /></div>;
    } else if (this.props.participant.isCommitted) {
      return <Icon icon="check" helptext={this.props.participant.name + "'s preferences are committed"} />;
    } else {
      return <Icon icon="unselected" helptext={this.props.participant.name + "'s preferences are not yet committed"} />;
    }
  }

  getDropdownActions() {
    return [];
  }

  getCss() {
    return this.props.isCommitted ? "green-border" : "";
  }

  render() {
    return <Tile
      isHeader={this.props.isHeader}
      icon={this.getIcon()}
      header={this.props.participant.name}
      footer={this.getFooter()}
      headerElements={this.getHeaderElements()}
      footerElements={this.getFooterElements()}
      dropdownActions={this.getDropdownActions()}
      hash={this.props.participant.hash}
      css={this.getCss()}
      {...this.props}
    />;
  }
}

import TextService from '../services/TextService';

export default class BggService {

  static getGameData = (bggId, callback) => {
    fetch('api/bgg/game/' + bggId)
      .then(response => response.text())
      .then(data => new DOMParser().parseFromString(data, "text/xml"))
      .then(xml => {
        let data = undefined;
        if (xml.getElementsByTagName("parsererror").length === 0) {
          data = BggService.getGameDataFromXml(xml);
        }
        return callback(data);
      });
  }

  static getUserData = (userName, callback) => {
    fetch('api/bgg/user/' + userName)
      .then(response => response.text())
      .then(data => new DOMParser().parseFromString(data, "text/xml"))
      .then(xml => {
        let data = undefined;
        if (xml.getElementsByTagName("parsererror").length === 0) {
          data = BggService.getUserDataFromXml(xml);
        }
        return callback(data);
      });
  }

  static getCollectionData = (userName, callback) => {
    fetch('api/bgg/collection/' + userName)
      .then(response => response.text())
      .then(data => new DOMParser().parseFromString(data, "text/xml"))
      .then(xml => {
        let data = undefined;
        if (xml.getElementsByTagName("parsererror").length === 0 && xml.getElementsByTagName("error").length === 0 &&
          !(xml.getElementsByTagName("message").length > 0 && xml.getElementsByTagName("message")[0].innerHTML.includes("Please try again later for access."))) {
          data = BggService.getCollectionDataFromXml(xml);
        }
        return callback(data);
      });
  }

  static getGameDataFromXml = (xml) => {
    let data = {};

    data.bggId = parseInt(xml.getElementsByTagName("item")[0].getAttribute("id"));

    data.year = xml.getElementsByTagName("yearpublished")[0].getAttribute("value");

    const ratings = xml.getElementsByTagName("ratings")[0].children;
    const average = Array.prototype.filter.call(ratings, x => x.tagName === "average");
    if (average && average.length && average[0].getAttribute("value") && !isNaN(parseFloat(average[0].getAttribute("value")))) {
      data.rating = parseFloat(average[0].getAttribute("value")).toFixed(1)
    }

    const rawDescription = xml.getElementsByTagName("description")[0].innerHTML;
    data.description = TextService.htmlUnescape(rawDescription.replace(/<br\/>/g, " ").replace(/\s{1,}/g, " "));

    data.url = `https://boardgamegeek.com/boardgame/${data.bggId}`;

    data.thumbnailUrl = xml.getElementsByTagName("thumbnail")[0].innerHTML;

    const links = xml.getElementsByTagName("link");
    const categories = Array.prototype.filter.call(links, x => x.getAttribute("type") === "boardgamecategory").map(x => x.getAttribute("value"));
    data.category = categories.join(", ");

    return data;
  };

  static getUserDataFromXml = (xml) => {
    let data = {};
    if (xml) {
      data.username = xml.getElementsByTagName("user")[0].getAttribute("name");
      data.firstname = xml.getElementsByTagName("firstname")[0].getAttribute("value");
      data.lastname = xml.getElementsByTagName("lastname")[0].getAttribute("value");
    }
    return data;
  }

  static getCollectionDataFromXml = (xml) => {
    let data = {};
    if (xml) {
      const items = xml.getElementsByTagName("item");
      data.bggTitles = [...new Set(Array.prototype.map.call(items, x => x.getElementsByTagName("name")[0].innerHTML))];
    }
    return data;
  }
}

import React, { useState } from 'react';
import { FormControl, Alert } from 'react-bootstrap';
import { ConfirmationModal } from '../shared/ConfirmationModal';
import { BlurbTile } from '../shared/BlurbTile';
import Constants from '../../services/Constants';
import Validator from '../../services/ValidationService';
import TextService from '../../services/TextService';
import EntityService from '../../services/EntityService';
import { UrlService } from '../../services/UrlService';
import '../../css/home.css';

export function Contact() {
  const actionStates = {
    Default: 0,
    ModalOpenValid: 1,
    ModalOpenInvalid: 2,
    SendingEmail: 3,
    EmailNotFound: 4,
    EmailSent: 5,
    Error: 6
  }

  const [actionState, setActionState] = useState(actionStates.Default);
  const [userEmail, setUserEmail] = useState("");

  UrlService.useTitle('Contact');

  const handleEmailChange = (e) => {
    const userEmail = e.target.value;
    setUserEmail(userEmail);
    setActionState(Validator.isEmailValid(userEmail) ? actionStates.ModalOpenValid : actionStates.ModalOpenInvalid);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleModalChange(actionStates.SendingEmail);
    }
  };

  const getModalContent = () => {
    return (
      <>
        <div className="modal-element">Enter your email address, and we'll send links to your profile and active stirs.</div>
        <br />
        <FormControl autoFocus value={userEmail} onChange={handleEmailChange} onKeyPress={handleKeyPress} placeholder="enter your email" />
      </>);
  }

  const handleModalChange = (newActionState) => {
    setActionState(newActionState);

    if (newActionState === actionStates.SendingEmail) {
      EntityService.getAsyncData((_, status) => {
        if (status === Constants.httpStatus.Ok) {
          setActionState(actionStates.EmailSent);
        } else if (status === Constants.httpStatus.NotFound) {
          setActionState(actionStates.EmailNotFound);
        } else {
          setActionState(actionStates.Error);
        }
      }, 'api/email/sendRecoveryLinks/' + userEmail);
    }
  }

  const getStatusAlert = () => {
    switch (actionState) {
      case actionStates.SendingEmail:
        return <Alert transition={null} variant="info">checking email '{userEmail}'...</Alert>;
      case actionStates.EmailNotFound:
        return <Alert transition={null} variant="danger">'{userEmail}' is not in our database or there was an error sending the email.</Alert>;
      case actionStates.EmailSent:
        return <Alert transition={null} variant="success">an email has been sent to {userEmail} - please allow a few moments and check your inbox</Alert>;
      case actionStates.Error:
        return <Alert transition={null} variant="danger">{TextService.genericErrorText}</Alert>;
      default:
        return null;
    }
  }

  return (
    <div className="home-body">
      <div className="contact-page-inner">
        <ConfirmationModal
          title="enter email"
          isOpen={[actionStates.ModalOpenValid, actionStates.ModalOpenInvalid].includes(actionState)}
          content={getModalContent()}
          yesText="send"
          onNo={() => handleModalChange(actionStates.Default)}
          onYes={() => handleModalChange(actionStates.SendingEmail)}
          isInvalid={actionState === actionStates.ModalOpenInvalid}
        />
        {getStatusAlert()}
        <div className="large-blurb-text">
          how can we help?
        </div>
        <div className="method-blurb-container">
          <span className="stretch"></span>
          <BlurbTile icon="email" url="mailto:info@teamstir.io" head="contact us" blurb="send an email|to the developers|(info@teamstir.io)" helptext="want to be heard? email the developers and let us know if you think teamstir is amazing / helpful / terrible." />
          <span className="stretch"></span>
          <BlurbTile icon="link" func={() => { handleModalChange(Validator.isEmailValid(userEmail) ? actionStates.ModalOpenValid : actionStates.ModalOpenInvalid); }} head="get links" blurb="resend URLs to|your profile and stirs" helptext="if you have participated in a stir but can't find it, click here to receive an email with links to your profile and any stirs you're in" />
          <span className="stretch"></span>
        </div>
      </div>
    </div>
  );
}

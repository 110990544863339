export default class Constants {

  static hash = {
    administrator: 'a',
    team: 'e',
    theme: 'h',
    link: 'l',
    observer: 'o',
    match: 'r',
    stir: 's',
    step: 't',
    event: 'v',
    participant: 'u',
    user: 'x'
  };

  static page = {
    Administrator: 1,
    Event: 2,
    Preference: 3,
    Stir: 4,
    Theme: 5,
    User: 6,
    Assign: 7,
  };

  static oneMinute = 60000; // milliseconds
  static oneSecond = 1000; // milliseconds

  static maxStepNumber = 4;

  static defaultTeamMinCount = 1;
  static defaultTeamMaxCount = 20;

  static defaultParticipantMinCount = 1;
  static defaultParticipantMaxCount = 200;

  static minAssignTeamCount = 1;
  static minElectOrExcludeTeamCount = 2;

  static stepNumbers = [...Array(Constants.maxStepNumber).keys()].map(x => ++x);

  static colorThemes = [
    { id: 1, name: 'Light Theme', css: './colorThemes/ThemeLight.css' },
    { id: 2, name: 'Dark Theme', css: './colorThemes/ThemeDark.css' },
    { id: 3, name: 'Sunset Theme', css: './colorThemes/ThemeSunset.css' },
    { id: 4, name: 'Ocean Theme', css: './colorThemes/ThemeOcean.css' },
  ];

  static httpStatus = {
    Ok: 200,
    Redirect: 303,
    BadRequest: 400,
    NotFound: 404,
    Error: 500
  };

  static pageDelays = {
    MaximumRefreshCount: 36,
    InitialRefreshSeconds: 30,
    IncrementRefreshSeconds: 4,
    AfterActionSeconds: 2,
    BetweenSaveSeconds: 5
  };

  // Use negative values in enum to avoid id collision.
  static actionType = {
    leaveThisStir: -1,
    clearPreferences: -2,
    clearPreferencesAndCommit: -3,
    addParticipant: -4,
    removeThisParticipant: -5,
    setDeadline: -6
  };

  static resolutionType = {
    None: 0,
    Assign: 1,
    MirrorAssign_Deprecated: 2,
    Elect: 3,
    Exclude: 4
  };

  static methodType = {
    None: 0,
    Random: 1,
    Group: 2,
    Rank: 3,
    Draft: 4,
    Admin: 5,
    SelfAssign: 6,
  };

  static algorithmType = {
    None: 0,
    Hungarian: 1,
    Cascade: 2,
    Random: 3,
    BordaCount: 4,
    Approval: 5,
    Roommate: 6,
    Knockout: 7,
    Draft: 8,
    Predetermined: 9,
  };

  static stirParticipantType = {
    Participant: 1,
    AdminParticipant: 2,
    Observer: 3,
    AdminObserver: 4,
  };

  static themeType = {
    ExistingCustom: 0, // This entry doesn't exist in the back end
    Game: 1,
    Generated: 2,
    Custom: 3,
    MirrorParticipants: 4,
    BggCollection: 5,
  };

  static tieBreakerType = {
    Random: 0,
    EventKarma: 1,
    ParticipantKarma: 2,
    Priority: 3,
  };

  static revealType = {
    ToAll: 1,
    ToParticipant: 2,
  };

  static assignType = {
    NotAssign: 0,
    CannotPredict: 1,
    TeamsPerParticipant: 2,
    ParticipantsPerTeam: 3,
    AllAvailableTeams: 4,
    ExistingMatches: 5,
  };
}

import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';
import { Icon } from './Icon';
import DateService from '../../services/DateService';

export class EmailButton extends Component {
  // This button has three basic looks...
  // 1) You have not sent an email yet, so you see a regular envelope. (helptext tells you what the button does)
  // 2) You have sent an email, so you see an envelope with a green check. (helptext tells you when it was sent)
  // 3) You are currently sending an email, so you see a spinner and cannot interact (helptext tells you it is sending)
  render() {
    return this.props.isSending
      ? (
        <Nav.Link to="#" className="disabled-button">
            <Icon icon={"loading"} helptext={`sending invitation emails to all participants in this ${this.props.entityName}...`} />
        </Nav.Link>
      ) : (
        <Nav.Link to="#" onClick={this.props.onClick}>
            {this.props.dateSent
              ? <Icon icon="email-success" helptext={`participants were sent invitations on ${DateService.getDateText(this.props.dateSent)}`} />
              : <Icon icon="email" helptext={`send invitation emails to all participants in this ${this.props.entityName}`} />
            }
        </Nav.Link>
      );
  }
}
import 'bootstrap/dist/css/bootstrap.css';
import './css/index.css';
import './css/google.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App.1';

// TODO: We're currently renaming App.js with a hash to refresh browser cache.
// But we can also attempt to use cache headers or implement a minifcation webpack.

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const root = createRoot(rootElement);

root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>
);

import React, { Component } from 'react';
import Validator from '../../services/ValidationService';
import { UrlService } from '../../services/UrlService';
import Constants from '../../services/Constants';
import { HelpOverlay } from '../shared/HelpOverlay';
import { Icon } from '../shared/Icon';
import { Link } from 'react-router-dom';
import '../../css/Breadcrumbs.css';

export class Breadcrumbs extends Component {

  static user = 1;
  static stir = 2;
  static home = 3;
  static event = 4;

  getAdminCrumbs(user, stir, theme, event) {
    if (user?.name && event?.name && stir?.name) {
      return [ // user > event > stir > admin
        <Breadcrumb key="1" text={user.name} path={"/" + user.hash} type={Breadcrumbs.user} />,
        <Breadcrumb key="2" text={event.name} path={"/" + event.hash} type={Breadcrumbs.event} />,
        <Breadcrumb key="3" text={stir.name} path={"/" + stir.hash} type={Breadcrumbs.stir} />,
        <Breadcrumb key="4" text="administer" />
      ];
    } else if (user?.name && stir?.name) {
      return [ // user > stir > admin
        <Breadcrumb key="1" text={user.name} path={"/" + user.hash} type={Breadcrumbs.user} />,
        <Breadcrumb key="2" text={stir.name} path={"/" + stir.hash} type={Breadcrumbs.stir} />,
        <Breadcrumb key="3" text="administer" />
      ];
    }
  }

  getThemeCrumbs(user, stir, theme, event) {
    if (user?.name && event?.name && stir?.name && theme?.name) {
      return [ // user > event > stir > theme
        <Breadcrumb key="1" text={user.name} path={"/" + user.hash} type={Breadcrumbs.user} />,
        <Breadcrumb key="2" text={event.name} path={"/" + event.hash} type={Breadcrumbs.event} />,
        <Breadcrumb key="3" text={stir.name} path={"/" + stir.hash} type={Breadcrumbs.stir} />,
        <Breadcrumb key="4" text={theme.name} />
      ];
    } else if (event?.name && stir?.name && theme?.name) {
      return [ // home > event > stir > theme
        <Breadcrumb key="1" text="teamstir" path="/" type={Breadcrumbs.home} />,
        <Breadcrumb key="2" text={event.name} path={"/" + event.hash} type={Breadcrumbs.event} />,
        <Breadcrumb key="3" text={stir.name} path={"/" + stir.hash} type={Breadcrumbs.stir} />,
        <Breadcrumb key="4" text={theme.name} />
      ];
    } else if (user?.name && stir?.name && theme?.name) {
      return [ // user > stir > theme
        <Breadcrumb key="1" text={user.name} path={"/" + user.hash} type={Breadcrumbs.user} />,
        <Breadcrumb key="2" text={stir.name} path={"/" + stir.hash} type={Breadcrumbs.stir} />,
        <Breadcrumb key="3" text={theme.name} />
      ];
    } else if (stir?.name && theme?.name) {
      return [ // home > stir > theme
        <Breadcrumb key="1" text="teamstir" path="/" type={Breadcrumbs.home} />,
        <Breadcrumb key="2" text={stir.name} path={"/" + stir.hash} type={Breadcrumbs.stir} />,
        <Breadcrumb key="3" text={theme.name} />
      ];
    } else if (user?.name && theme?.name) {
      return [ // user > theme
        <Breadcrumb key="1" text={user.name} path={"/" + user.hash} type={Breadcrumbs.user} />,
        <Breadcrumb key="2" text={theme.name} />
      ];
    } else if (theme?.name) {
      return [ // home > theme
        <Breadcrumb key="1" text="teamstir" path="/" type={Breadcrumbs.home} />,
        <Breadcrumb key="2" text={theme.name} />
      ];
    }
  }

  getPickCrumbs(user, stir, theme, event) {
    if (user?.name && event?.name && stir?.name) {
      return [ // user > event > stir > set prefs
        <Breadcrumb key="1" text={user.name} path={"/" + user.hash} type={Breadcrumbs.user} />,
        <Breadcrumb key="2" text={event.name} path={"/" + event.hash} type={Breadcrumbs.event} />,
        <Breadcrumb key="3" text={stir.name} path={"/" + stir.hash} type={Breadcrumbs.stir} />,
        <Breadcrumb key="4" text="set preferences" />
      ];
    } else if (user?.name && stir?.name) {
      return [ // user > stir > set prefs
        <Breadcrumb key="1" text={user.name} path={"/" + user.hash} type={Breadcrumbs.user} />,
        <Breadcrumb key="2" text={stir.name} path={"/" + stir.hash} type={Breadcrumbs.stir} />,
        <Breadcrumb key="3" text="set preferences" />
      ];
    }
  }

  getStirCrumbs(user, stir, theme, event) {
    if (user?.name && event?.name && stir?.name) {
      return [ // user > event > stir
        <Breadcrumb key="1" text={user.name} path={"/" + user.hash} type={Breadcrumbs.user} />,
        <Breadcrumb key="2" text={event.name} path={"/" + event.hash} type={Breadcrumbs.event} />,
        <Breadcrumb key="3" text={stir.name} />
      ];
    } else if (user?.name && stir?.name) {
      return [ // user > stir
        <Breadcrumb key="1" text={user.name} path={"/" + user.hash} type={Breadcrumbs.user} />,
        <Breadcrumb key="2" text={stir.name} />
      ];
    } else if (event?.name && stir?.name) {
      return [ // home > event > stir
        <Breadcrumb key="1" text="teamstir" path="/" type={Breadcrumbs.home} />,
        <Breadcrumb key="2" text={event.name} path={"/" + event.hash} type={Breadcrumbs.event} />,
        <Breadcrumb key="3" text={stir.name} />
      ];
    } else if (stir?.name) {
      return [ // home > stir
        <Breadcrumb key="1" text="teamstir" path="/" type={Breadcrumbs.home} />,
        <Breadcrumb key="2" text={stir.name} />
      ];
    }
  }

  getUserCrumbs(user, stir, theme, event) {
    if (user?.name) {
      return [ // home > user
        <Breadcrumb key="1" text="teamstir" path="/" type={undefined} />,
        <Breadcrumb key="2" text={user.name} />
      ];
    }
  }

  getEventCrumbs(user, stir, theme, event) {
    if (user?.name && event?.name) {
      return [ // user > event
        <Breadcrumb key="1" text={user.name} path={"/" + user.hash} type={Breadcrumbs.user} />,
        <Breadcrumb key="2" text={event.name} />
      ];
    } else if (event?.name) {
      return [ // home > event
        <Breadcrumb key="1" text="teamstir" path="/" type={Breadcrumbs.home} />,
        <Breadcrumb key="2" text={event.name} />
      ];
    }
  }

  getBreadcrumbs() {
    const adminPath = "administer/";
    const pickPath = "pick/";
    const path = window.location.pathname.substr(1);
    const hash = path.replace(adminPath, "").replace(pickPath, "");

    if (!Validator.isHashValid(hash)) return null;

    const isAdmin = Validator.isHashValid(hash, Constants.hash.stir) && adminPath === path.substr(0, adminPath.length);
    const isPick = Validator.isHashValid(hash, Constants.hash.stir) && pickPath === path.substr(0, pickPath.length);

    const user = this.props.user;
    const stir = this.props.stir;
    const theme = this.props.theme;
    const event = this.props.event;

    if (isAdmin)                               return this.getAdminCrumbs(user, stir, theme, event);
    else if (isPick)                           return this.getPickCrumbs(user, stir, theme, event);
    else if (Validator.isHashValid(hash, Constants.hash.theme)) return this.getThemeCrumbs(user, stir, theme, event);
    else if (Validator.isHashValid(hash, Constants.hash.stir))  return this.getStirCrumbs(user, stir, theme, event);
    else if (Validator.isHashValid(hash, Constants.hash.user))  return this.getUserCrumbs(user, stir, theme, event);
    else if (Validator.isHashValid(hash, Constants.hash.event)) return this.getEventCrumbs(user, stir, theme, event);
    return null;
  }

  render() {
    let i = -1;
    const breadcrumbs = this.getBreadcrumbs();
    return (
      <div className="breadcrumb-container">
        {(breadcrumbs && breadcrumbs.length) ? breadcrumbs.reduce((r, a) => r.concat((<Breadcrumb key={i--} />), a), [0]).slice(2) : null}
      </div>
    );
  }
}

export class Breadcrumb extends Component {

  render() {
    let inner = null;
    if (this.props.path && this.props.text) {
      inner = <Link to={UrlService.getUrl(this.props.path)}>{this.props.text}</Link>;
    } else if (this.props.text) {
      inner = <>{this.props.text}</>;
    } else {
      inner = <Icon icon="breadcrumb-separator" />;
    }
    const content = <div className="breadcrumb">{inner}</div>;

    switch (this.props.type) {
      case Breadcrumbs.user:
        return <HelpOverlay content={content} text={"view " + this.props.text + "'s profile"} />;
      case Breadcrumbs.stir:
        return <HelpOverlay content={content} text={"view the " + this.props.text} />;
      case Breadcrumbs.home:
        return <HelpOverlay content={content} text={"return to the teamstir landing page"} />;
      case Breadcrumbs.event:
        return <HelpOverlay content={content} text={"view the event: " + this.props.text} />;
      default:
        return content;
    }
  }
}

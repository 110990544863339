import React, { Component } from 'react';
import { HelpOverlay } from '../shared/HelpOverlay';
import { Link } from 'react-router-dom';
import { Icon } from '../shared/Icon';

export class BlurbTile extends Component {

  getInnerContent() {
    const blurb = this.props.blurb.split("|").map(x => <span key={x}>{x}<br /></span>);
    return (
      <>
        <Icon icon={this.props.icon} />
        <div className="method-blurb-name">
          {this.props.head}
        </div>
        <div className="method-blurb-description">
          {blurb}
        </div>
      </>);
  }

  getContent() {
    if (this.props.link && this.props.link.length) {
      return <Link to={this.props.link} className="method-blurb">{this.getInnerContent()}</Link>;
    }
    if (this.props.url && this.props.url.length) {
      return <a href={this.props.url} target="_blank" rel="noopener noreferrer" className="method-blurb" >{this.getInnerContent()}</a>;
    }
    if (this.props.func) {
      return <button onClick={this.props.func} className="method-blurb">{this.getInnerContent()}</button>;
    }
    return null;
  }

  render() {
    return this.props.helptext
      ? <HelpOverlay text={this.props.helptext} content={this.getContent()} />
      : this.getContent();
  }
}


import _ from 'lodash';
import Match from './Match';
import Participant from './Participant';
import Theme from './Theme';
import Step from './Step';
import Constants from '../services/Constants';

//this class is for already created stirs and is mostly readonly. Stir creation uses a different model
export default class Stir {
  constructor(data) {
    let currentStep = data.steps.find(step => step.hash === data.currentStepHash);

    let participants = data.participantViews.map(participant => {
      const stepParticipant = data.stepParticipants.find(stepParticipant =>
        (stepParticipant.stepHash === data.currentStepHash && participant.hash === stepParticipant.participantHash));
      return new Participant(participant, stepParticipant);
    });

    let stir = {
      availableTeamHashes: data.availableTeamHashes,
      invitationDateLastSent: data.invitationDateLastSent,
      hash: data.hash,
      message: data.message,
      tieBreakerTypeId: data.tieBreakerTypeId,
      name: data.theme.name + " " + currentStep.methodName.toLowerCase() + " stir",
      theme: new Theme(data.theme),
      eventHash: data.eventHash,
      steps: data.steps.map(s => new Step(s)).sort((a, b) => a.resolutionSequence - b.resolutionSequence),
      stepCount: data.steps.length,
      themeName: data.theme.name,
      themeHash: data.theme.hash,
      teamCount: data.theme.teams.length,
      participants: participants,
      currentStep: currentStep,
      dateResolved: currentStep.dateResolved,
      stepParticipants: data.stepParticipants,
      participantCount: participants.filter(p => !p.isObserver).length,
      isSecretReveal: currentStep.isSecretReveal(),
      assignedTeamsPerParticipant: data.assignedTeamsPerParticipant,
    };

    stir.getParticipant = (hash) => stir.participants.find(p => p.hash === hash);

    //committedCount is a function because it can change on the frontend
    stir.getCommittedCount = () => stir.stepParticipants.filter(x => (x.stepHash === stir.currentStep.hash) && x.isCommitted).length;

    let outcomes = _.flatten(_.map(data.steps, 'outcomes'));

    stir.matches = Stir.getMatches(data, outcomes, participants); // 'match' is now a front-end-only concept

    stir.theme.teams.forEach(t => t.addMatchStats(stir.matches));

    //don't show stir points for secret stirs because it may reveal preferences
    stir.totalStirPoints = stir.isSecretReveal ? 0 : outcomes.map(a => a.teamScore).reduce((a, b) => { return a + b }, 0);
    stir.averagePreference = stir.isSecretReveal ? null : data.averagePreference;

    return stir;
  }

  static getMatches = (data, outcomes, participants) => {
    const teamMatches = {};
    const teamlessMatches = [];
    const getHash = (match) => `${match.teamHash}|${match.participantHash}`;

    const finalSelfAssignSequence = Math.max(...data.steps.filter(_ => _.methodId == Constants.methodType.SelfAssign).map(_ => _.resolutionSequence));

    outcomes.forEach(outcome => {
      let match = {...outcome};
      if (!match.teamHash) {
        teamlessMatches.push(match);
        return;
      }

      match.step = data.steps.find(s => s.hash === match.stepHash);

      // Self-Assign overrides all previous matches
      if (match.step.isAssign() && match.step.methodId !== Constants.methodType.SelfAssign && match.step.resolutionSequence < finalSelfAssignSequence) return;

      const existingMatch = teamMatches[getHash(match)];
      let currentMatch = match;

      if (existingMatch && match.step) {
        // Match is already in our collection, so ensure the step is the one we want and set parameters.
        const isLatestResolvedStep = data.dateResolved && new Date(match.step.dateResolved) > new Date(existingMatch.step.dateResolved);
        const isEarliestUnresolvedStep = !data.dateResolved && !match.step.dateResolved && match.step.resolutionSequence < existingMatch.step.resolutionSequence;

        if (!isLatestResolvedStep && !isEarliestUnresolvedStep) {
          currentMatch = existingMatch;
        }

        currentMatch.electedCount = (currentMatch.electedCount || existingMatch.electedCount) + (match.step.isElect() ? 1 : 0);
        currentMatch.excludedCount = (currentMatch.excludedCount || existingMatch.excludedCount) + (match.step.isExclude() ? 1 : 0);
        currentMatch.assignedCount = (currentMatch.assignedCount || existingMatch.assignedCount) + (match.step.isAssign() ? 1 : 0);
      } else if (match.step) {
        // Match does not exist, so set some parameters and add it to our collection.
        currentMatch.electedCount = match.step.isElect() ? 1 : 0;
        currentMatch.excludedCount = match.step.isExclude() ? 1 : 0;
        currentMatch.assignedCount = match.step.isAssign() ? 1 : 0;
      } else {
        currentMatch.assignedCount = 1;
      }
      teamMatches[getHash(match)] = currentMatch;
    });

    const matches = teamlessMatches
      .concat(Object.keys(teamMatches).map((hash) => teamMatches[hash]))
      .map((m) => {
        m.team = data.theme.teams.find(t => t.hash === m.teamHash);
        m.participant = participants.find(p => p.hash === m.participantHash);
        m.winnerRank = data.gameResults.find(r => r.participantHash === m.participantHash && r.teamHash === m.teamHash)?.winnerRank;
        return new Match(m);
      });

    return matches;
  }
}

import React, { Component } from 'react';
import { HelpOverlay } from '../shared/HelpOverlay';

export class TileElement extends Component {

  getContent() {
    const inlineLabel = (this.props.inlinelabel) ? <label className="inline">{`${this.props.label}: `}</label> : null;
    if (typeof this.props.content === "string") {
      return (
        <div className={this.props.css}>
          {inlineLabel}
          <span className={this.props.inlinelabel ? "inline" : ""} dangerouslySetInnerHTML={{ __html: this.props.content }} />
        </div>
      );
    }
    return <div className={this.props.css}>{inlineLabel}{this.props.content}</div>;
  }

  render() {
    if (!this.props.content) return null;

    const earliestCharacterOverflow = 250; // shorter than this and there's no real chance that text will truncate.
    const content = (typeof this.props.content === "string" && this.props.content.length > earliestCharacterOverflow)
      ? <HelpOverlay content={this.getContent()} text={this.props.content} />
      : this.getContent();

    if (this.props.label && !this.props.inlinelabel) {
      return (
        <div>
          <label>{this.props.label}</label>
          {content}
        </div>
      );
    }

    return content;
  }
}

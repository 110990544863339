import React from 'react';
import { Link } from 'react-router-dom';
import { BlurbTile } from '../shared/BlurbTile';
import { HelpOverlay } from '../shared/HelpOverlay';
import { UrlService } from '../../services/UrlService';
import Validator from '../../services/ValidationService';
import Constants from '../../services/Constants';
import '../../css/home.css';

export function Home({ user }) {
  const alternateEnvironment = UrlService.getAlternateEnvironment();
  UrlService.useTitle('Home');

  return (
    <div className="home-body">
      <div className="home-body-inner">
        <div className="large-blurb-text">
          {user?.name ?.length ? <>welcome back, {user.name}</> : <>teamstir is a free tool
            <br />
            for distributing stuff</>}
        </div>
        <div className="medium-blurb-container">
          <div className="medium-blurb-container-inner-left">
            <div>what can it do?</div>
            <ul>
              <li>choose sides in a board game</li>
              <li>divide students into groups</li>
              <li>split up chore duties</li>
              <li>allocate anything you like</li>
            </ul>
          </div>
          <div className="medium-blurb-container-inner-right">
            <HelpOverlay content={<Link to={UrlService.getUrl("/stir")}>GET STARTED</Link>} text="make a stir" />
            <HelpOverlay content={<Link to={UrlService.getUrl("/releaseNotes")}>WHAT'S NEW</Link>} text="learn about the latest features and changes to teamstir" />
            <HelpOverlay content={<a href={alternateEnvironment.Url}>{alternateEnvironment.DisplayText}</a>} text="switch between our most stable version of teamstir and the beta version, which has the latest features" />
          </div>
        </div>
        <div className="method-blurb-container">
          <span className="stretch"></span>
          {Validator.isHashValid(UrlService.getUserHashFromUrl(), Constants.hash.user) && <BlurbTile icon="clone" link={UrlService.getUrl(`/${UrlService.getUserHashFromUrl()}`)} head="clone" blurb="choose one of your previous stirs to clone" helptext="view your profile to choose a previous stir, which you can clone into a new stir with the values prepopulated." />}
          <span className="stretch"></span>
          <BlurbTile icon="rank" link={UrlService.getUrl("/stir/1", "method=rank")} head="rank" blurb="choose teams by|preference order" helptext="start creating a stir with the rank method pre-selected" />
          <span className="stretch"></span>
          <BlurbTile icon="random" link={UrlService.getUrl("/stir/1", "method=random")} head="random" blurb="randomize teams|among participants" helptext="start creating a stir with the random method pre-selected" />
          <span className="stretch"></span>
          {!UrlService.getUserHashFromUrl()?.length &&
            <BlurbTile icon="group" link={UrlService.getUrl("/stir/1", "method=group")} head="group" blurb="choose teams|by category" helptext="start creating a stir with the group method pre-selected" /> &&
            <span className="stretch"></span>}
        </div>
      </div>
    </div>
  );
}
import React, { Component } from 'react';
import { FormControl, FormLabel } from 'react-bootstrap';
import { Icon } from '../shared/Icon';
import DatePicker from 'react-datepicker';
import DateService from '../../services/DateService'

export class EditEvent extends Component {

  getEventNameControl() {
    return (
      <div className="padding-topbottom-10">
        <FormLabel>Name</FormLabel>
        <FormControl autoFocus
          disabled={this.props.islocked}
          onChange={this.props.handleEventNameChange}
          placeholder="give your event a name"
          value={this.props.event.name}
        />
      </div>
    );
  }

  getEventDateControl() {
    return (
      <div className="padding-topbottom-10">
        <FormLabel>
          {"Date"}
          <Icon icon="question" helptext="Date and time of the event, so everyone knows when to be there." />
        </FormLabel>
        <DatePicker
          disabled={this.props.islocked}
          className="form-control"
          wrapperClassName="form-control"
          selected={this.props.event.eventDate}
          onChange={this.props.handleEventDateChange}
          placeholderText="starting date and time"
          showTimeSelect
          timeIntervals={30}
          timeCaption="time"
          dateFormat={DateService.controlDateFormat}
        />
      </div>
    );
  }

  getEventAddressControl() {
    return (
      <div className="padding-topbottom-10">
        <FormLabel>
          {"Address"}
          <Icon icon="question" helptext="Location of the event, so everyone knows where to go." />
        </FormLabel>
        <FormControl
          disabled={this.props.islocked}
          rows="2"
          placeholder="where it's happening (optional)"
          as="textarea"
          onChange={this.props.handleEventAddressChange}
          value={this.props.event.address}
        />
      </div>
    );
  }

  getEventMessageControl() {
    return (
      <div className="padding-topbottom-10">
        <FormLabel>
          {"Message"}
          <Icon icon="question" helptext="An optional note for attendees about the event." />
        </FormLabel>
        <FormControl
          disabled={this.props.islocked}
          rows="3"
          placeholder="a note to your attendees (optional)"
          as="textarea"
          onChange={this.props.handleEventMessageChange}
          value={this.props.event.message}
        />
      </div>
    );
  }

  render() {
    return (
      <>
        {this.getEventNameControl()}
        {this.getEventDateControl()}
        {this.getEventAddressControl()}
        {this.getEventMessageControl()}
      </>
    );
  }
}

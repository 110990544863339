import React, { Component } from 'react';
import TextService from '../../services/TextService';
import { Icon } from '../shared/Icon';
import { TeamInteractableTile } from '../tiles/TeamInteractableTile';
import Constants from '../../services/Constants';

export class GroupDetails extends Component {
  constructor(props) {
    super(props);
    this.handleTeamAddRemove = this.handleTeamAddRemove.bind(this);
    const group1 = props.teamPicks.filter(x => x.pickRank === 1 && this.props.availableTeamHashes.includes(x.teamHash)).map(x => x.teamHash);
    const group0 = this.props.availableTeamHashes.filter(hash => !group1.includes(hash));
    this.state = {
      teams: props.stir.theme.teams,
      teamPicks: props.teamPicks,
      groups: [ group0, group1 ]
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.teamPicks.length && !newProps.teamPicks.length) {
      const groups = [ this.props.availableTeamHashes, [] ];
      this.setState({ groups: groups }, this.ensureSave);
    }
  }

  handleTeamAddRemove(hash, isAdd) {
    if ((this.state.groups[1].length >= this.props.maxPickCount && isAdd) || this.props.isCommitted) return;

    let group0 = this.state.groups[0].filter(x => x !== hash);
    let group1 = this.state.groups[1].filter(x => x !== hash);
    if (isAdd) {
      group1.push(hash);
    } else {
      group0.push(hash);
    }
    const groups = [group0, group1];
    this.setState({ groups: groups }, this.ensureSave);
  }

  ensureSave() {
    const approvedTeams = this.state.groups[1];

    const availableApprovedTeams = approvedTeams.filter(teamHash => this.props.availableTeamHashes.includes(teamHash));

    const teamsAsPicks = availableApprovedTeams.map(teamHash => ({ teamHash: teamHash, pickRank: 1 }));

    var pickList = [...new Set(teamsAsPicks)];
     
    this.props.handlePreferencesChange(pickList);
  }

  render() {
    if (!this.props.stir) {
      return null;
    }

    if (!this.state.groups[0].length && !this.state.groups[1].length) {
      return (
        <div className="no-content-outer">
          <div className="no-content-inner">
            <p>{`no available teams`}</p>
            <p className="no-content-description">there are no teams available to be assigned at this time</p>
          </div>
        </div>
      );
    }

    const disableSelect = this.state.groups[1].length >= this.props.maxPickCount;

    const group0 = (this.state.groups[0].length)
      ? (
        <>
          <div className="tile-dual-inner-header">unapproved teams</div>
          {this.state.groups[0].map(hash =>
            <TeamInteractableTile
              key={hash}
              data-id={hash}
              team={this.state.teams.find(t => t.hash === hash)}
              pickRank={null}
              isCommitted={this.props.isCommitted}
              methodId={this.props.stir.currentStep.methodId}
              handleTeamAddRemove={this.handleTeamAddRemove}
              disableSelect={disableSelect}
            />
          ).sort((a, b) => {
            var aName = a.props.team.name.toUpperCase();
            var bName= b.props.team.name.toUpperCase();
            return (aName < bName) ? -1 : (aName > bName) ? 1 : 0;
          })}
        </>
      ) : (
        <div className="tile-dual-inner-header"></div>
      );

    const helptextIntro = this.props.isCommitted ? "Uncommit your preferences, then click" : "Click";
    const helptext = `${helptextIntro} the + or - buttons to approve or unapprove teams that you want ${TextService.getResolutionVerb(this.props.stir.currentStep.resolutionTypeId, true)}. ${this.props.resolutionText}`;
    const group1 = (this.state.groups[1].length)
      ? (
        <>
          <div className="tile-dual-inner-header right">
            {`approved teams (` + (disableSelect ? "max teams approved)" : `up to ${this.props.maxPickCount - this.state.groups[1].length} more)`)}
            <Icon icon="question" helptext={helptext} />
          </div>
          {this.state.groups[1].map(hash =>
            <TeamInteractableTile
              key={hash}
              data-id={hash}
              team={this.state.teams.find(t => t.hash === hash)}
              pickRank={1}
              isCommitted={this.props.isCommitted}
              methodId={this.props.stir.currentStep.methodId}
              handleTeamAddRemove={this.handleTeamAddRemove}
            />
          ).sort((a, b) => {
            var aName = a.props.team.name.toUpperCase();
            var bName = b.props.team.name.toUpperCase();
            return (aName < bName) ? -1 : (aName > bName) ? 1 : 0;
          })}
        </>
      )
      : this.props.getNoContentControl("approved", helptext);

    return (
      <div className="tile-dual-outer">
        <div className="tile-dual-inner sortable-container right">
          {group1}
        </div>
        <div className="tile-dual-inner">
          {group0}
        </div>
        <span className="stretch"></span>
      </div>);
  }
}

export default class WebsocketService {
  static openWebsocket = (callback) => {
    const scheme = document.location.protocol === "https:" ? "wss" : "ws";
    const port = document.location.port ? (":" + document.location.port) : "";
    const connectionUrl = scheme + "://" + document.location.hostname + port + "/websocket";

    var socket = new WebSocket(connectionUrl);

    socket.onerror = (event) => {
      console.log('websocket error:');
      console.log(event);
    };

    socket.onmessage = (event) => {
      callback(event);
    };

    console.debug('websocket connected');

    return socket;
  }
}
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UrlService } from '../../services/UrlService';

export class DropdownAction extends Component {
  render() {
    if (!this.props.name || !this.props.name.length) {
      return null;
    }
    if (this.props.uri && this.props.uri.length) {
      return <Link className="dropdown-item" to={"/" + this.props.uri}>{this.props.name}</Link>;
    }
    if (this.props.hash && this.props.hash.length) {
      return <Link className="dropdown-item" to={UrlService.getUrl("/" + this.props.hash)}>{this.props.name}</Link>;
    }
    if (this.props.url && this.props.url.length) {
      return <a className="dropdown-item" href={this.props.url} target="_blank" rel="noopener noreferrer">{this.props.name}</a>;
    }
    if (this.props.func) {
      return <button className="dropdown-item" onClick={this.props.func}>{this.props.name}</button>;
    }
    return null;
  }
}

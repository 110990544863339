import Team from './Team';

export default class Theme {
  constructor(theme) {
    if (theme.isInitialized) throw new Error("theme already initialized"); else theme.isInitialized = true;

    theme.teams = (theme.teams || []).map(t => new Team(t));

    return theme;
  }
}

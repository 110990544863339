import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UrlService } from '../../services/UrlService';
import '../../css/StirMenuBottom.css';

export class StirMenuBottom extends Component {

  getLeftButton() {
    switch (this.props.stir.stepNumber) {
      case 1:
        return (<li><Nav.Link as={Link} to={UrlService.getUrl("/")}>cancel</Nav.Link></li>);
      case 2:
        return (<li><button onClick={() => this.props.handleStepChange(1)} onMouseDown={(e) => e.preventDefault()}>back</button></li>);
      case 3:
        return (<li><button onClick={() => this.props.handleStepChange(2)} onMouseDown={(e) => e.preventDefault()}>back</button></li>);
      case 4:
        return (<li><button onClick={() => this.props.handleStepChange(3)} onMouseDown={(e) => e.preventDefault()}>back</button></li>);
      default:
        return null;
    }
  }

  getRightButton() {
    switch (this.props.stir.stepNumber) {
      case 1:
        return <li><button onClick={() => this.props.handleStepChange(2)} onMouseDown={(e) => e.preventDefault()}>next</button></li>;
      case 2:
        return <li><button onClick={() => this.props.handleStepChange(3)} onMouseDown={(e) => e.preventDefault()}>next</button></li>;
      case 3:
        return <li><button onClick={() => this.props.handleStepChange(4)} onMouseDown={(e) => e.preventDefault()}>next</button></li>;
      case 4:
        return <li><button onClick={() => this.props.handleStepChange(5)} onMouseDown={(e) => e.preventDefault()}>stir</button></li>;
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="stir-body">
        <div className="stir-bottom-menu">
        {this.getLeftButton()}
        {this.getRightButton()}
        </div>
      </div>
    );
  }
}

import React, { Component } from 'react';
import { Icon } from '../shared/Icon';
import { Tile } from './Tile';
import { DropdownAction } from '../shared/DropdownAction';
import { TileElement } from './TileElement';
import { UserDetailsAction } from '../details/UserDetails';
import DateService from '../../services/DateService';
import TextService from '../../services/TextService';
import _uniqueId from 'lodash/uniqueId';

export class UserTile extends Component {

  getIcon() {
    return <Icon icon={"participant"} helptext={this.props.user.name} />;
  }

  getFooter() {
    return this.props.user.dateCreated ? ("first stirred " + DateService.getDurationText(this.props.user.dateCreated)) : null;
  }

  getHeaderElements() {
    const user = this.props.user;

    const victories = [
      { rank: 1, count: user.goldCount },
      { rank: 2, count: user.silverCount },
      { rank: 3, count: user.bronzeCount },
    ]
      .filter(x => x.count)
      .map(x => <span key={`${user.hash}_victory_${x.rank}`}>{x.count} <Icon icon="medal" rank={x.rank} /></span>);

    const elementGroup1 = (
      <>
        <TileElement label="next deadline" content={DateService.getDateText(user.nextDeadline) || "no current deadline"} inlinelabel={true} />
        <TileElement label="unresolved stirs" content={user.activeStirCount} inlinelabel={true} />
        <TileElement label="victories" content={victories.length ? victories : "none yet"} inlinelabel={true} />
      </>
    );

    const elementGroup2 = (
      <>
        <TileElement label="email" content={user.email} inlinelabel={true} />
        <TileElement label="color" content={user.getColorTheme().name} inlinelabel={true} />
        {user.bggUserName && <TileElement label="BGG" content={user.bggUserName} inlinelabel={true} />}
      </>
    );

    return [
      <TileElement key={1} css="tilehead-oneliner" content={elementGroup1} />,
      <TileElement key={2} css="tilehead-oneliner" content={elementGroup2} />,
    ];
  }

  getFooterElements() {
    const stirCount = this.props.user.totalStirCount;
    const karma = this.props.user.getKarma();
    return (
      <>
        {stirCount !== undefined &&
          <div>{stirCount} <Icon icon="stir" helptext={"you have participated in " + TextService.pluralize(stirCount, "stir")} /></div>
        }
        {karma !== undefined &&
          <div>{karma} <Icon icon="dharmachakra" helptext={"karma is the extent to which you received preferred teams in stirs (lower or negative means you received preferred teams more than others, higher or positive means you received preferred teams less than others)"} /></div>
        }
      </>
    );
  }

  getDropdownActions() {
    const hasBgg = this.props.user?.bggUserName?.length > 0;
    const bggAction = hasBgg ? UserDetailsAction.openUnlinkBggUserNameModal : UserDetailsAction.openLinkBggUserNameModal;
    return [
      <DropdownAction key={_uniqueId()} name="edit name" func={() => this.props.handleModalAction(UserDetailsAction.openNameModal)} />,
      <DropdownAction key={_uniqueId()} name="change colors" func={() => this.props.handleModalAction(UserDetailsAction.openColorsModal)} />,
      <DropdownAction key={_uniqueId()} name={(hasBgg ? 'un' : '') + 'link BGG account'} func={() => this.props.handleModalAction(bggAction)} />,
    ];
  }

  render() {
    return <Tile
      isHeader={this.props.isHeader}
      icon={this.getIcon()}
      header={this.props.user.name}
      footer={this.getFooter()}
      headerElements={this.getHeaderElements()}
      footerElements={this.getFooterElements()}
      dropdownActions={this.getDropdownActions()}
      hash={this.props.user.hash}
      {...this.props}
    />;
  }
}

import React, { useState, useEffect, useRef } from 'react';
import { Alert, Nav } from 'react-bootstrap';
import { ConfirmationModal } from '../shared/ConfirmationModal';
import { StirTile } from '../tiles/StirTile';
import { ParticipantAssignTile } from '../tiles/ParticipantAssignTile';
import { Spinner } from '../shared/Spinner';
import { NoContent } from '../shared/NoContent';
import { Breadcrumbs } from '../shared/Breadcrumbs';
import { Icon } from '../shared/Icon';
import { UrlService } from '../../services/UrlService';
import EntityService from '../../services/EntityService';
import Constants from '../../services/Constants';
import Stir from '../../entities/Stir';

export function AssignDetails(props) {
  const pageStates = {
    None: 0,
    Assigning: 1,
    Saving: 2,
    SaveError: 3,
    SaveComplete: 4
  };
  const [stir, setStir] = useState(null);
  const isLoadingStir = useRef();
  const [notFound, setNotFound] = useState(false);
  const [pageState, setPageState] = useState(pageStates.None);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [florp, setFlorp] = useState(false);

  UrlService.useTitle('Admin Assign');

  const loadStir = (data) => {
    if (!data) {
      isLoadingStir.current = false;
      setNotFound(true);
    } else {
      let loadedStir = new Stir(data);
      for (let participant of loadedStir.participants) {
        participant.selectedTeamHashes = [];
      }
      if (loadedStir.currentStep.isAdminAssign()) {
        setPageState(pageStates.Assigning);
        setStir(loadedStir);
      } else {
        setNotFound(true); // 404 if not an assign step
      }
    }
  }

  useEffect(() => {
    if (props.hash === UrlService.getHashFromUrl() && !isLoadingStir.current && !notFound) {
      EntityService.getAsyncData(loadStir, `api/${props.hash}?user=${UrlService.getUserHashFromUrl()}`, isLoadingStir);
    }
  }, []);

  const handleSelectionChange = (e) => {
    let updatedStir = stir;
    updatedStir.participants.find(p => p.hash === e.participantHash).selectedTeamHashes = e.selectedTeamHashes;
    setStir(updatedStir);
    setFlorp(!florp);
  }

  const getMessage = () => {
    if (pageState === pageStates.SaveError) {
      return <Alert transition={null} variant="danger">an error occurred while saving assignments</Alert>;
    }
  }

  const getContent = () => {
    if (!stir) return null;
    if (!stir.participants) return <NoContent noun="participants" />;

    const allSelectedTeamHashes = stir.participants.map(p => p.selectedTeamHashes).flat();

    const participants = stir.participants
      .sort((a, b) => { return (a.isObserver - b.isObserver); })
      .map(p => {
      const availableTeams = stir.theme.teams.filter(team => stir.availableTeamHashes.includes(team.hash) && (!allSelectedTeamHashes.includes(team.hash) || p.selectedTeamHashes.includes(team.hash)));
      return <ParticipantAssignTile
        key={p.hash}
        id={p.hash}
        participant={p}
        availableTeams={availableTeams}
        isObserver={p.isObserver}
        handleSelectionChange={handleSelectionChange}
        selectedTeamHashes={p.selectedTeamHashes}
      />
    });

    // Sort admin first, then participants alphabetically, then observers alphabetically.
    return participants
      .sort((a, b) => (a.isObserver === b.isObserver) ? (a > b) : (a.isObserver ? 1 : -1))
      .sort((a, b) => b.isAdmin - a.isAdmin);
  }

  const getFooter = () => {
    if (!stir || stir.dateResolved) return null;
    return (
      <div className="grid-footer">
        <Nav.Link to="#" onClick={() => { setIsConfirmationModalOpen(true); }}>
          <Icon icon="save" helptext="commit these assignments" />
        </Nav.Link>
      </div>
    );
  }

  const saveAssignments = () => {
    setPageState(pageStates.Saving);
    setIsConfirmationModalOpen(false);
    const dto = {
      stirHash: stir.hash,
      assignments: stir.participants.map(p => {
        return {
          stirHash: stir.hash,
          stepParticipant: {
            participantHash: p.hash,
            stepHash: stir.currentStepHash,
          },
          picks: p.selectedTeamHashes.map(hash => {
            return {
              teamHash: hash,
              pickRank: 0,
            }
          })
        };
      })
    };
    EntityService.setAsyncData((_, status) => {
      if (status === Constants.httpStatus.Ok) {
        // navigate to stir page to see results
        setPageState(pageStates.SaveComplete);
        const from = UrlService.getUrlParam("from");
        props.navigate(UrlService.getUrl(`/${stir.hash}`, from ? `from=${from}` : ""));
      } else {
        setPageState(pageStates.SaveError);
      }
    }, `/api/stir/assign?user=${props.user.hash}`, dto);
  }

  const getConfirmationModal = () =>
    <ConfirmationModal
      title="commit assignments"
      yesText="commit"
      isOpen={isConfirmationModalOpen}
      onNo={() => setIsConfirmationModalOpen(false)}
      onYes={() => saveAssignments()}
      content={
        <div className="modal-element">
          {"Are these team assignments complete? Once committed, assignments cannot be changed, and you will be navigated back to the stir results page."}
        </div>
      }
    />;

  if (notFound) return <NoContent noun={"stir"} />;

  if (isLoadingStir.current || !stir || [pageStates.Saving, pageStates.None, pageStates.SaveComplete].includes(pageState)) return <Spinner />;

  return (
    <div className="center">
      <div className="tile-container">
        <Breadcrumbs user={props.user} stir={stir} theme={stir.theme} event={stir.event} />
        <StirTile
          key={stir.hash}
          user={props.user}
          stir={stir}
          isHeader={true}
          page={Constants.page.Assign}
        />
        {getMessage()}
        {getContent()}
        {getFooter()}
        {getConfirmationModal()}
      </div>
    </div>);
}

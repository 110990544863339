import React, { Component } from 'react';
import { Icon } from '../shared/Icon';
import { Tile } from './Tile';
import { DropdownAction } from '../shared/DropdownAction';
import TextService from '../../services/TextService';
import _uniqueId from 'lodash/uniqueId';

export class TeamTile extends Component {

  constructor(props) {
    super(props);
    if (!props.team.isInitialized) throw new Error("team not initialized");
  }

  getIcon() {
    const team = this.props.team;
    if (team.electedCount === 1) {
      return team.excludedCount
        ? <Icon icon="elect-team-1x-exclude-team" helptext={team.name + " was elected in this stir before being excluded"} />
        : <Icon icon="elect-team-1x" helptext={team.name + " was elected in this stir"} />;
    } else if (team.electedCount === 2) {
      return team.excludedCount
        ? <Icon icon="elect-team-2x-exclude-team" helptext={team.name + " was elected twice in this stir before being excluded"} />
        : <Icon icon="elect-team-2x" helptext={team.name + " was elected twice in this stir"} />;
    } else if (team.electedCount === 3) {
      return <Icon icon="elect-team-3x" helptext={team.name + " was elected three times in this stir"} />;
    } else if (team.excludedCount === 1) {
      return <Icon icon="exclude-team" helptext={team.name + " was excluded from this stir"} />;
    } else if (this.props.isSecretAssignment) {
      return <Icon icon="team-secret" helptext={team.name + " was secretly assigned"} />;
    } else {
      return <Icon icon="team" helptext={team.name} />;
    }
  }

  getFooter() {
    return null;
  }

  getHeaderElements() {
    return null;
  }

  getFooterElements() {
    const team = this.props.team;
    const winCountIcon = team.winCount
      ? (
        <div>
          {team.winCount} <Icon icon="medal" rank={1} helptext={`${team.name} has ${TextService.pluralize(team.winCount, "recorded win")} in teamstir`} />
        </div>
      )
      : null;
    const averagePreferenceIcon = team.averagePreference
      ? (
        <div>
          {team.averagePreference.toFixed(1)} <Icon icon="balance" helptext={`the average preference rank for ${team.name} across all completed stirs is ${team.averagePreference.toFixed(1)}`} />
        </div>
      ) : (
        <div>
          <Icon icon="no-balance" helptext={"no preference statistics exist for " + team.name + " yet"} />
        </div>
      );
    return (
      <>
        {winCountIcon}
        {averagePreferenceIcon}
      </>
    );
  }

  getDropdownActions() {
    return this.props.team.links.map(link =>
      <DropdownAction key={_uniqueId()} name={link.name} url={link.url} />
    );
  }

  render() {
    return <Tile
      isHeader={this.props.isHeader}
      icon={this.getIcon()}
      header={this.props.team.name}
      footer={this.getFooter()}
      headerElements={this.getHeaderElements()}
      footerElements={this.getFooterElements()}
      dropdownActions={this.getDropdownActions()}
      hash={this.props.team.hash}
      {...this.props}
    />;
  }
}

import React, { useRef } from 'react';
import { getErrors } from './CreateStir';
import { getIo, MethodStep, action, createMethodStep } from './MethodStep';
import { Icon } from '../shared/Icon';
import Constants from '../../services/Constants';
import EntityService from '../../services/EntityService';
import { UrlService } from '../../services/UrlService';

export const Step3 = (props) => {
  const maxSteps = 10; //TODO: Possibly raise to 20 or 30 for fantasy sports people

  const stir = props.stir;
  const update = props.handleStirChange;
  const isLoadingAlgorithms = useRef(false);
  const isLoadingMethods = useRef(false);

  UrlService.useTitle('Step 3');

  const hydrateCollections = () => {
    if (!stir.algorithms.length) {
      EntityService.getAsyncData((data) => {
        stir.algorithms = data;

        update(stir);
      }, 'api/algorithms', isLoadingAlgorithms);
    }

    if (!stir.methods.length) {
      EntityService.getAsyncData((data) => {
        stir.methods = data;

        update(stir);
        }, 'api/methods', isLoadingMethods);
    }
  }

  hydrateCollections();

  const handleUpdate = (methodStep) => {
    const index = stir.methodSteps.findIndex((x) => parseInt(x.sequence) === parseInt(methodStep.sequence));
    stir.methodSteps.splice(index, 1, methodStep);

    update(stir);
  }

  const handleMethodStepAction = (sequence, theAction) => {
    if (theAction === action.expand) {
      stir.expandedMethodStepSequence = sequence;
    } else if (theAction === action.collapse) {
      stir.expandedMethodStepSequence = null;
    } else if (theAction === action.delete) {
      stir.methodSteps = stir.methodSteps.filter(p => { return parseInt(p.sequence) !== sequence; });
      stir.expandedMethodStepSequence = (sequence === stir.expandedMethodStepSequence) ? Math.max(1, (sequence - 1)) : stir.expandedMethodStepSequence;
    }

    update(stir);
  }

  const addGridRow = () => {
    const methodSteps = stir.methodSteps;
    const sequence = Math.max(...methodSteps.map(p => p.sequence)) + 1;
    methodSteps.push(createMethodStep(sequence));
    stir.methodSteps = methodSteps;
    stir.expandedMethodStepSequence = sequence;

    update(stir);
  }

  const getGridFooter = () => {
    if (stir.methodSteps.length < maxSteps) {
      return (
        <div className="grid-footer">
          <button onClick={() => addGridRow()} onMouseDown={(e) => e.preventDefault()} >
            <Icon icon="plus" css="add-button" helptext="add another method" />
          </button>
        </div>);
    }
    return null;
  }

  const getContent = () => {
    if (!stir.algorithms.length || !stir.methods.length) return;

    let methodStepControls = [];
    const isMirror = stir.selectedThemeTypeId === Constants.themeType.MirrorParticipants;
    const availableMethods = stir.participantNamesOnly
      ? stir.methods.filter(x => !x.isEmailRequired)
      : stir.methods;

    for (let i = 0; i < stir.methodSteps.length; i++) {
      let step = stir.methodSteps[i];

      step.input = (i === 0)
        ? getIo(isMirror ? 0 : stir.getTeamCount(),
             stir.getParticipantCount(),
             0,
             step)
        : getIo(stir.methodSteps[i - 1].output.teamCount,
             stir.methodSteps[i - 1].output.participantCount,
             stir.methodSteps[i - 1].output.matchCount,
             stir.methodSteps[i - 1]);

      methodStepControls.push(<MethodStep
        key={step.sequence}
        isMirror={isMirror}
        isExpanded={stir.expandedMethodStepSequence === step.sequence}
        step={step}
        totalParticipantCount={stir.getParticipantCount()}
        methods={availableMethods}
        algorithms={stir.algorithms}
        isOnlyStep={stir.methodSteps.length === 1}
        handleMethodStepAction={handleMethodStepAction}
        handleUpdate={handleUpdate} />
      );
    }

    return (
      <div className="stir-body-inner">
        <div className="stir-step-top-content">
          <p className="description-text">
            {(stir.selectedThemeTypeId === Constants.themeType.MirrorParticipants)
              ? <>Choose how your participants will be allocated. <b>Assign</b> matches participants to each other (but not to themselves). <b>Elect</b> and <b>Exclude</b> narrow the field of participants. You can specify up to three methods, which will resolve in sequential order.</>
              : <>Choose how your teams and participants will be allocated. <b>Assign</b> matches teams to participants. <b>Elect</b> or <b>Exclude</b> narrows the list of teams. You can select up to three methods, which will resolve in sequential order.</>}
          </p>
        </div>
        {methodStepControls}
        {getGridFooter()}
      </div>);
  }

  return (
    <div className="stir-body">
      {getErrors(stir, 3)}
      {getContent()}
    </div>
  );
}
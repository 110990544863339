import Constants from '../services/Constants';
import StirView from './StirView';
import Validator from '../services/ValidationService';

export default class User {
  constructor(user) {

    user = user || { };

    user.hash = Validator.isHashValid(user?.hash, Constants.hash.user) ? user.hash : undefined;

    user.publicHash = Validator.isHashValid(user?.publicHash, Constants.hash.participant) ? user.publicHash : undefined;

    user.hasEmail = user.email && user.email.length > 0;

    user.getColorTheme = () => Constants.colorThemes.find(x => x.id === (user.colorThemeId || 1));

    user.stirViews = (user.stirViews || []).map(stirView => new StirView(stirView)); //This just adds some methods

    user.getKarma = () => {
      const validKarma = user.stirViews.filter(s => !isNaN(parseFloat(s.karma))).map(s => s.karma);
      return (validKarma.length)
        ? parseInt((validKarma.reduce((a, b) => a + b) / validKarma.length) * 100)
        : undefined;
    }

    user.totalStirCount = user.stirViews.length;

    user.activeStirCount = 0;

    user.nextDeadline = null;

    user.stirViews
      .map(stir => stir.currentStep)
      .filter(step => step?.dateShouldResolve && !step?.dateResolved)
      .forEach(step => {
        user.activeStirCount++;
        const dateShouldResolve = (step && step.dateShouldResolve) ? new Date(step.dateShouldResolve) : null;
        const isFuture = dateShouldResolve && (dateShouldResolve > new Date());
        const isNext = !user.nextDeadline || (dateShouldResolve < user.nextDeadline);
        if (isFuture && isNext) {
          user.nextDeadline = dateShouldResolve;
        }
      });

    user.goldCount = user.stirViews.filter(s => s.winnerRank === 1).length;
    user.silverCount = user.stirViews.filter(s => s.winnerRank === 2).length;
    user.bronzeCount = user.stirViews.filter(s => s.winnerRank === 3).length;

    return user;
  }
}

import React, { useState, useEffect, useRef } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { StirTile } from '../tiles/StirTile';
import { EventTile } from '../tiles/EventTile';
import { Spinner } from '../shared/Spinner';
import { NoContent } from '../shared/NoContent';
import { Breadcrumbs } from '../shared/Breadcrumbs';
import { ConfirmationModal } from '../shared/ConfirmationModal';
import { EditEvent } from '../shared/EditEvent';
import { Icon } from '../shared/Icon';
import { EmailButton } from '../shared/EmailButton';
import { UrlService } from '../../services/UrlService';
import EntityService from '../../services/EntityService';
import Constants from '../../services/Constants';
import Event from '../../entities/Event';

export const action = {
  closeModal: 1,
  editEvent: 2,
  saveEvent: 3,
};

export function EventDetails(props) {
  const user = props.user;

  const [event, setEvent] = useState({});
  const [editedEvent, setEditedEvent] = useState({});
  const [isEditEventModalOpen, setIsEditEventModalOpen] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [isSendEmailButtonDisabled, setIsSendEmailButtonDisabled] = useState(false);
  const [isError, setIsError] = useState(false);
  const isLoading = useRef();
  const isSaving = useRef();

  UrlService.useTitle('Event Details');

  useEffect(() => {
    EntityService.getEntityAsync((data) => {
      if (data) {
        loadEvent(data);
      } else {
        setNotFound(true);
      }
    }, props.hash, isLoading);
  }, []);

  const canEdit = () => {
    return user && event && user?.publicHash === event?.createdByParticipantHash;
  };

  const loadEvent = (data) => {
    if (!data || (Object.entries(data).length === 0 && data.constructor === Object)) return;

    const loadedEvent = new Event(data);

    setEvent(loadedEvent);
    setEditedEvent({ ...loadedEvent });
    setIsEditEventModalOpen(false);
  };

  const saveEvent = () => {
    EntityService.setAsyncData((data, status) => {
      if (status === Constants.httpStatus.Ok) {
        loadEvent(data);
      } else {
        setIsError(true);
        setIsEditEventModalOpen(false);
      }
    }, '/api/events/' + props.hash, editedEvent, 'PATCH', isSaving);
  };

  const handleModalAction = (a) => {
    if (a === action.closeModal) {
      setIsEditEventModalOpen(false);
      setEditedEvent({ ...event });
    } else if (a === action.editEvent) {
      setIsEditEventModalOpen(true);
    } else if (a === action.saveEvent) {
      saveEvent();
    }
  };

  const getEditEventModalContent = () => {
    if (isSaving.current) return <Spinner />;

    if (isEditEventModalOpen) {
      return <EditEvent
        event={editedEvent}
        handleEventNameChange={(e) => { editedEvent.name = e.target.value; setEditedEvent({ ...editedEvent }); }}
        handleEventDateChange={(e) => { editedEvent.eventDate = e.target.value; setEditedEvent({ ...editedEvent }); }}
        handleEventAddressChange={(e) => { editedEvent.address = e.target.value; setEditedEvent({ ...editedEvent }); }}
        handleEventMessageChange={(e) => { editedEvent.message = e.target.value; setEditedEvent({ ...editedEvent }); }}
      />;
    }
  };

  const getFooter = () => {
    return (
      <div className="grid-footer">
        {canEdit() && <EmailButton
          entityName="event"
          isSending={isSendEmailButtonDisabled}
          dateSent={event.invitationDateLastSent}
          onClick={() => {
            setIsSendEmailButtonDisabled(true);

            EntityService.getAsyncData((_data, _status) => {
              const isOk = _status === Constants.httpStatus.Ok;

              event.invitationDateLastSent = isOk ? new Date() : event.invitationDateLastSent;
              setIsSendEmailButtonDisabled(false);
              setIsError(!isOk);
              setEvent(event);
            }, `api/email/sendEventInvites/${event.hash}`);
          }} />
        }
        <Nav.Link as={Link} to={UrlService.getUrl("/stir/1", `templateEvent=${event.hash}`)}>
            <Icon icon="plus" helptext="create a new stir for this event" />
        </Nav.Link>
      </div>);
  };

  const isEditedEventValid = () => {
    return editedEvent &&
      editedEvent.name && editedEvent.name.length >= 4 && editedEvent.name.length <= 1000 &&
      editedEvent.eventDate && !isNaN(new Date(editedEvent.eventDate).getTime()) &&
      (!editedEvent.address || editedEvent.address.length <= 1000) &&
      (!editedEvent.message || editedEvent.message.length <= 1000);
  };

  const getContent = () => {
    if (!event) return null;

    if (event.stirs?.length) {
      return (
        <>
          {event.stirs.map(x => <StirTile key={x.hash} stir={x} user={user} page={Constants.page.Event} />)}
          {getFooter()}
        </>);
    }
    return <NoContent noun={"event"} />
  };

  if (isLoading.current) return <Spinner />;

  if (notFound) return <NoContent noun={"event"} />;

  return (
    <div className="center">
      <ConfirmationModal
        title="edit event"
        isOpen={isEditEventModalOpen}
        content={getEditEventModalContent()}
        yesText="Ok"
        onNo={() => handleModalAction(action.closeModal)}
        onYes={() => handleModalAction(action.saveEvent)}
        isInvalid={isSaving.current || !isEditedEventValid()}
        validationText={isSaving.current ? "" : "Give your event a name and valid details."}
      />
      <div className="tile-container">
        <Breadcrumbs user={user} event={event} />
        <EventTile
          key={event.hash}
          user={user}
          event={event}
          isHeader="true"
          canEdit={canEdit()}
          handleModalAction={handleModalAction}
        />
        {getContent()}
      </div>
    </div>);
}
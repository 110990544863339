import React, { Component } from 'react';
import { FormControl, FormLabel } from 'react-bootstrap';
import { ConfirmationModal } from '../shared/ConfirmationModal';
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import { Icon } from './Icon';
import { UrlService } from '../../services/UrlService';
import { HelpOverlay } from './HelpOverlay';
import Validator from '../../services/ValidationService';
import EntityService from '../../services/EntityService';
import Constants from '../../services/Constants';
import '../../css/TopMenu.css';

export class TopMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginHash: '',
      userEmail: undefined,
      isLoginModalOpen: false,
      isRecoveryModalOpen: false,
      isSendingEmail: false,
    };
  }

  getHalo = (icon) => <span className="nav-link"><Icon icon={icon} css="halo" /></span>;

  getTeamstirLink() {
    if (UrlService.isPage("")) {
      return <span className="navbar-brand halo">teamstir</span>;
    }
    return <Link className="navbar-brand" to={UrlService.getUrl("/")}>teamstir</Link>;
  }

  getStirLink() {
    if (UrlService.isPage("stir")) {
      return this.getHalo("stir");
    }
    const content = (
      <Nav.Link as={Link} to={UrlService.getUrl("/stir")}>
        <Icon icon="stir" />
      </Nav.Link>
    );
    return <HelpOverlay content={content} text="start using teamstir to choose teams or allocate things" />;
  }

  getProfileLink() {
    const userHash = UrlService.getUserHashFromUrl();
    let overlayText = undefined;
    let profileLink = undefined;
    if (userHash) {
      if (UrlService.isPage(userHash)) {
        return this.getHalo("participant");
      }
      overlayText = "my profile";
      profileLink = (
        <Nav.Link as={Link} to={UrlService.getUrl("/" + userHash)}><Icon icon="participant" /></Nav.Link>
      );
    } else {
      overlayText = "enter your user hash to login to teamstir";
      profileLink = (
        <Nav.Link as={Link} onClick={() => this.setState({ isLoginModalOpen: true })}>
          <Icon icon="participant" />
          <span className="hide-when-narrow">&nbsp;&nbsp;LOGIN</span>
        </Nav.Link>
      );
    }
    return <HelpOverlay content={profileLink} text={overlayText} />;
  }

  getAboutLink() {
    if (UrlService.isPage("about")) {
      return this.getHalo("info");
    }
    const aboutContent = <Nav.Link as={Link} to={UrlService.getUrl("/about")}><Icon icon="info" /></Nav.Link>;
    return <HelpOverlay content={aboutContent} text="learn more" />;
  }

  getContactLink() {
    if (UrlService.isPage("contact")) {
      return this.getHalo("email");
    }
    const contactContent = <Nav.Link as={Link} to={UrlService.getUrl("/contact")}><Icon icon="email" /></Nav.Link>;
    return <HelpOverlay content={contactContent} text="contact us" />;
  }

  getLoginModal() {
    const handleLoginYesClick = () => {
      const hash = this.state.loginHash.toLowerCase().trim();
      if (Validator.isHashValid(hash)) {
        this.setState({ isLoginModalOpen: false, loginHash: hash });
      }
    };

    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        handleLoginYesClick();
      }
    };

    return <ConfirmationModal
      title="log into teamstir"
      isOpen={this.state.isLoginModalOpen}
      content={
        <>
          <div className="modal-element">
            {"Enter your 10-digit private hash (password) to login:"}
            <Icon icon="question" helptext={`You can get this hash from your teamstir emails. Click on a link to visit the site and copy the "user=" parameter from the URL`} />
          </div>
          <br />
          <FormLabel>Private Hash:</FormLabel>
          <FormControl autoFocus value={this.state.loginHash} onChange={(e) => this.setState({ loginHash: e.target.value })} onKeyPress={handleKeyPress} placeholder="xxxxxxxxxx" type="text" />
          <br />
        </>
      }
      yesText="log in"
      secondaryText="resend my hash"
      onNo={() => this.setState({ isLoginModalOpen: false, loginHash: '' })}
      onYes={handleLoginYesClick}
      onSecondary={() => this.setState({ isRecoveryModalOpen: true })}
      isInvalid={!Validator.isHashValid(this.state.loginHash.toLowerCase().trim(), Constants.hash.user)}
    />;
  }

  getRecoveryModal() {
    return <ConfirmationModal
      title="enter email"
      isOpen={this.state.isRecoveryModalOpen}
      content={
        <>
          <div className="modal-element">Enter your email address to recover your private hash (password). You will receive a recovery email at that address</div>
          <br />
          <FormControl autoFocus value={this.state.userEmail || ''} onChange={(e) => this.setState({ userEmail: e.target.value })} placeholder="email" />
          <br />
        </>
      }
      yesText="send"
      onNo={() => {
        this.setState({ userEmail: undefined, isRecoveryModalOpen: false });
      }}
      onYes={() => { if (Validator.isEmailValid(this.state.userEmail)) this.setState({ isRecoveryModalOpen: false, isSendingEmail:true }); }}
      isInvalid={!Validator.isEmailValid(this.state.userEmail) && !this.state.isSendingEmail}
    />
  }

  componentDidUpdate(foo, bar) {
    if (!this.state.isLoginModalOpen && Validator.isHashValid(this.state.loginHash, Constants.hash.user)) {
      window.location = this.state.loginHash;
      this.setState({ loginHash: '' });
    }

    if (this.state.isSendingEmail) {
      EntityService.getAsyncData(() => {
        this.setState({ isSendingEmail: false });
      }, 'api/email/sendRecoveryLinks/' + this.state.userEmail);
    }
  }

  render() {
    return (
      <>
        <Navbar className="nav-menu">
        {this.getTeamstirLink()}
        <Nav className="navbar-right">
          {this.getStirLink()}
          {this.getAboutLink()}
          {this.getContactLink()}
          {this.getProfileLink()}
        </Nav>
      </Navbar>
        {this.getLoginModal()}
        {this.getRecoveryModal()}
        </>
    );
  }
}

import React, { useState, useEffect } from 'react';
import { ReactSortable } from 'react-sortablejs';
import TextService from '../../services/TextService';
import { Icon } from '../shared/Icon';
import { TeamInteractableTile } from '../tiles/TeamInteractableTile';

const getSelectedHashes = (props) => props.teamPicks.filter(x => x.pickRank > 0 && props.availableTeamHashes.includes(x.teamHash)).sort((a, b) => { return a.pickRank - b.pickRank; }).map(x => x.teamHash);
const getUnselectedHashes = (props) => props.availableTeamHashes.filter(hash => !getSelectedHashes(props).includes(hash));

export function RankDetails(props) {
  const teams = props.stir.theme.teams;
  const [selectedHashes, setSelectedHashes] = useState(getSelectedHashes(props));
  const [unselectedHashes, setUnselectedHashes] = useState(getUnselectedHashes(props));

  const handleTeamAddRemove = (hash, isAdd) => {
    if ((selectedHashes.length >= props.maxPickCount && isAdd) || props.isCommitted) return;

    let updatedSelectedHashes = selectedHashes.filter(x => x !== hash);
    let updatedUnselectedHashes = unselectedHashes.filter(x => x !== hash);
    if (isAdd) {
      updatedSelectedHashes.push(hash);
    } else {
      updatedUnselectedHashes.push(hash);
    }
    setSelectedHashes(updatedSelectedHashes);
    setUnselectedHashes(updatedUnselectedHashes);
  }

  useEffect(() => {
    if (props.isOverridingTeamPicks) {
      setSelectedHashes(getSelectedHashes(props));
      setUnselectedHashes(getUnselectedHashes(props));
    }
  }, [props.isOverridingTeamPicks]);

  useEffect(() => {
    let hashCount = 1;
    const pickList = [...new Set(selectedHashes
      .filter(teamHash => props.availableTeamHashes.includes(teamHash)))]
      .map(teamHash => { return { teamHash: teamHash, pickRank: hashCount++ }; });

    props.handlePreferencesChange(pickList);
  }, [selectedHashes]);

  if (!props.stir) return null;

  let pickRank = 1;

  let selectedTeams = selectedHashes.map(hash =>
    <TeamInteractableTile
      key={hash}
      data-id={hash}
      team={teams.find(t => t.hash === hash)}
      pickRank={pickRank++}
      isCommitted={props.isCommitted}
      methodId={props.stir.currentStep.methodId}
      handleTeamAddRemove={handleTeamAddRemove}
    />
  );

  const disableSelect = selectedHashes.length >= props.maxPickCount;

  const unselectedTeams = unselectedHashes.map(hash =>
    <TeamInteractableTile
      key={hash}
      data-id={hash}
      team={teams.find(t => t.hash === hash)}
      pickRank={null}
      isCommitted={props.isCommitted}
      methodId={props.stir.currentStep.methodId}
      handleTeamAddRemove={handleTeamAddRemove}
      disableSelect={disableSelect}
    />
  );

  const helptext = props.availableTeamHashes.length
    ? `${props.isCommitted ? "Uncommit your preferences, click" : "Click"} the + button to select teams, and then arrange them here with those you want ${TextService.getResolutionVerb(props.stir.currentStep.resolutionTypeId, true)} higher in the list. ${props.resolutionText}`
    : "There are no available teams to assign.";

  return (
    <div className="tile-dual-outer">
      <div className="tile-dual-inner right">
        {selectedTeams?.length > 0 && 
          <div className="tile-dual-inner-header">
            {`selected teams (` + (disableSelect ? "max teams selected)" : `up to ${props.maxPickCount - selectedHashes.length} more)`)}
            <Icon icon="question" helptext={helptext} />
          </div>}
        {(selectedTeams?.length > 0)
          ? <ReactSortable
              className="sortable-container"
              group='teams'
              animation={150}
              list={selectedHashes}
              setList={(values) => setSelectedHashes(values.map(val => val.toString()))}
              filter={props.isCommitted ? ".tile-outer" : ""}
            >
              {selectedTeams}
            </ReactSortable>
          : props.getNoContentControl("selected", helptext) }
      </div>
      <div className="tile-dual-inner">
        {unselectedTeams?.length > 0 &&
          <div className="tile-dual-inner-header">unselected teams</div>}
        <ReactSortable
          className="sortable-container"
          group='teams'
          animation={150}
          list={unselectedHashes}
          setList={(values) => setUnselectedHashes(values.map(val => val.toString()))}
          filter={".tile-outer"}
        >
          {(unselectedTeams?.length > 0)
            ? <>{unselectedTeams}</>
            : <></>
          }
        </ReactSortable>
      </div>
      <span className="stretch"></span>
    </div>);
}
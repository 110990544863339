import React, { Component } from 'react';
import { Icon } from '../shared/Icon';
import { Tile } from './Tile';
import { HelpOverlay } from '../shared/HelpOverlay';
import Constants from '../../services/Constants';

export class TeamInteractableTile extends Component {

  constructor(props) {
    super(props);
    if (!props.team.isInitialized) throw new Error("team not initialized");
  }

  getIcon() {
    if (this.props.disableSelect || this.props.isCommitted) {
      return <Icon icon="team" />;
    }

    if (this.props.pickRank == null) {
      return <Icon icon="plus" />;
    }

    if (this.props.methodId === Constants.methodType.Rank) {
       return <Icon icon="drag" />;
    }

    return <Icon icon="minus" />;
  }

  getFooterElements() {
    return (this.props.pickRank && this.props.methodId === 3)
      ? (
        <div>
          <HelpOverlay content={<div>{"#" + this.props.pickRank}</div>} text={"you have given " + this.props.team.name + " a preference rank of #" + this.props.pickRank} />
        </div>
      ) : (
        <></>
      );
  }

  getDropdownActions() {
    return [];
  }

  getUnselectTeamIcon() {
    if (!this.props.isCommitted && this.props.pickRank && this.props.methodId === Constants.methodType.Rank) {
      return <Icon icon="times" />;
    }
    return null;
  }

  render() {
    return <Tile
      pickRank={this.props.pickRank}
      onClick={this.props.handleTeamAddRemove}
      isHeader={this.props.isHeader}
      icon={this.getIcon()}
      header={this.props.team.name}
      footer={null}
      headerElements={null}
      footerElements={this.getFooterElements()}
      buttonAction={this.getUnselectTeamIcon()}
      dropdownActions={this.getDropdownActions()}
      hash={this.props.team.hash}
      {...this.props}
    />;
  }
}
export default class Match {
  constructor(match) {
    if (match.isInitialized) throw new Error("match already initialized"); else match.isInitialized = true;

    match.getOutcome = () => {
      if (match.teamHash && match.step?.outcomes?.length > 0) {
        return match.step.outcomes.find(outcome => outcome.teamHash === match.teamHash);
      }
      return { };
    }

    return match;
  }
}

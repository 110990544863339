import React, { Component } from 'react';
import { Icon } from '../shared/Icon';
import { Tile } from './Tile';
import { DropdownAction } from '../shared/DropdownAction';
import { TileElement } from './TileElement';
import TextService from '../../services/TextService';
import _uniqueId from 'lodash/uniqueId';

export class ThemeTile extends Component {

  constructor(props) {
    super(props);
    if (!props.theme.isInitialized) throw new Error("theme not initialized");
  }

  getIcon() {
    return <Icon icon={"theme"} />;
  }

  getFooter() {
    const theme = this.props.theme;
    let infoString = "";
    if (theme.description && theme.description.length) {
      infoString = theme.description;
    } else if (theme.createdByParticipantName) {
      infoString = "created by " + theme.createdByParticipantName;
    } else {
      if (theme.bgg && theme.bgg.year) {
        infoString = "published " + theme.bgg.year;
      }
      if (theme.bgg && theme.bgg.rating) {
        infoString += (infoString.length ? ", " : "") + "bgg rating " + theme.bgg.rating;
      }
    }
    return infoString.length ? <>{infoString}</> : null;
  }

  getHeaderElements() {
    return (!this.props.theme.bgg) ? [] : [
      <TileElement key={1} label="category" content={this.props.theme.bgg.category} />,
      <TileElement key={2} label="description" content={this.props.theme.bgg.description} inlinelabel={true} />,
      <TileElement key={3} content={<img src={this.props.theme.bgg.thumbnailUrl} alt={this.props.theme.name} />} css="allow-overflow mobile-hide" />
    ];
  }

  getFooterElements() {
    const theme = this.props.theme;
    return (
      <>
        <div>
          {theme.minParticipants}-{theme.maxParticipants} <Icon icon="participant" helptext={"this theme is for " + theme.minParticipants + " to " + theme.maxParticipants + " participants"} />
        </div>
        <div>
          {theme.stirCount} <Icon icon="stir" helptext={"there " + TextService.isArePlural(theme.stirCount, "stir") + " with this theme"} />
        </div>
        <div>
          {theme.teamCount || theme.teams.length} <Icon icon="team" helptext={"there " + TextService.isArePlural(theme.teamCount || theme.teams.length, "team") + " in this theme"} />
        </div>
      </>
    );
  }

  getDropdownActions() {
    return this.props.theme.links.map(x =>
      <DropdownAction key={_uniqueId()} name={x.name} url={x.url} />
    ).concat(
      <DropdownAction key={_uniqueId()} name={"view on BGG"} url={(this.props.theme.bggId ? "https://boardgamegeek.com/boardgame/" + this.props.theme.bggId : null)} />
    );
  }

  render() {
    return <Tile
      isHeader={this.props.isHeader}
      hash={this.props.theme.hash}
      icon={this.getIcon()}
      header={this.props.theme.name}
      footer={this.getFooter()}
      headerElements={this.getHeaderElements()}
      footerElements={this.getFooterElements()}
      dropdownActions={this.getDropdownActions()}
      {...this.props}
    />;
  }
}

export default class Team {
  constructor(team) {
    if (team.isInitialized) throw new Error("team already initialized"); else team.isInitialized = true;

    team.addMatchStats = (matches) => {
      // Count how many times each team has been elected, excluded, and assigned.
      matches.filter(m => m.stepHash && m.teamHash === team.hash).forEach(m => {
        team.electedCount = m.electedCount || 0;
        team.excludedCount = m.excludedCount || 0;
        team.assignedCount = m.assignedCount || 0;
      });
    }

    return team;
  }
}

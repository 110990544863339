import Constants from "../services/Constants";

export default class Participant {
  constructor(participant, stepParticipant) {
    if (participant.isInitialized) throw new Error("participant already initialized"); else participant.isInitialized = true;

    participant.isAdmin = participant.stirParticipantTypeId === Constants.stirParticipantType.AdminParticipant || participant.stirParticipantTypeId === Constants.stirParticipantType.AdminObserver;
    participant.isObserver = participant.stirParticipantTypeId === Constants.stirParticipantType.Observer || participant.stirParticipantTypeId === Constants.stirParticipantType.AdminObserver;
    participant.isCommitted = stepParticipant?.isCommitted === true;
    participant.concedeTies = stepParticipant?.concedeTies === true;

    return participant;
  }
}

import React, { Component } from 'react';
import { Icon } from '../shared/Icon';
import { Tile } from './Tile';
import { DropdownAction } from '../shared/DropdownAction';
import { TileElement } from './TileElement';
import DateService from '../../services/DateService';
import TextService from '../../services/TextService';
import Constants from '../../services/Constants';
import _uniqueId from 'lodash/uniqueId';

export class StirTile extends Component {

  isAdminAssign = () => this.props.stir.currentStep.methodId === Constants.methodType.Admin && this.props.stir.currentStep.resolutionTypeId === Constants.resolutionType.Assign;

  getStepUiElements() {
    if (!this.props.stir || !this.props.stir.steps)
      return [];
    return this.props.stir.steps
      .map(x => {
      let dateText = `not yet started`;
      const isCurrentStep = x.hash === this.props.stir.currentStepHash;
      if (x.dateResolved) {
        dateText = `resolved ${DateService.getShortDateText(x.dateResolved)}`;
      } else if (x.dateShouldResolve && isCurrentStep) {
        dateText = `due ${DateService.getShortDateText(x.dateShouldResolve)}`;
      } else if (x.dateStarted) {
        dateText = `started ${DateService.getShortDateText(x.dateStarted)}`;
      }
      const methodText = (this.props.stir.isSecretReveal ? "secret " : "") + TextService.getResolutionNoun(x.resolutionTypeId);
      const icon = x.dateResolved ? x.methodName.split(" ")[0].toLowerCase() : "in-progress";
      const content = ` ${x.methodName.toLowerCase()} ${methodText} ${dateText}\n`;
      return <div key={`${x.hash}_stepdisplay`}><Icon icon={icon} />{content}</div>;
    });
  }

  getIcon() {
    const isObserver = this.props.stir.stirParticipantTypeId === Constants.stirParticipantType.Observer || this.props.stir.stirParticipantTypeId === Constants.stirParticipantType.AdminObserver;
    return <Icon icon={isObserver ? "observer" : "stir"} />;
  }

  getFooter() {
    let inner = null;
    const step = this.props.stir.currentStep;
    const dateResolved = step ? step.dateResolved : this.props.stir.dateResolved;
    const dateShouldResolve = step ? step.dateShouldResolve : this.props.stir.dateShouldResolve;
    const methodName = step ? step.methodName : this.props.stir.methodName;
    const resolutionType = step?.resolutionTypeId;
    const resolutionCount = step?.resolutionCount;
    const timeAgoText = "resolved " + DateService.getDurationText(dateResolved);

    if (dateResolved && this.props.stir.assignedTeamName) {
      const assignedTeamNames = this.props.stir.assignedTeamName.split(', ');

      let assignCountsByTeamName = {};
      for (let i = 0; i < assignedTeamNames.length; i++) {
        if (assignCountsByTeamName[assignedTeamNames[i]] === undefined) {
          assignCountsByTeamName[assignedTeamNames[i]] = 1;
        }
        else {
          assignCountsByTeamName[assignedTeamNames[i]]++;
        }
      }

      const maxAssignCount = Math.max(...Object.values(assignCountsByTeamName));

      this.props.stir.assignedTeamName = Object.keys(assignCountsByTeamName).filter(teamName => assignCountsByTeamName[teamName] === maxAssignCount).join(", ");

      inner = <><span className="main-text">{this.props.stir.assignedTeamName}</span><span className="note-text">&nbsp;&ndash;&nbsp;{timeAgoText}</span></>;
    } else if (dateResolved) {
      inner = <span className="note-text">{TextService.getResolutionText(resolutionType, resolutionCount, timeAgoText, true, this.isAdminAssign())}</span>;
    } else if (dateShouldResolve) {
      const secondsUntilResolution = parseInt((new Date(dateShouldResolve) - new Date()) / Constants.oneSecond);
      const shouldHaveResolved = !dateResolved && secondsUntilResolution <= 0;
      const reasonableSecondsToBeAmbiguous = 60;
      const timeLeftText =
        (shouldHaveResolved && Math.abs(secondsUntilResolution) <= reasonableSecondsToBeAmbiguous)
          ? "ending momentarily"
          : (shouldHaveResolved ? "should have ended " : "ends ") + DateService.getDurationText(dateShouldResolve);
      inner = <span className="note-text">{TextService.getResolutionText(resolutionType, resolutionCount, timeLeftText, false, this.isAdminAssign())}</span>;
    } else {
      inner = <span className="note-text">{TextService.getResolutionText(resolutionType, resolutionCount, null, false, this.isAdminAssign())}</span>;
    }

    const icon = dateResolved ? methodName.split(" ")[0].toLowerCase() : "in-progress";
    return <><Icon icon={icon} />{inner}</>;
  }

  getHeaderElements() {
    const deadlineLabel = `${this.props.stir.stepCount > 1 ? 'next ' : ''}deadline`;
    const deadlineText = DateService.getDateText((this.props.stir.currentStep && !this.props.stir.dateResolved) ? this.props.stir.currentStep.dateShouldResolve : null);
    const elementGroup1 = (
      <>
        <TileElement label={deadlineLabel} content={deadlineText} inlinelabel={true} />
        <TileElement label="event" content={DateService.getDateText(this.props.stir.event?.eventDate)} inlinelabel={true} />
      </>
    );
    const showGroup1 = deadlineText || this.props.stir.event?.eventDate;

    return [
      <TileElement key={1} css="tilehead-oneliner" content={showGroup1 ? elementGroup1 : null} />,
      <TileElement key={2} label="message" content={this.props.stir.message} inlinelabel={true} />,
      <TileElement key={3} css="tilehead-oneliner" content={this.getStepUiElements()} />
    ];
  }

  getFooterElements() {
    const revealMessage = this.props.stir.isSecretReveal ? ". Results are concealed, which means no one can see anyone else's assignments, and votes are hidden" : "";
    const medal = ![1, 2, 3].includes(this.props.stir.winnerRank) ? null : (
      <div>
        <Icon icon="medal" rank={this.props.stir.winnerRank} helptext="You" />
      </div>);
    const committedParticipant = (this.props.stir.dateResolved || this.isAdminAssign()) ? null : (
      <div>
        {this.props.stir.getCommittedCount()} <Icon icon="user-check" helptext={this.props.stir.getCommittedCount() + " of " + this.props.stir.participantCount + " participants have committed their preferences"} />
      </div>);
    const totalStirPoints = !this.props.stir.totalStirPoints ? null : (
      <div>
        {this.props.stir.totalStirPoints} <Icon icon="star" helptext={"Participants in this stir assigned a total of " + TextService.pluralize(this.props.stir.totalStirPoints, "Stir Point") + ", which are the teams' inverse rankings (higher numbers mean more desired teams)."} />
      </div>);
    const averagePreference = !this.props.stir.averagePreference ? null : (
      <div>
        {this.props.stir.averagePreference.toFixed(1)} <Icon icon="balance" helptext={"The average preference among assigned teams in this stir is " + this.props.stir.averagePreference.toFixed(1) + "."} />
      </div>);
    return (
      <>
        {medal}
        {committedParticipant}
        {totalStirPoints}
        {averagePreference}
        <div>
          {this.props.stir.participantCount} <Icon icon={this.props.stir.isSecretReveal ? "user-secret" : "participant"} helptext={"There " + TextService.isArePlural(this.props.stir.participantCount, "participant") + " in this stir" + revealMessage + "."} />
        </div>
        <div>
          {this.props.stir.teamCount} <Icon icon="team" helptext={"There " + TextService.isArePlural(this.props.stir.teamCount, "team") + " in this stir."} />
        </div>
      </>);
  }

  getDropdownActions() {
    let actions = [];
    const participant = this.props.stir.participants?.find(p => p.hash === this.props.user?.publicHash);
    const isStirParticipant = this.props.user && (this.props.page === Constants.page.User || participant !== undefined);
    const stirParticipantTypeId = this.props.stir?.stirParticipantTypeId ?? participant?.stirParticipantTypeId;
    const isAdmin = stirParticipantTypeId === Constants.stirParticipantType.AdminParticipant || stirParticipantTypeId === Constants.stirParticipantType.AdminObserver;
    const isObserver = stirParticipantTypeId === Constants.stirParticipantType.Observer || stirParticipantTypeId === Constants.stirParticipantType.AdminObserver;
    const userHash = this.props.user?.hash;

    if (isStirParticipant && this.props.page !== Constants.page.Assign && isAdmin && this.isAdminAssign() && !this.props.stir.dateResolved) actions.push(
      <DropdownAction name={"assign teams"} hash={'assign/' + this.props.stir.hash} key={_uniqueId()} />
    );

    if (isStirParticipant && this.props.page !== Constants.page.Administrator && isAdmin) actions.push(
      <DropdownAction name={"administer stir"} hash={'administer/' + this.props.stir.hash} key={_uniqueId()} />
    );

    if (isStirParticipant && !isObserver && this.props.page !== Constants.page.Preference && !this.props.stir.dateResolved && !this.isAdminAssign()) actions.push(
      <DropdownAction name={"set preferences"} hash={'pick/' + this.props.stir.hash} key={_uniqueId()} />
    );

    if (this.props.page !== Constants.page.Stir) actions.push(
      <DropdownAction name={this.props.stir.dateResolved ? "view matches" : "view progress"} hash={this.props.stir.hash} key={_uniqueId()} />,
    );

    if (this.props.stir.themeHash) actions.push(
      this.props.page === Constants.page.User
        ? <DropdownAction name={"view theme"} uri={this.props.stir.themeHash + "?user=" + userHash + "&from=" + this.props.stir.hash} key={_uniqueId()} />
        : <DropdownAction name={"view theme"} hash={this.props.stir.themeHash} key={_uniqueId()} />
    );

    if (this.props.page !== Constants.page.Event) actions.push(
      <DropdownAction name={"view event"} hash={this.props.stir.eventHash || this.props.stir.event?.hash} key={_uniqueId()} />
    );

    if (this.props.page === Constants.page.Preference && this.props.handleStirSelect && !this.props.isCommitted && !this.isAdminAssign()) actions.push(
      <DropdownAction name={"clear preferences"} func={() => this.props.handleStirSelect(Constants.actionType.clearPreferences)} key={_uniqueId()} />,
    );

    if (this.props.page === Constants.page.Preference && this.props.handleStirSelect && !this.props.stir.dateResolved && !isAdmin) actions.push(
      <DropdownAction name={"leave this stir"} func={() => this.props.handleStirSelect(Constants.actionType.leaveThisStir)} key={_uniqueId()} />
    );

    if ([Constants.page.Preference, Constants.page.Administrator, Constants.page.Stir].includes(this.props.page)) actions.push(
      <DropdownAction name={"clone this stir"} uri={`stir/1?templateStir=${this.props.stir.hash}${userHash ? `&user=${userHash}` : ''}`} key={_uniqueId()} />
    );

    return actions;
  }

  render() {
    return <Tile
      isHeader={this.props.isHeader}
      icon={this.getIcon()}
      header={this.props.stir.themeName}
      footer={this.getFooter()}
      headerElements={this.getHeaderElements()}
      footerElements={this.getFooterElements()}
      dropdownActions={this.getDropdownActions()}
      hash={this.props.stir.hash}
      {...this.props}
    />;
  }
}

import Constants from '../services/Constants';

export default class TextService {

  static genericErrorText = "there was an error connecting to the server";
  static inactivityErrorText = "Reload page to restart checking stir status. (updates stopped due to inactivity)";

  static htmlEscape = (str) => {
    return str.toString()
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
  }

  static htmlUnescape = (str) => {
    return str.toString()
      .replace(/&amp;/g, '&')
      .replace(/&quot;/g, `"`)
      .replace(/&#10;/g, ' ')
      .replace(/&#39;/g, '\'')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>');
  }

  // Returns simple text with a quantity and a noun, pluralized if appropriate.
  // For example (1, dog) or (5, dog) would yield the text "1 dog" or "5 dogs".
  static pluralize = (quantity, noun) => {
    return this.pluralizeIrregular(quantity, noun, `${noun}s`);
  }

  static pluralizeIrregular = (quantity, singularNoun, pluralNoun) => {
    return `${quantity} ${quantity === 1 ? singularNoun : pluralNoun}`;
  }

  static isArePlural = (quantity, noun) => {
    return (quantity === 1 ? "is " : "are ") + this.pluralize(quantity, noun);
  }

  static getResolutionVerb = (resolutionTypeId, isPastTense) => {
    switch (resolutionTypeId) {
      case Constants.resolutionType.Assign:
        return isPastTense ? "assigned" : "assign";
      case Constants.resolutionType.Elect:
        return isPastTense ? "elected" : "elect";
      case Constants.resolutionType.Exclude:
        return isPastTense ? "excluded" : "exclude";
      default:
        return "";
    }
  }

  static getResolutionNoun = (resolutionTypeId) => {
    switch (resolutionTypeId) {
      case Constants.resolutionType.Assign:
        return "assignment";
      case Constants.resolutionType.Elect:
        return "election";
      case Constants.resolutionType.Exclude:
        return "exclusion";
      default:
        return "method";
    }
  }

  static getResolutionText = (resolutionType, resolutionCount, timeText, isPastTense, isAdminAssign) => {
    const text = timeText || "no deadline";
    switch (resolutionType) {
      case Constants.resolutionType.Assign:
        if (isPastTense) return text;

        if (resolutionCount === 0) return isAdminAssign ? "the admin is currently assigning teams" : "assigning all teams";

        return `assigning ${this.pluralize(resolutionCount, "team")} per participant (${text})`;
      case Constants.resolutionType.Elect:
        return isPastTense
          ? text
          : `electing ${this.pluralize(resolutionCount, "team")} (${text})`;
      case Constants.resolutionType.Exclude:
        return isPastTense
          ? text
          : `excluding ${this.pluralize(resolutionCount, "team")} (${text})`;
      default:
        return text;
    }
  }

}

import React, { Component } from 'react';
import { Spinner } from '../shared/Spinner';
import { NoContent } from '../shared/NoContent';
import { Breadcrumbs } from '../shared/Breadcrumbs';
import { TeamTile } from '../tiles/TeamTile';
import { ThemeTile } from '../tiles/ThemeTile';
import EntityService from '../../services/EntityService';
import { UrlService } from '../../services/UrlService';
import Validator from '../../services/ValidationService';
import Theme from '../../entities/Theme';
import Constants from '../../services/Constants';
import BggService from '../../services/BggService';
import Event from '../../entities/Event';
import StirView from '../../entities/StirView';
import Stir from '../../entities/Stir';

export class ThemeDetails extends Component {
  constructor(props) {
    super(props);
    this.updateFromEntityContent = this.updateFromEntityContent.bind(this);
    this.state = {
      stir: null,
      event: null,
      theme: {},
      isLoading: true,
      notFound: false
    };
  }

  componentDidMount() {
    document.title = 'teamstir - Theme Details';

    EntityService.getEntityAsync((data) => {
      if (data) {
        this.setState({ theme: new Theme(data), isLoading: false }, () => {
          this.getBggContent();
          this.getCreatedByContent();
        });
      } else {
        this.setState({ isLoading: false, notFound: true });
      }
    }, this.props.hash);
    if (Validator.isHashValid(UrlService.getUrlParam("from"))) {
      EntityService.getEntityAsync(this.updateFromEntityContent, UrlService.getUrlParam("from"));
    }
  }

  updateFromEntityContent(data) {
    if (!data || !data.hash) return;
    if (Validator.isHashValid(data.hash, Constants.hash.stir)) {
      this.setState({ stir: new Stir(data) });
    } else if (Validator.isHashValid(data.hash, Constants.hash.match)) {
      this.setState({ stir: new Stir(data.stir) });
    } else if (Validator.isHashValid(data.hash, Constants.hash.administrator)) {
      this.setState({ stir: new Stir(data.stir) });
    } else if (Validator.isHashValid(data.hash, Constants.hash.event)) {
      const stir = data.stirs.find(x => x.theme.hash === this.props.hash);
      this.setState({ event: new Event(data), stir: new StirView(stir) });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.user?.name && !this.state.user) {
      this.setState({ user: newProps.user });
    }
  }

  getBggContent() {
    if (!this.state.theme.bggId) return null;

    BggService.getGameData(this.state.theme.bggId, (data) => {
      let theme = this.state.theme;
      theme.bgg = data;
      this.setState({ theme: theme });
    });
  }

  getCreatedByContent() {
    if (!this.state.theme.createdByParticipantHash) {
      return;
    }
    EntityService.getAsyncData(data => {
      const theme = this.state.theme;
      theme.createdByParticipantName = data.name;
      this.setState({ theme: theme });
    }, 'api/' + this.state.theme.createdByParticipantHash);
  }

  getContent() {
    if (!this.state.theme) {
      return null;
    }
    if (this.state.theme.teams && this.state.theme.teams.length) {
      return this.state.theme.teams.map(team =>
        <TeamTile key={team.hash} team={team} />
      );
    }
    return <NoContent noun={"teams"} />
  }

  render() {
    if (this.state.isLoading) return <Spinner />;

    if (this.state.notFound) return <NoContent noun={"theme"} />;

    return (
      <div className="center">
        <div className="tile-container">
          <Breadcrumbs user={this.props.user} stir={this.state.stir} event={this.state.event} theme={this.state.theme} />
          <ThemeTile key={this.state.theme.hash} theme={this.state.theme} isHeader="true" />
          {this.getContent()}
        </div>
      </div>);
  }
}

import Stir from './Stir';

export default class Event {
  constructor(data) {
    var stirs = (data.stirs || []).map(s => new Stir(s));

    var participantNames = [...new Set(stirs.flatMap(x => x.participants).map(x => x.name))];

    var event = {
      address: data.address,
      createdByParticipantHash: data.createdByParticipantHash,
      hash: data.hash,
      name: data.name,
      message: data.message,
      timeZone: data.timeZone,
      stirs: stirs,
      stirCount: data.stirs.length,
      participantNames: participantNames,
      participantCount: participantNames.length,
      eventDate: data.eventDate ? new Date(data.eventDate) : null,
      invitationDateLastSent: data.invitationDateLastSent ? new Date(data.invitationDateLastSent) : null
    };

    return event;
  }
}
import Constants from "../services/Constants";

export default class Step {
  constructor(step) {
    if (step.isInitialized) throw new Error("step already initialized"); else step.isInitialized = true;

    step.dateStarted = step.dateStarted ? new Date(step.dateStarted) : null;
    step.dateShouldResolve = step.dateShouldResolve ? new Date(step.dateShouldResolve) : null;
    step.dateResolved = step.dateResolved ? new Date(step.dateResolved) : null;

    step.isAssign = () => step.resolutionTypeId === Constants.resolutionType.Assign;
    step.isElect = () => step.resolutionTypeId === Constants.resolutionType.Elect;
    step.isExclude = () => step.resolutionTypeId === Constants.resolutionType.Exclude;
    step.isSecretReveal = () => step.revealTypeId === Constants.revealType.ToParticipant;
    step.isAdminAssign = () => step.isAssign() && step.methodId === Constants.methodType.Admin;
    step.isDraft = () => step.methodId === Constants.methodType.Draft;

    return step;
  }
}

import React, { useState, useEffect } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { Icon } from '../shared/Icon';
import { TeamInteractableTile } from '../tiles/TeamInteractableTile';
import { MatchTile } from '../tiles/MatchTile';
import Match from '../../entities/Match';

export function DraftDetails(props) {
  const selectedHashesDefault = props.teamPicks.filter(x => x.pickRank > 0 && props.availableTeamHashes.includes(x.teamHash)).sort((a, b) => { return a.pickRank - b.pickRank; }).map(x => x.teamHash);
  const unselectedHashesDefault = props.availableTeamHashes.filter(hash => !selectedHashesDefault.includes(hash) && !props.draftedTeamHashes.includes(hash));

  const teams = props.stir.theme.teams; //all possible teams
  const currentStep = props.stir.currentStep;
  const activeUserHash = currentStep.activeUserHash;
  const isActiveParticipant = props.user.publicHash === activeUserHash;
  const [selectedHashes, setSelectedHashes] = useState(selectedHashesDefault);
  const [unselectedHashes, setUnselectedHashes] = useState(unselectedHashesDefault);
  const isPickingDisabled = selectedHashes.length >= props.maxPickCount || props.isCommitted || !isActiveParticipant;

  useEffect(() => {
    if (selectedHashes.length != props.teamPicks.length) {
      ensureSave();
    }
  }, [selectedHashes]);

  const handleTeamAddRemove = (hash, isAdd) => {
    let newSelectedHashes = selectedHashes.filter(x => x !== hash);
    let newUnselectedHashes = unselectedHashes.filter(x => x !== hash);

    if (isAdd) {
      if (selectedHashes.length >= props.maxPickCount || props.isCommitted) return;

      newSelectedHashes.push(hash);
    } else {
      if (props.teamPicks.length < props.maxPickCount || props.teamPicks.sort((a, b) => { return b.pickRank - a.pickRank; })[0].teamHash !== hash || props.isCommitted) return;
      
      newUnselectedHashes.push(hash);
    }

    setSelectedHashes(newSelectedHashes);
    setUnselectedHashes(newUnselectedHashes);
  }

  const ensureSave = () => {
    let hashCount = 1;
    const pickList = [...new Set(selectedHashes
      .filter(teamHash => props.availableTeamHashes.includes(teamHash)))]
      .map(teamHash => { return { teamHash: teamHash, pickRank: hashCount++ }; });

    props.handlePreferencesChange(pickList);
  }

  if (!props.stir) {
    console.log("no stir");
    return null;
  }

  let pickRank = 1;
  let selectedTeams = selectedHashes.map(hash => {
    if (props.draftedTeamHashes.includes(hash)) {
      const m = new Match({
        assignedCount: 1,
        teamHash: hash,
        participantHash: props.user.publicHash,
        team: props.stir.theme.teams.find(t => t.hash === hash),
        participant: props.stir.participants.find(p => p.hash === props.user.publicHash)
      });
      return <MatchTile key={m.teamHash + m.participantHash} match={m} isSecretReveal={props.stir.isSecretReveal} isDraft={true} />;
    }
    return <TeamInteractableTile
      key={hash}
      data-id={hash}
      team={teams.find(t => t.hash === hash)}
      pickRank={pickRank++}
      isCommitted={props.isCommitted}
      methodId={currentStep.methodId}
      handleTeamAddRemove={handleTeamAddRemove}
    />;
  });

  const unselectedTeams = unselectedHashes.map(hash =>
    <TeamInteractableTile
      key={hash}
      data-id={hash}
      team={teams.find(t => t.hash === hash)}
      pickRank={null}
      isCommitted={isPickingDisabled}
      methodId={currentStep.methodId}
      handleTeamAddRemove={!isPickingDisabled && handleTeamAddRemove}
      disableSelect={isPickingDisabled}
    />
  );

  let helptext = undefined;
  let activeUserText = undefined;
  let headerText = <div className="tile-dual-inner-header">my drafted teams</div>;

  if (isActiveParticipant) {
    helptext = `Click the + button to draft a team. Click Commit to confirm your pick and advance the draft. ${props.resolutionText}`;
    activeUserText = <div>It is your turn to draft</div>;
    headerText = selectedTeams?.length > 0 && <div className="tile-dual-inner-header">
      {`selected teams (` + (isPickingDisabled ? "max teams selected)" : `up to ${props.maxPickCount - selectedHashes.length} more)`)}
      <Icon icon="question" helptext={helptext} />
    </div>;
  }

  return (
    <div className="tile-dual-outer">
      {activeUserText}
      <div className="tile-dual-inner right">
        {headerText}
        {(selectedTeams?.length > 0)
          ? <ReactSortable
              className="sortable-container"
              list={selectedHashes}
              setList={(values) => { setSelectedHashes(values.map(val => val.toString())) }}
              group='teams'
              animation={150}
              onMove={() => false}
              sort={false}
            >
              {selectedTeams}
            </ReactSortable>
          : props.getNoContentControl("drafted", helptext) }
      </div>
      <div className="tile-dual-inner">
        {unselectedTeams?.length > 0 && <div className="tile-dual-inner-header">undrafted teams</div>}
        <ReactSortable
          className="sortable-container"
          list={unselectedHashes}
          setList={(values) => { setUnselectedHashes(values.map(val => val.toString())) }}
          group='teams'
          animation={150}
          onMove={() => !isPickingDisabled}>
          {unselectedTeams?.length > 0 &&
            <>
              {unselectedTeams}
            </>
          }
        </ReactSortable>
      </div>
      <span className="stretch"></span>
    </div>);
}
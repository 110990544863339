import Constants from '../services/Constants';

//this class is used for displaying stir summary info relative to the current user as part of a list
export default class StirView {
  constructor(stirViewData) {
    Object.assign(this, stirViewData);
    this.getCommittedCount = () => stirViewData.committedCount;
    this.isSecretReveal = stirViewData.currentStep.revealTypeId === Constants.revealType.ToParticipant;
  }
  //additional properties already exist on the data send from the backend
}
import React, { useState, useRef } from 'react';
import { Form, FormCheck, FormLabel, FormControl } from 'react-bootstrap';
import { ReactSortable } from 'react-sortablejs';
import { ConfirmationModal } from '../shared/ConfirmationModal';
import { EditEvent } from '../shared/EditEvent';
import { Spinner } from '../shared/Spinner';
import { Icon } from '../shared/Icon';
import { UrlService } from '../../services/UrlService';
import DateService from '../../services/DateService';
import EntityService from '../../services/EntityService';
import Validator from '../../services/ValidationService';
import Constants from '../../services/Constants';
import "react-datepicker/dist/react-datepicker.css";
import '../../css/StirStep.css';
import '../../css/TileMini.css';
import _uniqueId from 'lodash/uniqueId';
import { getErrors } from './CreateStir';

export const eventType = {
  none: 0,
  create: 1,
  link: 2,
};

export const Step4 = (props) => {
  const userHash = UrlService.getUserHashFromUrl();
  const { stir, isSubmitting } = props;
  const update = props.handleStirChange;
  const [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false);
  const [isLinkEventModalOpen, setIsLinkEventModalOpen] = useState(false);
  const isLoadingEvent = useRef();
  const isLoadingTieBreakers = useRef();
  const [isError, setIsError] = useState(false); //not used

  UrlService.useTitle('Step 4');

  const loadEvents = (data) => {
    if (!data) {
      setIsError(true);
    } else {
      stir.events = data;
      setIsError(false);
      update(stir);
    }
  }

  if (stir.events === null && userHash) {
    EntityService.getAsyncData(loadEvents, `api/events/?user=${userHash}`, isLoadingEvent);
  }

  if (!stir.tieBreakers.length) {
    EntityService.getAsyncData((data) => {
      stir.tieBreakers = data;
      update(stir);
    }, 'api/tieBreakers', isLoadingTieBreakers);
  }

  const getEventDisplayText = (name, date, isText) => {
    return isText
      ? `${name} on ${DateService.getShortDateText(date)}`
      : <><span className="bold">{name}</span><span>&nbsp;{"on"}&nbsp;{DateService.getDateText(date, DateService.dateLongFormat)}</span></>;
  }

  const getSubHead = () => {
    return (
      <div className="stir-step-top-content">
        <p>
          Configure optional settings here.
        </p>
      </div>
    );
  }

  const handleEventHashSelect = (e) => {
    setEvent(stir.events.find(x => x.hash === e.target.value) || {}, stir.selectedEventTypeId);
  }

  const handleEventTypeSelect = (selectedEventTypeId) => {
    setIsCreateEventModalOpen(selectedEventTypeId === eventType.create);
    setIsLinkEventModalOpen(selectedEventTypeId === eventType.link);
    stir.selectedEventTypeId = selectedEventTypeId;

    update(stir);
  }

  const handleEventModalAction = (selectedEventTypeId, isOk) => {
    if (!isOk) {
      const selectedEventTypeId = hasValidEvent() ? stir.selectedEventTypeId : eventType.none;
      setIsCreateEventModalOpen(false);
      setIsLinkEventModalOpen(false);
      stir.selectedEventTypeId = selectedEventTypeId;

      update(stir);
    } else if (
      (selectedEventTypeId === eventType.create && hasValidEvent()) ||
      (selectedEventTypeId === eventType.link && hasLinkedEvent())) {
      setIsCreateEventModalOpen(false);
      setIsLinkEventModalOpen(false);
    }
  }

  const setEvent = (event, selectedEventTypeId) => {
    stir.eventName = event.name || "";
    stir.eventDate = event.eventDate || null;
    stir.eventAddress = event.address || "";
    stir.eventMessage = event.message || "";
    stir.eventHash = event.hash || "";
    stir.selectedEventTypeId = selectedEventTypeId;

    update(stir);
  }

  const hasValidEvent = () => {
    return (
      stir.eventName.length >= 4 && stir.eventName.length <= 1000 &&
      stir.eventDate && !isNaN(new Date(stir.eventDate).getTime()) &&
      (!stir.eventAddress || stir.eventAddress.length <= 1000) &&
      (!stir.eventMessage || stir.eventMessage.length <= 1000));
  }

  const hasLinkedEvent = () => {
    return Validator.isHashValid(stir.eventHash, Constants.hash.event);
  }

  const getEventControls = () => {
    let eventControl = null;
    if (isCreateEventModalOpen || isLinkEventModalOpen) {
      eventControl = <div>editing...</div>;
    } else if (hasValidEvent()) {
      const address = stir.eventAddress.length ? <div><span className="bold">address:</span>&nbsp;{stir.eventAddress}</div> : null;
      const message = stir.eventMessage.length ? <div><span className="bold">message:</span>&nbsp;{stir.eventMessage}</div> : null;
      eventControl = (
        <div>
          {getEventDisplayText(stir.eventName, stir.eventDate, false)}
          {stir.selectedEventTypeId === eventType.create ? <Icon onClick={() => handleEventTypeSelect(stir.selectedEventTypeId)} css="stir-icon-toggle interactable" icon="edit" helptext="edit this event's details" /> : null}
          <Icon onClick={() => setEvent({}, eventType.none)} css="stir-icon-toggle interactable" icon="times" helptext={stir.eventHash ? "unlink this event" : "clear this event"} />
          {address}
          {message}
        </div>
      );
    } else {
      eventControl = (
        <Form.Select value={stir.selectedEventTypeId} onChange={(e) => handleEventTypeSelect(parseInt(e.target.value))} onLoad={(e) => handleEventTypeSelect(parseInt(e.target.value))} >
          <option key={_uniqueId()} value={eventType.none}>No event</option>
          <option key={_uniqueId()} value={eventType.create}>Create an event</option>
          {stir.events?.length ? <option key={_uniqueId()} value={eventType.link}>Link an event</option> : null}
        </Form.Select>);
    }
    return (
      <div className="padding-topbottom-10">
        <FormLabel>
          {"Event"}
          <Icon icon="question" helptext="If your event takes place at a certain time, enter it here to be shared with your participants." />
        </FormLabel>
        {eventControl}
      </div>);
  }

  const getStirMessageControl = () => {
    return (
      <div className="padding-topbottom-10">
        <FormLabel>
          {"Message"}
          <Icon icon="question" helptext="Include an optional custom message in your invitation email." />
        </FormLabel>
        <FormControl
          rows="3"
          as="textarea"
          value={stir.customMessage || ""}
          onChange={(e) => {
            stir.customMessage = e.target.value;
            update(stir);
          }}
          placeholder="Enter message to participants here" />
      </div>);
  }

  const setParticipantPriority = (participants, isPriorityList) => {
    const setOrder = (p, i, isPriority) => {
      p.priority = isPriority ? (i + 1) : undefined;
      p.nonPriority = isPriority ? undefined : (i + 1);
      return p;
    }
    const group1 = participants
      .filter(p => (p.name || p.email))
      .map((p, i) => setOrder(p, i, isPriorityList));
    const group2 = stir.participants
      .filter(p => (p.name || p.email) && !group1.map(x => x.id).includes(p.id))
      .sort((a, b) => isPriorityList ? (a.nonPriority - b.nonPriority) : (a.priority - b.priority))
      .map((p, i) => setOrder(p, i, !isPriorityList));
    const group3 = stir.participants
      .filter(p => !p.name && !p.email)
      .map(p => { p.priority = undefined; p.nonPriority = undefined; return p; });

    stir.participants = group1.concat(group2).concat(group3).sort((a, b) => a.id - b.id);
    update(stir);
  }

  const getTieBreakerParticipantControl = (p) => {
    return <div key={p.id} className="tile-mini participant grabbable">
      <div>
        {p.name}
      </div>
    </div>;
  }

  const getTieBreakerPriorityControl = () => {
    if (stir.selectedTieBreakerTypeId !== Constants.tieBreakerType.Priority || !stir.participants.length) {
      return null;
    }

    const priorityParticipants = stir.participants
      .filter(p => (p.name || p.email) && (p.priority !== undefined))
      .sort((a, b) => a.priority - b.priority);
    const nonPriorityParticipants = stir.participants
      .filter(p => (p.name || p.email) && (p.nonPriority !== undefined))
      .sort((a, b) => a.nonPriority - b.nonPriority);

    return (
      <>
        <div className="select-participants-header">
          <div>
            <span>participants</span>
            <Icon icon="question" helptext="Participants in this list will not receive priority from teamstir's algorithms." />
          </div>
          <div>
            <span>participants with priority</span>
            <Icon icon="question" helptext="Participants in this list will receive priority if a team could fairly be assigned to more than one participant. In that case, teamstir will give priority to whoever is higher on this list." />
          </div>
        </div>
        <div className="select-participants-container">
          <ReactSortable
            group='participants'
            animation={150}
            list={nonPriorityParticipants}
            setList={participants => setParticipantPriority(participants, false)}
          >
            {nonPriorityParticipants.map(p => getTieBreakerParticipantControl(p))}
          </ReactSortable>
          <ReactSortable
            group='participants'
            className="selected-participants"
            animation={150}
            list={priorityParticipants}
            setList={participants => setParticipantPriority(participants, true)}
          >
            {priorityParticipants.map(p => getTieBreakerParticipantControl(p))}
          </ReactSortable>
        </div>
      </>
    );
  }

  const getTieBreakerControl = () => {
    if (!stir) return;
    const availableTieBreakers = Validator.isHashValid(stir.eventHash)
      ? stir.tieBreakers
      : stir.tieBreakers.filter(x => x.id !== Constants.tieBreakerType.EventKarma);
    return (
      <div className="padding-topbottom-10">
        <FormLabel>
          {"Tie Breaker"}
          <Icon icon="question" helptext="Configure how teamstir handles ties, which is when an algorithm determines that a team could be fairly assigned to more than one participant." />
        </FormLabel>
        <Form.Select value={stir.selectedTieBreakerTypeId} onChange={(e) => {
          stir.selectedTieBreakerTypeId = parseInt(e.target.value);
          update(stir);
        }}>
          {availableTieBreakers.map(x => <option key={`tieBreaker_${x.id}`} value={x.id}>{x.name}</option>)}
        </Form.Select>
        <p className="stir-creation-blurb">
          {stir.getSelectedTieBreaker().blurb}
        </p>
        {getTieBreakerPriorityControl()}
      </div>);
  }

  const getAdditionalSettingsControl = () => {
    const hasTimeLimit = props.stir.methods.filter(method => props.stir.methodSteps.map(x => x.methodId).includes(method.id)).some(method => method.isTimeLimitSupported);
    return (
      <>
        <div className="padding-topbottom-10">
          <FormLabel>
            {"Additional Settings"}
          </FormLabel>
        </div>
        <div className="stir-checkbox">
          <FormCheck
            id="revealtype-stir-checkbox"
            value={stir.revealTypeId === Constants.revealType.ToParticipant}
            checked={stir.revealTypeId === Constants.revealType.ToParticipant}
            onChange={(e) => {
              stir.revealTypeId = e.target.checked ? Constants.revealType.ToParticipant : Constants.revealType.ToAll;
              update(stir);
            }} />
          <span>
            <label htmlFor="revealtype-stir-checkbox">conceal results</label>
            <Icon icon="question" helptext="With this option selected, team assignments will only be revealed to those who received them, so participants cannot see each other's assignments. Teams that are elected or excluded will be revealed, but how participants voted will be hidden. Use this for activities with hidden information like Mafia or Secret Santa." />
          </span>
        </div>

        {!stir.participantNamesOnly &&
          <div className="stir-checkbox">
            <FormCheck
              id="email-invitations-checkbox"
              value={stir.shouldEmailInvitations}
              checked={stir.shouldEmailInvitations}
              onChange={(e) => {
                stir.shouldEmailInvitations = e.target.checked;
                update(stir);
              }} />
            <span>
              <label htmlFor="email-invitations-checkbox">{hasTimeLimit ? "email invitations now" : "email results now"}</label>
              <Icon icon="question" helptext="With this option selected, an email will be sent to each participant when you click the 'stir' button. Invitations can still be sent any time when administering a stir or event." />
            </span>
          </div>
        }
      </>
    );
  }

  const getCreateEventModalContent = () => {
    if (stir.isSavingEvent) return <Spinner />;
    if (!isCreateEventModalOpen) return null;

    return <EditEvent
      event={{
        name: stir.eventName,
        eventDate: stir.eventDate,
        address: stir.eventAddress,
        message: stir.eventMessage
      }}
      islocked={Validator.isHashValid(stir.templateEventHash, Constants.hash.event)}
      handleEventNameChange={(e) => {
        stir.eventName = e.target.value;
        update(stir);
      }}
      handleEventDateChange={(eventDate) => {
        stir.eventDate = eventDate;
        update(stir);
      }}
      handleEventAddressChange={(e) => {
        stir.eventAddress = e.target.value;
        update(stir);
      }}
      handleEventMessageChange={(e) => {
        stir.eventMessage = e.target.value;
        update(stir);
      }}
    />;
  }

  const getLinkEventModalContent = () => {
    if (stir.isSavingEvent) return <Spinner />;
    if (!isLinkEventModalOpen) return null;
    return (
      <>
        <FormLabel>
          {"Event"}
          <Icon icon="question" helptext="Select an event to link to this stir." />
        </FormLabel>
        <Form.Select value={stir.eventHash || ""} onChange={handleEventHashSelect} onLoad={handleEventHashSelect} >
          <option key={_uniqueId()} value=""></option>
          {stir.events
            .sort((e1, e2) => (new Date(e2.eventDate) - new Date(e1.eventDate)))
            .map(e => <option key={e.hash} value={e.hash}>{getEventDisplayText(e.name, e.eventDate, true)}</option>)}
        </Form.Select>
      </>
    );
  }

  if (isSubmitting.current || isLoadingTieBreakers.current) return <Spinner />

  return (
    <div className="stir-body">
      <ConfirmationModal
        title="create event"
        isOpen={isCreateEventModalOpen}
        content={getCreateEventModalContent()}
        yesText="Ok"
        onNo={() => handleEventModalAction(eventType.create, false)}
        onYes={() => handleEventModalAction(eventType.create, true)}
        isInvalid={!hasValidEvent()}
        validationText={"Give your event a time and place."}
      />
      <ConfirmationModal
        title="link event"
        isOpen={isLinkEventModalOpen}
        content={getLinkEventModalContent()}
        yesText="Ok"
        onNo={() => handleEventModalAction(eventType.link, false)}
        onYes={() => handleEventModalAction(eventType.link, true)}
        isInvalid={!hasLinkedEvent()}
        validationText={"Select an event."}
      />
      <div className="stir-body-inner">
        {getErrors(stir, 4)}
        {getSubHead()}
        {getStirMessageControl()}
        {getEventControls()}
        {getTieBreakerControl()}
        {getAdditionalSettingsControl()}
      </div>
    </div>
  );
}

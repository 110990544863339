import Validator from './ValidationService';
import { UrlService } from './UrlService';

export default class EntityService {
  static getEntityAsync = (callback, hash, isLoadingRef, doReload) => {
    hash = hash || UrlService.getUserHashFromUrl();

    if (Validator.isHashValid(hash)) {
      this.getAsyncData((data, statusCode) => {
        if (data && !data.hash) data.hash = hash;

        callback(data, statusCode);
      }, `api/${hash}`, isLoadingRef, doReload);
    }
  }

  static getAsyncData = (callback, uri, isLoadingRef, doReload) => {
    doReload = doReload ?? true;

    if (isLoadingRef) {
      if (isLoadingRef.current || (!doReload && isLoadingRef.current === false)) return;

      isLoadingRef.current = true;
    }

    console.debug('fetching ' + uri);
    fetch(uri).then(async response => {
      try {
        let data = await response.json();

        if (isLoadingRef) isLoadingRef.current = false;

        try {
          const isValidResponse = data && !(Object.entries(data).length === 0 && data.constructor === Object);

          callback(isValidResponse ? data : null, response.status);

          console.debug('get callback complete ' + uri);
        } catch (error) {
          console.log('Error in callback function during getAsyncData:');
          console.log(error);
        }
      } catch (error) {
        console.log('Error fetching response during getAsyncData:');
        console.log(error);

        if (isLoadingRef) isLoadingRef.current = false;
        callback(null, response.status);
      }
    });
  }

  static setAsyncData = (callback, uri, postBody, method, isSavingRef) => {
    if (isSavingRef) {
      if (isSavingRef.current) return;

      isSavingRef.current = true;
    }

    console.debug('posting ' + uri);
    fetch(uri, {
      method: method || 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postBody ?? {})
    }).then(async response => {
      try {
        let data = await response.text();

        if (data.length) data = JSON.parse(data);

        if (isSavingRef) isSavingRef.current = false;

        try {
          const isValidResponse = data && !(Object.entries(data).length === 0 && data.constructor === Object);

          callback(isValidResponse ? data : null, response.status);

          console.debug('post callback complete ' + uri);          
        } catch (error) {
          console.log('Error in callback function during setAsyncData:');
          console.log(error);
        }
      } catch (error) {
        console.log('Error fetching response during setAsyncData:');
        console.log(error);

        if (isSavingRef) isSavingRef.current = false;
        callback(null, response.status);
      }
    });
  }
}

import Moment from 'moment';

export default class DateService {
  static dateLongFormat = "ddd, MMM Do, YYYY [at] h:mma";   // Fri, Apr 24th, 2020 at 1:25pm
  static dateShortFormatWithoutYear = "MMM D";              // Apr 24
  static dateShortFormatWithYear = "MMM D, yyyy";           // Apr 24, 2020
  static controlDateFormat = "MMM d, yyyy h:mm aa";         // Apr 24, 2020 1:25 PM

  static getShortDateText = (dateText) => {
    const date = Moment(dateText);
    return (dateText) ? date.format(date.year() === Moment().year() ? DateService.dateShortFormatWithoutYear : DateService.dateShortFormatWithYear) : "";
  }

  static getDateText = (dateText, format) => {
    return (dateText) ? Moment(dateText).format(format || DateService.dateLongFormat) : "";
  };

  static getDurationText = (dateText) => {
    return (dateText) ? Moment(dateText).fromNow() : "";
  };

  static getTimeText = (secondsFromNow) => {
    return (secondsFromNow > 0) ? Moment().add(secondsFromNow, 'seconds').format(DateService.dateLongFormat) : "";
  }
}

import React, { Component } from 'react';
import { Form, FormControl, FormLabel, Alert, Nav } from 'react-bootstrap';
import { Icon } from '../shared/Icon';
import { StirTile } from '../tiles/StirTile';
import { Spinner } from '../shared/Spinner';
import { NoContent } from '../shared/NoContent';
import { ConfirmationModal } from '../shared/ConfirmationModal';
import { Breadcrumbs } from '../shared/Breadcrumbs';
import { ParticipantAdminTile } from '../tiles/ParticipantAdminTile';
import { EmailButton } from '../shared/EmailButton';
import TextService from '../../services/TextService';
import Constants from '../../services/Constants';
import { UrlService } from '../../services/UrlService';
import EntityService from '../../services/EntityService';
import Validator from '../../services/ValidationService';
import Stir from '../../entities/Stir';
import Participant from '../../entities/Participant';
import _uniqueId from 'lodash/uniqueId';
import DatePicker from 'react-datepicker';
import DateService from '../../services/DateService';

export class AdministratorDetails extends Component {
  constructor(props) {
    super(props);
    this.messageType = {
      NoMessage: 0,
      SavingMessage: 1,
      AddedMessage: 2,
      RemovedMessage: 3,
      WinnerMessage: 4,
      NewDeadlineMessage: 5,
    };

    this.state = {
      user: props.user,
      stir: {},
      isAddParticipantModalOpen: false,
      isSetDeadlineModalOpen: false,
      isLoading: true,
      notFound: false,
      isError: false,
      isSendEmailButtonDisabled: false,
      messageType: this.messageType.NoMessage,
      addParticipantEmail: "",
      addParticipantType: Constants.stirParticipantType.Participant,
      newDeadline: null,
      userHash: UrlService.getUserHashFromUrl()
    };

    this.administerParticipant = this.administerParticipant.bind(this);
    this.updateEventContent = this.updateEventContent.bind(this);
    this.initData(this.messageType.NoMessage);
  }

  componentDidMount() {
    document.title = 'teamstir - Administrator Details';
  }

  initData(messageType) {
    EntityService.getAsyncData((data, status) => {
      if (status === Constants.httpStatus.NotFound) {
        this.setState({ isLoading: false, notFound: true });

        return;
      } else if (status !== Constants.httpStatus.Ok) {
        this.setState({ isLoading: false, isError: true });

        return;
      }

      if (data.userHash !== this.state.userHash) {
        if (this.state.userHash.length) {
          this.setState({ isLoading: false, notFound: true })
        } else {
          props.navigate("/" + this.props.hash + "?user=" + data.userHash);
        }
      }

      let user = data.participant;
      user.publicHash = user.hash;
      user.hash = data.userHash;
      const stir = new Stir(data.stir);
      if (Validator.isHashValid(stir.eventHash)) {
        EntityService.getEntityAsync(this.updateEventContent, stir.eventHash);
      }
      this.setState({ stir: stir, user: user, isLoading: false, messageType: messageType });
    }, `api/stir/${this.props.hash}/administer?user=${this.state.userHash}`);
  }

  updateEventContent(data) {
    if (data && Validator.isHashValid(data.hash, Constants.hash.event)) {
      this.setState({ event: data });
    }
  }

  administerParticipant(content, action) {
    let settings = null;
    let messageType = this.messageType.NoMessage;
    let copyStir = this.state.stir;
    if (action === Constants.actionType.removeThisParticipant) {
      copyStir.participants = copyStir.participants.filter(x => x.hash !== content);

      settings = { removeHashes: [content] };
      messageType = this.messageType.RemovedMessage;
    } else if (action === Constants.actionType.addParticipant) {
      if (!Validator.isEmailValid(content.addParticipantEmail)) return;

      copyStir.participants.push(new Participant({ name: "loading...", hash: copyStir.participants.length }));
      this.setState({ isModalOpen: false });

      settings = (content.addParticipantType === Constants.stirParticipantType.Observer)
        ? { addObserverEmails: [content.addParticipantEmail] }
        : { addParticipantEmails: [content.addParticipantEmail] };
      messageType = this.messageType.AddedMessage;
    } else if ([1, 2, 3].includes(parseInt(action))) {
      const winnerRank = parseInt(action);
      const teamHash = this.state.stir.matches.find(x => x.participantHash === content).team.hash;
      copyStir.matches.find(x => x.participantHash === content).winnerRank = winnerRank;

      settings = { gameResults: [{ participantHash: content, stirHash: this.state.stir.hash, teamHash: teamHash, winnerRank: winnerRank }] };
      messageType = this.messageType.WinnerMessage;
    } else if (action === Constants.actionType.setDeadline) {
      this.setState({ isSetDeadlineModalOpen: false });

      settings = { newDeadline: this.state.newDeadline };
      messageType = this.messageType.NewDeadlineMessage;
    }
  
    if (settings) {
      settings.stirHash = this.state.stir.hash;
      this.setState({ stir: copyStir, messageType: this.messageType.SavingMessage }, () => {
        document.getElementsByClassName("dropdown")[0].click();

        EntityService.setAsyncData((_, status) => {
          if (status === Constants.httpStatus.Ok) {
            this.initData(messageType);
          } else {
            this.setState({ isError: true });
          }
        }, `/api/stir/administer/${this.props.hash}?user=${this.state.user.hash}`, settings);
      });
    }
  }

  handleAddParticipantTypeChange(stirParticipantTypeId) {
    if (isNaN(stirParticipantTypeId)) return;
    this.setState({ addParticipantType: stirParticipantTypeId });
  }

  getFooter() {
    if (!this.state.stir || this.state.stir.dateResolved) return null;

    return (
      <div className="grid-footer">
        <EmailButton
          entityName="stir"
          isSending={this.state.isSendEmailButtonDisabled}
          dateSent={this.state.stir.invitationDateLastSent}
          onClick={() => {
            this.setState({ isSendEmailButtonDisabled: true });
            EntityService.getAsyncData((_data, _status) => {
              const isError = _status !== Constants.httpStatus.Ok;
              let stir = this.state.stir;
              stir.invitationDateLastSent = isError ? this.state.stir.invitationDateLastSent : new Date();
              this.setState({ isSendEmailButtonDisabled: false, isError, stir });
            }, `api/email/sendInvite/${this.props.hash}`)
          }}
        />
        <Nav.Link to="#" onClick={() => { this.setState({ isAddParticipantModalOpen: true }) }}>
            <Icon icon="plus" helptext="add a participant" />
        </Nav.Link>
        {!this.state.stir.currentStep.dateResolved &&
          <Nav.Link to="#" onClick={() => { this.setState({ isSetDeadlineModalOpen: true }) }}>
              <Icon icon="clock" helptext="set stir deadline" />
          </Nav.Link>
        }
      </div>
    );
  }

  getContent() {
    if (!this.state.stir) return null;
    if (!this.state.stir.participants) return <NoContent noun="participants" />;

    const participants = this.state.stir.participants.map(p =>
      <ParticipantAdminTile
        key={p.hash}
        participant={p}
        stir={this.state.stir}
        administerParticipant={this.administerParticipant}
        isAdmin={p.stirParticipantTypeId === Constants.stirParticipantType.AdminParticipant || p.stirParticipantTypeId === Constants.stirParticipantType.AdminObserver}
        isObserver={p.stirParticipantTypeId === Constants.stirParticipantType.Observer || p.stirParticipantTypeId === Constants.stirParticipantType.AdminObserver}
      />);

    // Sort admin first, then participants alphabetically, then observers alphabetically.
    return participants
      .sort((a, b) => (a.isObserver === b.isObserver) ? (a > b) : (a.isObserver ? 1 : -1))
      .sort((a, b) => b.isAdmin - a.isAdmin);
  }

  getAddParticipantModal() {
    return (
      <>
        <div className="modal-element">
          {"Enter the email of your new participant, and we'll send an invitation email with a unique link where they can visit this stir."}
        </div>
        <br />
        <FormLabel>Email</FormLabel>
        <FormControl autoFocus value={this.state.addParticipantEmail} onChange={(e) => this.setState({ addParticipantEmail: e.target.value })} placeholder="enter an email" />
        <br />
        <FormLabel>
          <span>Type of Participant</span>
          <Icon icon="question" helptext="Observers can only view the public state of a stir, whereas participants can fully participate in each remaining step." />
        </FormLabel>
        <Form.Select value={this.state.addParticipantType} onChange={(e) => this.handleAddParticipantTypeChange(e.target.value)} >
          <option key={_uniqueId()} value={Constants.stirParticipantType.Participant}>Participant</option>
          <option key={_uniqueId()} value={Constants.stirParticipantType.Observer}>Observer</option>
        </Form.Select>
      </>);
  }

  getSetDeadlineModal() {
    return (
      <>
        <div className="modal-element">
          {"Enter the deadline for the current step of this stir"}
        </div>
        <br />
        <FormLabel>Deadline</FormLabel>
        <DatePicker
          className="form-control"
          wrapperClassName="form-control"
          selected={this.state.newDeadline || this.state.stir.currentStep.dateShouldResolve}
          onChange={(actualDate) => this.setState({ newDeadline: actualDate })}
          placeholderText="Timeframe for committing team picks"
          showTimeSelect
          timeIntervals={30}
          timeCaption="time"
          dateFormat={DateService.controlDateFormat}
        />
      </>);
  }

  getMessage() {
    let message = null;
    let onDismiss = () => this.setState({ messageType: this.messageType.NoMessage });
    let bsStyle = "success";
    if (this.state.messageType === this.messageType.SavingMessage) {
      message = "saving...";
      bsStyle = "info";
      onDismiss = null;
    } else if (this.state.messageType === this.messageType.AddedMessage) {
      const participantNoun = (this.state.addParticipantType === Constants.stirParticipantType.Observer) ? "observer" : "participant";
      message = `${participantNoun} added`;
    } else if (this.state.messageType === this.messageType.RemovedMessage) {
      message = "participant removed";
    } else if (this.state.messageType === this.messageType.WinnerMessage) {
      message = "winner rank saved";
    } else if (this.state.messageType === this.messageType.NewDeadlineMessage) {
      message = "new deadline set";
    }
    if (message) {
      return <Alert transition={null} onDismiss={onDismiss} variant={bsStyle}>{message}</Alert>;
    }
  }

  render() {
    if (this.state.isLoading) return <Spinner />;
    if (this.state.notFound) return <NoContent noun={"stir"} />;
    if (this.state.isError) return <Alert transition={null} variant="danger">{TextService.genericErrorText}</Alert>;

    return (
      <div className="center">
        <ConfirmationModal
          title="add participant"
          isOpen={this.state.isAddParticipantModalOpen}
          content={this.getAddParticipantModal()}
          yesText="add"
          onNo={() => this.setState({ isAddParticipantModalOpen: false })}
          onYes={() => this.setState({ isAddParticipantModalOpen: false }, this.administerParticipant({ addParticipantEmail: this.state.addParticipantEmail, addParticipantType: parseInt(this.state.addParticipantType) }, Constants.actionType.addParticipant))}
        />
        <ConfirmationModal
          title="set deadline"
          isOpen={this.state.isSetDeadlineModalOpen}
          content={this.getSetDeadlineModal()}
          yesText="set"
          onNo={() => this.setState({ isSetDeadlineModalOpen: false })}
          onYes={() => this.setState({ isSetDeadlineModalOpen: false }, this.administerParticipant({ newDeadline: this.state.newDeadline }, Constants.actionType.setDeadline))}
          isInvalid={this.state.newDeadline <= new Date()}
          validationText={"Choose a time in the future."}
        />
        <div className="tile-container">
          <Breadcrumbs user={this.state.user} stir={this.state.stir} theme={this.state.stir.theme} event={this.state.event} />
          <StirTile
            key={this.state.stir.hash}
            user={this.state.user}
            stir={this.state.stir}
            isHeader={true}
            page={Constants.page.Administrator}
          />
          {this.getMessage()}
          {this.getContent()}
          {this.getFooter()}
        </div>
      </div>);
  }
}

import React, { Component } from 'react';
import { Icon } from '../shared/Icon';
import { Tile } from './Tile';
import { DropdownAction } from '../shared/DropdownAction';
import Constants from '../../services/Constants';
import _uniqueId from 'lodash/uniqueId';

export class ParticipantAdminTile extends Component {

  constructor(props) {
    super(props);
    if (!props.participant.isInitialized) throw new Error("participant admin not initialized");
  }

  getIconName() {
    switch (this.props.participant.stirParticipantTypeId) {
      case Constants.stirParticipantType.Participant:
        return "participant";
      case Constants.stirParticipantType.AdminParticipant:
        return "admin";
      case Constants.stirParticipantType.Observer:
        return "observer";
      case Constants.stirParticipantType.AdminObserver:
        return "admin-observer";
      default:
        return "participant";
    }
  }

  getEntityName() {
    return this.props.isAdmin ? "admin" : (this.props.isObserver ? "observer" : "participant");
  }

  getMatch() {
    return (this.props.stir && this.props.stir.matches) ? this.props.stir.matches.find(m => m.participantHash === this.props.participant.hash) : null;
  }

  getAvailableRanks() {
    // If stir is not resolved, don't allow winner ranks. If there are only 2 participants, just show 1st and 2nd. Otherwise allow 1st, 2nd, or 3rd.
    return (this.props.stir && this.props.stir.dateResolved) ? (this.props.stir.participantCount >= 3) ? [1, 2, 3] : [1, 2] : [];
  }

  getIcon() {
    return <Icon icon={this.getIconName()} />;
  }

  getFooter() {
    return null;
  }

  getHeaderElements() {
    return null;
  }

  getFooterElements() {
    const match = this.getMatch();
    return (
      <>
        {(match && this.getAvailableRanks().includes(match.winnerRank)) ? ("#" + match.winnerRank) : null}
      </>
    );
  }

  getDropdownActions() {
    const canRemove = this.props.stir && !this.props.stir.dateResolved && !this.props.isAdmin;
    const availableRanks = this.getAvailableRanks();
    return [
      <DropdownAction key={_uniqueId()} name={"remove this " + this.getEntityName()} func={(canRemove ? (() => this.props.administerParticipant(this.props.participant.hash, Constants.actionType.removeThisParticipant)) : null)} />,
      <DropdownAction key={_uniqueId()} name={"set as 1st place"} func={(availableRanks.includes(1) ? (() => this.props.administerParticipant(this.props.participant.hash, 1)) : null)} />,
      <DropdownAction key={_uniqueId()} name={"set as 2nd place"} func={(availableRanks.includes(2) ? (() => this.props.administerParticipant(this.props.participant.hash, 2)) : null)} />,
      <DropdownAction key={_uniqueId()} name={"set as 3rd place"} func={(availableRanks.includes(3) ? (() => this.props.administerParticipant(this.props.participant.hash, 3)) : null)} />,
    ];
  }

  render() {
    return <Tile
      isHeader={this.props.isHeader}
      icon={this.getIcon()}
      header={this.props.participant.name}
      footer={this.getFooter()}
      headerElements={this.getHeaderElements()}
      footerElements={this.getFooterElements()}
      dropdownActions={this.getDropdownActions()}
      hash={this.props.participant.hash}
      {...this.props}
    />;
  }
}

import React, { useState, useRef, useEffect } from 'react';
import { Form, FormControl, Alert } from 'react-bootstrap';
import { Icon } from '../shared/Icon';
import { StirTile } from '../tiles/StirTile';
import { UserTile } from '../tiles/UserTile'
import { Spinner } from '../shared/Spinner';
import { NoContent } from '../shared/NoContent';
import { ConfirmationModal } from '../shared/ConfirmationModal';
import { Breadcrumbs } from '../shared/Breadcrumbs';
import Constants from '../../services/Constants';
import BggService from '../../services/BggService';
import TextService from '../../services/TextService';
import EntityService from '../../services/EntityService';
import Validator from '../../services/ValidationService';
import { UrlService } from '../../services/UrlService';

export const UserDetailsAction = {
  saveUser: 1,
  closeModals: 2,
  openNameModal: 3,
  openColorsModal: 4,
  setNewName: 5,
  setNewColors: 6,
  openLinkBggUserNameModal: 7,
  openUnlinkBggUserNameModal: 8,
  openConfirmBggUserModal: 9,
  linkBggUserName: 10,
  unlinkBggUserName: 11,
};

export function UserDetails(props) {
  const { user, updateUser, isLoadingUser } = props;

  const isLoadingStirViews = useRef();
  const isSavingUser = useRef();
  const newUserName = useRef(); //Can't use state here because the rerender on every keystroke is too slow
  const newBggUserName = useRef();

  const [isNameChangeModalOpen, setIsNameChangeModalOpen] = useState(false);
  const [isColorThemeModalOpen, setIsColorThemeModalOpen] = useState(false);
  const [selectedColorThemeId, setSelectedColorThemeId] = useState(user.colorThemeId);
  const [isLinkBggUserNameModalOpen, setIsLinkBggUserNameModalOpen] = useState(false);
  const [isUnlinkBggUserNameModalOpen, setIsUnlinkBggUserNameModalOpen] = useState(false);
  const [isConfirmBggUserModalOpen, setIsConfirmBggUserModalOpen] = useState(false);
  const [bggUserInfo, setBggUserInfo] = useState(null);
  const [isError, setIsError] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [florp, setFlorp] = useState(false);

  UrlService.useTitle('Profile');

  useEffect(() => {
    if (Validator.isHashValid(user?.hash, Constants.hash.user) && user.name) {
      EntityService.getAsyncData((data, status) => {
        if (!data || status !== Constants.httpStatus.Ok) {
          setIsError(true);
        } else {
          user.stirViews = data;

          if (user.status === Constants.httpStatus.Error) {
            setIsError(true);
          } else {
            updateUser(user); //make sure Stirs get initialized
            setIsError(false);
            setFlorp(!florp); //TODO: actually update user without this stupid thing.
          }
        }
      }, 'api/participants/stirviews/' + user.hash, isLoadingStirViews, false);
    }
  }, [user, updateUser]);

  const saveUser = () => {
    EntityService.setAsyncData((data, status) => {
      if (status === Constants.httpStatus.Ok) {
        user.name = data.name;
        user.colorThemeId = data.colorThemeId;
        user.bggUserName = data.bggUserName;
        updateUser(user);
      } else {
        setIsError(true);
      }

    },
      '/api/participants/' + user.hash,
      {
        name: user.name,
        dateCreated: user.dateCreated,
        colorThemeId: user.colorThemeId,
        hash: user.hash,
        email: user.email,
        bggUserName: user.bggUserName
      },
      'PATCH',
      isSavingUser);
  };

  const handleModalAction = (modalAction) => {
    switch (modalAction) {
      case UserDetailsAction.saveUser:
        if (isNameChangeModalOpen && !newUserName.current.value) return;
        saveUser();
        setIsColorThemeModalOpen(false);
        setIsNameChangeModalOpen(false);
        break;
      case UserDetailsAction.closeModals:
        setIsColorThemeModalOpen(false);
        setIsNameChangeModalOpen(false);
        setIsLinkBggUserNameModalOpen(false);
        setIsUnlinkBggUserNameModalOpen(false);
        setIsConfirmBggUserModalOpen(false);
        break;
      case UserDetailsAction.openNameModal:
        setIsNameChangeModalOpen(true);
        break;
      case UserDetailsAction.openColorsModal:
        setIsColorThemeModalOpen(true);
        break;
      case UserDetailsAction.setNewName:
        if (newUserName.current?.value?.length) {
          user.name = newUserName.current.value;
          saveUser();
        }
        setIsNameChangeModalOpen(false);
        break;
      case UserDetailsAction.setNewColors:
          if (selectedColorThemeId > 0) {
            user.colorThemeId = selectedColorThemeId;
            saveUser();
        }
        setIsColorThemeModalOpen(false);
        break;
      case UserDetailsAction.openLinkBggUserNameModal:
        newBggUserName.current = undefined;
        setIsLinkBggUserNameModalOpen(true);
        break;
      case UserDetailsAction.openUnlinkBggUserNameModal:
        newBggUserName.current = undefined;
        setIsUnlinkBggUserNameModalOpen(true);
        break;
      case UserDetailsAction.openConfirmBggUserModal:
        const userEnteredBggName = newBggUserName.current?.value ?? "";
        setIsLinkBggUserNameModalOpen(false);
        if (userEnteredBggName.length) {
          BggService.getUserData(userEnteredBggName, (data) => {
            if (!data) {
              setAlertMessage(`'${userEnteredBggName}' is not a valid Board Game Geek user, please verify the spelling`);
              setIsConfirmBggUserModalOpen(false);
              return;
            }
            EntityService.getAsyncData((userData) => {
              if (userData) {
                setAlertMessage(`'${userEnteredBggName}' is already registered to another teamstir user`);
                setIsConfirmBggUserModalOpen(false);
                return;
              } else {
                // Passed BGG and teamstir validation...
                setBggUserInfo(data);
                setAlertMessage(null);
                setIsConfirmBggUserModalOpen(true);
              }
            }, `api/participants/bgg/${userEnteredBggName}`, undefined, false);
          });
        }
        break;
      case UserDetailsAction.linkBggUserName:
        user.bggUserName = bggUserInfo.username;
        saveUser();
        setIsConfirmBggUserModalOpen(false);
        setAlertMessage(null);
        setSuccessMessage(`successfully linked your BGG account '${user.bggUserName}'`);
        break;
      case UserDetailsAction.unlinkBggUserName:
        user.bggUserName = undefined;
        saveUser();
        setBggUserInfo(null);
        setIsUnlinkBggUserNameModalOpen(false);
        setAlertMessage(null);
        setSuccessMessage("successfully unlinked your BGG account");
        break;
      default:
        break;
    }
  }

  const handleColorThemeChange = (e) => {
    const colorThemeId = parseInt(e.target.value);

    if (colorThemeId > 0) {
      setSelectedColorThemeId(colorThemeId);
    }
  }

  const getNameChangeModalContent = () => {
    if (isSavingUser.current) {
      return <Spinner />;
    }

    return (
      <>
        <div className="modal-element">{"What would you like your name to be?"}</div>
        <br />
        <FormControl autoFocus ref={newUserName} placeholder="enter your name" />
      </>);
  }

  const getColorThemeChangeModalContent = () => {
    if (isSavingUser.current) {
      return <Spinner />;
    }

    return (
      <>
        <div className="modal-element">{"What color theme would you like?"}</div>
        <br />
        <Form.Select value={selectedColorThemeId} placeholder="select a color theme" onChange={handleColorThemeChange}>
          {Constants.colorThemes.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
        </Form.Select>
      </>);
  }

  const handleLinkBggUserNameKeyPress = (e) => {
    if (e.key === "Enter") {
      handleModalAction(UserDetailsAction.openConfirmBggUserModal);
    }
  };

  const getLinkBggUserNameModalContent = () => {
    if (isSavingUser.current) {
      return <Spinner />;
    }
    return (
      <>
        <div className="modal-element">{"What is your Board Game Geek user name? You can find it on your BGG user profile."}</div>
        <br />
        <FormControl autoFocus ref={newBggUserName} onKeyPress={handleLinkBggUserNameKeyPress} placeholder="enter your BGG user name" />
      </>);
  }

  const getConfirmBggUserModalContent = () => {
    if (isSavingUser.current || !bggUserInfo) {
      return <Spinner />;
    }
    const url = `https://boardgamegeek.com/user/${bggUserInfo.username}`;
    return (
      <>
        <div className="modal-element">{"Is this your BGG account?"}</div>
        <br />
        <div>
          <label>Name:&nbsp;</label>
          <span>{bggUserInfo.firstname}&nbsp;{bggUserInfo.lastname}</span>
        </div>
        <div>
          <label>Profile:&nbsp;</label>
          <a target='_blank' href={url}>{url}</a>
        </div>
      </>);
  }

  const getUnlinkBggUserNameModalContent = () => {
    if (isSavingUser.current) {
      return <Spinner />;
    }

    return (
      <>
        <div className="modal-element">{"Do you want to unlink your Board Game Geek account? This will remove teamstir's record of it. You can always link again later if you want."}</div>
      </>);
  }

  const getAlertMessage = () => {
    return alertMessage && <Alert transition={null} variant="warning" dismissible onClose={() => setAlertMessage(undefined)}><Icon icon="caution" />{alertMessage}</Alert>;
  }

  const getSuccessMessage = () => {
    return successMessage && <Alert transition={null} variant="success" dismissible onClose={() => setSuccessMessage(undefined)}><Icon icon="check" />{successMessage}</Alert>;
  }

  const getContent = () => {
    if (isLoadingStirViews.current !== false) return <Spinner />;

    if (isError) return <NoContent noun={"stirs"} />;

    return user.stirViews.map(x => <StirTile key={x.hash} stir={x} user={user} page={Constants.page.User} />);
  }

  if (isError) return <Alert transition={null} variant="danger">{TextService.genericErrorText}</Alert>;

  if (isLoadingUser.current !== false) return <Spinner />;

  if (!user?.email) return <NoContent noun={"user"} />;

  return (
    <div className="center">
      <ConfirmationModal
        title="edit name"
        isOpen={isNameChangeModalOpen}
        content={getNameChangeModalContent()}
        yesText="save"
        onNo={() => handleModalAction(UserDetailsAction.closeModals)}
        onYes={() => handleModalAction(UserDetailsAction.setNewName)}
        isInvalid={isSavingUser.current}
      />
      <ConfirmationModal
        title="change color theme"
        isOpen={isColorThemeModalOpen}
        content={getColorThemeChangeModalContent()}
        yesText="apply"
        onNo={() => handleModalAction(UserDetailsAction.closeModals)}
        onYes={() => handleModalAction(UserDetailsAction.setNewColors)}
        isInvalid={isSavingUser.current}
      />
      <ConfirmationModal
        title="link Board Game Geek"
        isOpen={isLinkBggUserNameModalOpen}
        content={getLinkBggUserNameModalContent()}
        yesText="link"
        onNo={() => handleModalAction(UserDetailsAction.closeModals)}
        onYes={() => handleModalAction(UserDetailsAction.openConfirmBggUserModal)}
        isInvalid={isSavingUser.current}
      />
      <ConfirmationModal
        title="link Board Game Geek"
        isOpen={isConfirmBggUserModalOpen}
        content={getConfirmBggUserModalContent()}
        yesText="link"
        onNo={() => handleModalAction(UserDetailsAction.closeModals)}
        onYes={() => handleModalAction(UserDetailsAction.linkBggUserName)}
        isInvalid={isSavingUser.current}
      />
      <ConfirmationModal
        title="unlink Board Game Geek"
        isOpen={isUnlinkBggUserNameModalOpen}
        content={getUnlinkBggUserNameModalContent()}
        yesText="unlink"
        onNo={() => handleModalAction(UserDetailsAction.closeModals)}
        onYes={() => handleModalAction(UserDetailsAction.unlinkBggUserName)}
        isInvalid={isSavingUser.current}
      />
      <div className="tile-container">
        <Breadcrumbs user={user} />
        {getAlertMessage()}
        {getSuccessMessage()}
        <UserTile
          key={user.hash}
          user={user}
          isHeader={true}
          handleModalAction={handleModalAction}
        />
        {getContent()}
      </div>
    </div>);
}
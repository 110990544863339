import React, { Component } from 'react';
import '../../css/NoContent.css';

export class NoContent extends Component {
  render() {
    return (
      <div className="no-content-outer">
        <div className="no-content-inner">
          no {this.props.noun || "entries"} found
          </div>
      </div>);
  }
}

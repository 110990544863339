import React from 'react';
import { Icon } from '../shared/Icon';
import { UrlService } from '../../services/UrlService';

export function About(props) {
  UrlService.useTitle('About');
    return (
      <div className="center">
        <div className="info-page-inner">

          <h1>the story</h1>
          <p>teamstir originated as a tool for choosing teams in board games. But it has expanded to a system of methods for distributing anything.</p>

          <h1>the use</h1>
          <p>Let's say you're having a party next weekend. You could use teamstir to see if people prefer Saturday or Sunday. Once that's settled, you want to divide your guests into four teams for a game. Next, perhaps you'd like everyone split into thirds based on their preference to bring drinks, snacks, or desserts. Teamstir is the only tool that can do all of that (and a lot more), through an intuitive interface driven by custom emails where participants set their preferences to any scenario quickly and easily.</p>

          <h1>the process</h1>
          <p>Create a stir in four quick steps:</p>

          <b><Icon icon="stir" /> Choose Teams</b>
          <p>select from teamstir's database or create your own custom teams</p>

          <b><Icon icon="stir" /> Enter Participants</b>
          <p>input the people involved and how to notify them of results</p>

          <b><Icon icon="stir" /> Select Method</b>
          <p>distribute teams randomly, systematically, or collaboratively</p>

          <b><Icon icon="stir" /> Customize Settings</b>
          <p>include a message or other details about your event</p>

          <h1>the methods</h1>

          <b><Icon icon="stir" /> Rank</b>
          <p>assign teams by rank preference</p>

          <b><Icon icon="stir" /> Draft</b>
          <p>claim teams one by one, in sequence</p>

          <b><Icon icon="stir" /> Random</b>
          <p>distribute teams arbitrarily</p>

          <b><Icon icon="stir" /> Assign</b>
          <p>participants or admins assign teams directly</p>

          <b><Icon icon="stir" /> Group</b>
          <p>categorize teams by preferred buckets</p>

          <h1>the crew</h1>

          <b><Icon icon="stir" /> Stephen Greenfield</b>
          <p>concept, design, back-end development, front-end development</p>

          <b><Icon icon="stir" /> Alan Frew</b>
          <p>design, back-end development</p>

          <h1>special thanks</h1>
          <p>to <span className="bold">Fallon McGovern</span> and <span className="bold">Danielle Brown</span> and <span className="bold">Owen Henry</span></p>
        </div>
      </div>
    );
}

import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import _uniqueId from 'lodash/uniqueId';

export const HelpOverlay = (props) => {
  if (!props) return <></>
  if (!props.text) return props.content;

  return (
    <OverlayTrigger delay={100} transition={null} placement="bottom" overlay={(p) => <Tooltip {...p}>{props.text}</Tooltip>}>
      {props.content}
    </OverlayTrigger>
  );
};

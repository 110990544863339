import React, { Component } from 'react';
import { Icon } from '../shared/Icon';
import { Tile } from './Tile';
import { DropdownAction } from '../shared/DropdownAction';
import { TileElement } from './TileElement'
import { EventDetails, action } from '../details/EventDetails';
import DateService from '../../services/DateService';
import _uniqueId from 'lodash/uniqueId';

export class EventTile extends Component {
  constructor(props) {
    super(props);
    if (props.event === {}) throw new Error("event not initialized");
  }

  getIcon() {
    return <Icon icon={"event"} />;
  }

  getFooter() {
    return (this.props.event.eventDate) ? <>{DateService.getDateText(this.props.event.eventDate)}</> : null;
  }

  getHeaderElements() {
    return [
      <TileElement key={1} content={this.getParticipantElements()} css={this.props.event.participantCount > 6 ? "tilehead-double-column" : ""} />,
      <TileElement key={2} label="message" content={this.props.event.message} inlinelabel={true} />,
      <TileElement key={3} label="address" content={this.props.event.address} />
    ];
  }

  getNameElement(name) {
    return <div key={`${name}_namedisplay`}><Icon icon="participant" />&nbsp;{name}</div>;
  }

  getParticipantElements() {
    if (!this.props.event.participantCount) {
      return "no participants";
    } else if (this.props.event.participantCount <= 6) {
      return this.props.event.participantNames.map(name => this.getNameElement(name));
    }
    const names = Array.from(this.props.event.participantNames).sort();
    const leftColumn = names.splice(0, Math.min(6, ((names.length + 1) / 2))).map(name => this.getNameElement(name));
    const rightColumn = names.splice(0, 6).map(name => this.getNameElement(name));
    if (names.length) {
      rightColumn.pop();
      rightColumn.push(`...and ${names.length + 1} others`);
    }
    return (
      <>
        <TileElement key={1} content={leftColumn} />
        <TileElement key={2} content={rightColumn} />
      </>
    );
  }

  getFooterElements() {
    const event = this.props.event;
    return (
      <>
        <div>
          {event.participantCount} <Icon icon="participant" helptext={"there " + (event.participantCount === 1 ? "is 1 participant" : ("are " + event.participantCount + " participants")) + " in this event"} />
        </div>
        <div>
          {event.stirCount} <Icon icon="stir" helptext={"there " + (event.stirCount === 1 ? "is 1 stir" : ("are " + event.stirCount + " stirs")) + " in this event"} />
        </div>
      </>
    );
  }

  getDropdownActions() {
    return [
      <DropdownAction
        key={_uniqueId()}
        name={"edit event"}
        func={(this.props.canEdit && this.props.handleModalAction) ? (() => this.props.handleModalAction(action.editEvent)) : null}
      />
    ];
  }

  render() {
    return <Tile
      entity={this.props.event}
      isHeader={this.props.isHeader}
      icon={this.getIcon()}
      header={this.props.event.name}
      footer={this.getFooter()}
      headerElements={this.getHeaderElements()}
      footerElements={this.getFooterElements()}
      dropdownActions={this.getDropdownActions()}
      hash={this.props.event.hash}
      {...this.props}
    />;
  }
}

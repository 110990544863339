import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { HelpOverlay } from '../shared/HelpOverlay';
import '../../css/Tile.css';
import '../../css/TileHead.css';

export class Tile extends Component {

  getCss() {
    return ("tile-outer "
      + (this.props.isHeader ? "tilehead-outer " : "")
      + (!this.props.footer ? "no-info " : "")
      + (this.props.css ? `${this.props.css} ` : "")
    ).trim();
  }

  getHeaderContent() {
    const content = (this.props.isHeader)
      ? (
        <div className="tilehead">
          <div className="tilehead-top">
            {this.props.header}
          </div>
          <div className="tilehead-bottom">
            {this.props.headerElements}
          </div>
        </div>
      ) : (
        <div className="tile-top">
          <div className="tile-top-left">
            {this.props.header}
          </div>
          <div className="tile-top-right">
            {this.props.buttonAction}
          </div>
        </div>
      );
    return <HelpOverlay content={content} text={this.props.header} />;
  }

  getIcon() {
    return (
      <div className="tile-icon">
        {this.props.icon}
      </div>
    );
  }

  getContent() {
    return (
      <>
        {this.props.isHeader ? null : this.getIcon()}
        <div className="tile-inner">
          {this.getHeaderContent()}
          <div className="tile-bottom">
            <div className="tile-bottom-left">
              {this.props.footer}
            </div>
            {this.props.hideStats ? null : (
              <div className="tile-bottom-right">
                {this.props.footerElements}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  render() {
    const dropdownActions = this.props.dropdownActions.filter(x =>
      x && x.props && x.props.name && (x.props.hash || x.props.url || x.props.func || x.props.uri)
    );

    if (dropdownActions.length) {
      const customToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div ref={ref} className={this.getCss()} onClick={(e) => { e.preventDefault(); if (onClick) { onClick(e); } }} key={this.props.hash} data-id={this.props.hash}>
          {children}
        </div>
      ));

      return (
        <Dropdown className="tile-dropdown">
          <Dropdown.Toggle as={customToggle} id={this.props.hash}>
            {this.getContent()}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-right">
            {dropdownActions}
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    return (
      <div className={this.getCss()} onClick={() => {
        if (this.props?.handleTeamAddRemove) { this.props.handleTeamAddRemove(this.props.hash, !this.props.pickRank) }
      }} {...{ "data-id": this.props["data-id"] }}>
        {this.getContent()}
      </div>
    );
  }
}

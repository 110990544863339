import React, { Component } from 'react';
import '../../css/NoContent.css';

export class NotFound extends Component {

  render() {
    return (
      <div className="no-content-outer">
        <div className="no-content-inner">
          <div className="ginormous text">
            404
          </div>
          <div className="text">
            sorry, that doesn't exist
          </div>
          <img src="nuts.png" alt="" />
          <br />
          <div className="text">
            nuts
          </div>
        </div>
      </div>
    );
  }
}
